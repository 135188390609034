import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createLink, createMachineLink } from './link';
import { createCache } from './cache';

export const getClient = () => {
  const apolloClientConfig = {
    link: createLink(),
    cache: createCache(),
    name: 'checkingin-web-apollo',
    defaultOptions: {
      // watchQuery: {
      //   fetchPolicy: 'no-cache',
      //   errorPolicy: 'ignore'
      // },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    }
  };

  return new ApolloClient(apolloClientConfig);
};

export const getMachineClient = (token) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: createMachineLink(token)
  });
