import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import YouTube from 'react-youtube';

import {
  getYouTubeVideoId,
  matchYoutubeUrl
} from '../../../../../helpers/video';

const ExerciseAudioVideo = ({ fileUrl, type, autoPlay, onEnded }) => {
  const isYoutube = matchYoutubeUrl(fileUrl);
  const youtubeVideoId = isYoutube ? getYouTubeVideoId(fileUrl) : null;

  const playerHeight = useMemo(() => {
    if (type === 'audio') return '3.125rem';
    if (isYoutube) return 'calc(50vw/1.77777778)'; // Aspect ratio: 16:9
    return '100%';
  }, [type]);

  if (isYoutube) {
    return (
      <YouTube
        videoId={youtubeVideoId}
        opts={{
          width: '100%',
          height: '100%',
          playerVars: {
            autoplay: autoPlay,
            showinfo: 1
          }
        }}
        style={{
          height: playerHeight
        }}
      />
    );
  }

  return (
    <ReactPlayer
      url={fileUrl}
      playing={autoPlay}
      config={{
        youtube: {
          playerVars: {
            showinfo: 1
          }
        },
        file: {
          attributes: {
            controls: true
          }
        }
      }}
      controls
      width="100%"
      height={playerHeight}
      onEnded={() => onEnded()}
    />
  );
};

ExerciseAudioVideo.defaultProps = {
  autoPlay: false,
  onEnded: () => {}
};

ExerciseAudioVideo.propTypes = {
  type: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  onEnded: PropTypes.func
};

export { ExerciseAudioVideo };
