import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import WeatherIcon from '../../../assets/svg/Trends/weather.svg';
import toggleIcon from '../../../assets/svg/Trends/change.svg';

import cloudActiveIcon from '../../../assets/svg/Trends/weather/cloud-active.svg';
import nightCloudActiveIcon from '../../../assets/svg/Trends/weather/night-cloud-active.svg';
import rainActiveIcon from '../../../assets/svg/Trends/weather/rain-active.svg';
import sunActiveIcon from '../../../assets/svg/Trends/weather/sun-active.svg';
import cloudIcon from '../../../assets/svg/Trends/weather/cloud.svg';
import nightCloudIcon from '../../../assets/svg/Trends/weather/night-cloud.svg';
import rainIcon from '../../../assets/svg/Trends/weather/rain.svg';
import sunIcon from '../../../assets/svg/Trends/weather/sun.svg';
import moonActiveIcon from '../../../assets/svg/Trends/weather/moon-active.svg';
import nightRainActiveIcon from '../../../assets/svg/Trends/weather/night-rain-active.svg';
import snowActiveIcon from '../../../assets/svg/Trends/weather/snow-active.svg';
import moonIcon from '../../../assets/svg/Trends/weather/moon.svg';
import nightRainIcon from '../../../assets/svg/Trends/weather/night-rain.svg';
import snowIcon from '../../../assets/svg/Trends/weather/snow.svg';

import ChartCard from '../../ChartCard';

import ArcChart from '../../ArcChart';

import {
  Wrapper,
  ToggleButton,
  Moment,
  MomentContainer,
  ContentWrapper,
  TopWords
} from './style';

const Weather = ({ checkIns }) => {
  const { t } = useTranslation();

  const [isDay, setIsDay] = useState(true);
  const [active, setActive] = useState('sunny');
  const [filtered, setFiltered] = useState([]);
  const [avgEnergy, setAvgEnergy] = useState(null);
  const [moments] = useState({
    sunny: {
      day: sunIcon,
      activeday: sunActiveIcon,
      night: moonIcon,
      activenight: moonActiveIcon
    },
    clouds: {
      day: cloudIcon,
      activeday: cloudActiveIcon,
      night: nightCloudIcon,
      activenight: nightCloudActiveIcon
    },
    rain: {
      day: rainIcon,
      activeday: rainActiveIcon,
      night: nightRainIcon,
      activenight: nightRainActiveIcon
    },
    snow: {
      day: snowIcon,
      activeday: snowActiveIcon,
      night: snowIcon,
      activenight: snowActiveIcon
    }
  });

  useEffect(() => {
    if (active && checkIns) {
      const filteredData = checkIns
        .filter(
          (checkin) => checkin?.weather?.weatherMain.toLowerCase() === active
        )
        .map(({ feelingWord, energyLevel }) => ({ feelingWord, energyLevel }));
      setFiltered(filteredData);
      const avg =
        filteredData.length > 0
          ? filteredData
              .map(({ energyLevel }) => energyLevel)
              .reduce((acc, cur) => acc + cur, 0) / filteredData.length
          : 0;
      setAvgEnergy(avg);
    }
  }, [active]);

  // if (!avgEnergy) {
  //   return (
  //     <ChartCard title="Weather" titleIcon={WeatherIcon}>
  //       <p>Loading...</p>
  //     </ChartCard>
  //   )
  // }

  const toggleIsDay = () => setIsDay((prevState) => !prevState);
  const getIconStatus = (moment) =>
    moments[moment][
      `${moment === active ? 'active' : ''}${isDay ? 'day' : 'night'}`
    ];
  const getMomentSubtitle = (moment) => {
    if (moment === active) {
      if (moment === 'sunny' && !isDay) {
        return t('Clear');
      }
      return `${moment.charAt(0).toUpperCase()}${moment.substring(1)}`;
    }
    return '';
  };

  const getTopWords = () => {
    const words = {};
    const allWords = filtered
      .map(({ feelingWord }) => feelingWord.split(','))
      .flat();
    [...new Set(allWords)].forEach((word) => {
      words[word] = 0;
    });
    allWords.forEach((word) => {
      words[word] += 1;
    });
    const ordered = Object.keys(words).sort((a, b) => words[b] > words[a]);
    return ordered
      .slice(0, 5)
      .map((word, index) => <li key={index}>{word}</li>);
  };

  return (
    <ChartCard title={t('Weather')} titleIcon={WeatherIcon}>
      <ToggleButton src={toggleIcon} onClick={toggleIsDay} />
      <Moment>{isDay ? t('Day') : t('Night')}</Moment>
      <Wrapper>
        <MomentContainer>
          {Object.keys(moments).map((moment) => (
            <div
              role="button"
              onClick={() => setActive(moment)}
              onKeyDown={() => setActive(moment)}
              tabIndex={0}
            >
              <img src={getIconStatus(moment)} alt={moment} />
              <span>{getMomentSubtitle(moment)}</span>
            </div>
          ))}
        </MomentContainer>
        <ContentWrapper>
          <TopWords>{getTopWords()}</TopWords>
          <ArcChart AvgEnergy={avgEnergy} />
        </ContentWrapper>
      </Wrapper>
    </ChartCard>
  );
};

Weather.propTypes = {
  checkIns: PropTypes.arrayOf(
    PropTypes.shape({
      createdDate: PropTypes.string.isRequired,
      energyLevel: PropTypes.number.isRequired,
      feelingContext: PropTypes.string.isRequired,
      feelingWord: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      weather: PropTypes.shape({
        weatherIcon: PropTypes.string.isRequired,
        weatherId: PropTypes.string.isRequired,
        weatherMain: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

export default Weather;
