import styled from 'styled-components';
import { memo } from 'react';

export const PageWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  margin: 0.625rem 0.625rem 8rem 0.625rem;
  height: auto;
`;

export const FilterWrapper = memo(styled.div`
  width: 100%;
  display: block;
  z-index: 2;
  box-sizing: border-box;
  gap: 0.625rem;
  align-items: center;
  > * {
    flex-grow: 1;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`);

export const Tab = memo(styled.button`
  min-width: 10%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
  ${({ active }) =>
    active &&
    `
  color: #3c7778;
  font-weight: bold;
  border-bottom: 5px solid #3c7778;
`}
`);

export const MonthFilter = memo(styled.div`
  width: 100%;
  padding: 0 2% 0;
  margin: 5vh 0 5vh;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  > label {
    position: absolute;
    right: 0;
    top: 0;
    width: 12vw;
    transform: translate(-50%, 50%);
  }
  flex-wrap: wrap;
`);

export const MonthTitle = memo(styled.span`
  display: flex;
  color: #204040;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.5em;
  flex-grow: 1;
`);

export const ContentWrapper = memo(styled.div`
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 2vh 2vh;
  justify-items: stretch;
  align-items: stretch;
  grid-template-columns: repeat(3, 1fr);
  box-sizing: border-box;
  padding-bottom: 80px;
  > div {
    width: calc((100% - 8vh) * 0.33);
  }
`);
export const SingleContentWrapper = memo(styled.div`
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 2vh 2vh;
  justify-items: stretch;
  align-items: stretch;
  grid-template-columns: repeat(3, 1fr);
  box-sizing: border-box;
  flex-grow: 3;

  > div {
    width: 100%;
  }
`);
export const MultipleContentWrapper = memo(styled.div`
  padding-bottom: 6vh;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 2vh 2vh;
  justify-content: center;
  align-items: stretch;
  > div {
    width: 100%;
  }

  @media (min-width: 1366px) {
    flex-wrap: unset;
  }
`);

export const Card = memo(styled.div`
  width: 100%;
  box-sizing: content-box;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template-rows: 72px 1fr;
  ${({ Area }) => Area && `grid-area: ${Area}`};
`);

export const CardTitle = memo(styled.h1`
  color: #636463;
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-size: 1.4em;
  line-height: 1.775em;
  display: inline-block;
  align-self: center;
  padding-left: 1em;
  width: 100%;
  height: 100%;
  border: none;
  border-bottom: 1px solid #fafafa;
  box-sizing: border-box;

  > strong {
    color: #418384;
    font-weight: 700;
  }
`);

export const CardBody = memo(styled.div`
  margin: 0;
  padding: 1.5vh;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  ${({ chartContainer }) => chartContainer && 'height: 45vh;'}
`);

export const LockedCardBody = memo(styled.div`
  margin: 0;
  padding: 2vh;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 4px;
  > span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  > img {
    width: 16px;
    height: 16px;
  }
  ${({ chartContainer }) => chartContainer && 'height: 45vh;'}
`);

export const SummaryData = memo(styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > table {
    text-align: center;
    color: #979797;
    font-weight: 600;
    font-size: 1.2rem;
    width: 100%;
    @media (min-width: 768px) {
      width: 70%;
    }
  }
  > table > tbody > tr {
    height: 4vh;
  }
  > table > thead > tr > th {
    font-size: 0.9em;
    font-weight: 700;
    color: #636463;
    white-space: nowrap;
  }
  > table > tbody > tr > td:first-of-type {
    text-align: left;
    font-size: 0.9em;
    font-weight: 700;
    color: #636463;
    white-space: nowrap;
  }
  > table > tbody > tr:last-of-type {
    border-top: 1px solid #ecebeb;
    > td {
      font-weight: 600;
      color: #2b5656;
    }
  }
`);
export const SummaryTab = memo(styled.div`
  width: 25%;
  @media (max-width: 640px) {
    width: 33%;
  }
  height: 16vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;

  > span {
    color: #979797;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
  }
  > span:first-of-type {
    font-size: 0.9em;
    font-weight: 700;
    color: #636463;
  }
  :last-of-type {
    @media (max-width: 640px) {
      width: 100%;
    }
    justify-content: center;
    background-color: #f7f9f9;
    > span:first-of-type {
      font-size: 3em;
      font-weight: 900;
      color: #51a3a4;
    }
    > span:last-of-type {
      font-weight: bold;
      color: #636463;
    }
  }
`);

export const SummaryWeekList = memo(styled.ul`
  height: 100%;
  display: grid;
  justify-items: stretch;

  > li {
    width: 100%;
    height: 100%;
  }

  > li > span {
    display: inline-block;
    height: 100%;
    width: 90%;
    font-size: 1.25em;
    color: #636463;
  }
  > li > span:last-of-type {
    width: 10%;
    text-align: right;
    font-size: 1.5em;
    color: #3c7778;
    font-weight: 700;
  }
`);
