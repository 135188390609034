import { getSummaryData, getWordCloud } from './monthlyGraphs';
import { calcAvgFromArray } from '../../services/insights/calcullations';

const getAvgDailyEnergyData = (data, key) => {
  const { list: energyLevelList } = data.reduce(
    (acc, { createdDate, energyLevel }, i) => {
      const [date] = createdDate.split('T');
      const day = new Date(date)
        .toLocaleDateString('en-US', {
          weekday: 'short'
        })
        .substr(0, 2);

      if (i === data.length - 1) {
        const lastDivideBy = acc.cache.divideBy || 1;
        const lastEnergyLevel = acc.cache.energyLevel || energyLevel;
        const lastDay = acc.cache.day || day;

        return {
          list: [
            ...acc.list,
            {
              day: lastDay,
              avgEnergyLevel: parseFloat(
                (lastEnergyLevel / lastDivideBy).toFixed(1)
              )
            }
          ]
        };
      }

      if (!acc.cache.day || acc.cache.day === day) {
        return {
          ...acc,
          cache: {
            day,
            divideBy: acc.cache.divideBy + 1,
            energyLevel: energyLevel + acc.cache.energyLevel
          }
        };
      }

      return {
        list: [
          ...acc.list,
          {
            day: acc.cache.day,
            avgEnergyLevel: parseFloat(
              (acc.cache.energyLevel / acc.cache.divideBy).toFixed(1)
            )
          }
        ],
        cache: { day: '', energyLevel: 0, divideBy: 0 }
      };
    },
    {
      list: [],
      cache: { day: '', energyLevel: 0, divideBy: 0 }
    }
  );

  const totalAverage =
    key === 'current' && calcAvgFromArray(energyLevelList, 'avgEnergyLevel');

  const displayDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const avgList = displayDays.map((dayOfWeek) => {
    const { avgEnergyLevel = 0 } =
      energyLevelList.find((item) => item.day === dayOfWeek) || {};

    return { dayOfWeek, [key]: avgEnergyLevel };
  });

  return {
    average: totalAverage,
    checkins: avgList
  };
};

const mergeAvgDailyEnergy = (currentData, priorData) => ({
  average: currentData.average,
  checkins: currentData.checkins.map((current, i) => ({
    ...current,
    ...priorData.checkins[i]
  }))
});

const getWeekGraphs = (currentData, priorData) => {
  const currentAvgDailyEnergy = getAvgDailyEnergyData(
    currentData.checkins,
    'current'
  );
  const priorAvgDailyEnergy = getAvgDailyEnergyData(
    priorData.checkins,
    'prior'
  );
  const { checkins, average } = mergeAvgDailyEnergy(
    currentAvgDailyEnergy,
    priorAvgDailyEnergy
  );

  const barColors = ['#E6B92A', '#ECEBEB'];

  return {
    summary: getSummaryData(currentData),
    avgDailyEnergy: {
      barColors,
      checkins,
      average
    },
    wordCloud: getWordCloud(currentData.checkins)
  };
};

export { getWeekGraphs };
