import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ChartCard from '../../ChartCard';

import { Wrapper, CheckIn } from './style';

import Chart from '../../ArcChart';

import UserIcon from '../../../assets/svg/Trends/user.svg';

const Summary = ({ checkInsAmount = 0, AvgEnergy = 6 }) => {
  const { t } = useTranslation();

  return (
    <ChartCard title={t('Summary')} titleIcon={UserIcon}>
      <Wrapper>
        <CheckIn>
          <h1>{checkInsAmount}</h1>
          <span>{t('Check Ins')}</span>
        </CheckIn>
        <Chart AvgEnergy={AvgEnergy} />
      </Wrapper>
    </ChartCard>
  );
};

Summary.propTypes = {
  checkInsAmount: PropTypes.number.isRequired,
  AvgEnergy: PropTypes.number.isRequired
};

export default memo(Summary);
