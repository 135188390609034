import React from 'react';

import { Loading } from './style';

const SimpleLoading = () => (
  <Loading className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </Loading>
);

export default SimpleLoading;
