import React from 'react';
import PropTypes from 'prop-types';

import { Image } from './styles';

const ExerciseImage = ({ fileUrl }) => <Image src={fileUrl} />;

ExerciseImage.propTypes = {
  fileUrl: PropTypes.string.isRequired
};

export { ExerciseImage };
