export const MEDIA_TYPES = {
  video: {
    text: 'Video',
    value: 'video'
  },
  audio: {
    text: 'Audio',
    value: 'audio'
  },
  file: {
    text: 'File',
    value: 'file'
  },
  link: {
    text: 'Link',
    value: 'link'
  },
  image: {
    text: 'Image',
    value: 'image'
  }
};
