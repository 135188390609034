import { gql } from '@apollo/client';

const graph = {
  GET_USER_PUBLICATIONS: gql`
    query userPublications($params: UserSettingFilterInput) {
      user(params: $params) {
        publications {
          id
          name
          scope
          type
          language
          objectName
          flags {
            publicationRecordId
            originId
            flag
          }
        }
      }
    }
  `,
  UPDATE_USER: gql`
    mutation UpdateUser($request: UpdateUserRequest!) {
      updateUser(user: $request) {
        id
        name
        createdDate
      }
    }
  `,
  GET_USER: gql`
    query GetUserAndCheckInsStats($request: CheckInStatsRequest!) {
      user {
        id
        name
        createdDate
      }
      stats(params: $request) {
        currentStreak
        longestStreak
        daysCheckedIn
        count
        hasMorning
        hasAfternoon
        hasEvening
        weekDayIndexes
      }
    }
  `,
  GET_IMPERSONATED_USER: gql`
    query GET_IMPERSONATED_USER($request: ImpersonateUser!) {
      user(where: $request) {
        id
        name
        createdDate
      }
    }
  `
};

export default graph;
