import styled from 'styled-components';

import { Separator as InnerSeparator } from '../../../components/Separator';

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;

  color: #707070;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;

  color: #636463;
`;

export const Separator = styled(InnerSeparator)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const SummaryInnerContainer = styled.div`
  margin-top: 3rem;
`;
