import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  TextTitle,
  TextDescription,
  InnerContainer
} from './styles';

const ExerciseSeparator = ({ content, handleCurrentResponse }) => {
  useEffect(() => {
    handleCurrentResponse({});
  }, [content]);

  return (
    <Container>
      <InnerContainer>
        <TextTitle>{content.title ?? ''}</TextTitle>
        {content?.description && (
          <TextDescription
            dangerouslySetInnerHTML={{
              __html: content.description.replaceAll('\n', '<br/>')
            }}
          />
        )}
      </InnerContainer>
    </Container>
  );
};

ExerciseSeparator.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  handleCurrentResponse: PropTypes.func.isRequired
};

export { ExerciseSeparator };
