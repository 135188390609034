import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@checkingin/component-library';

import {
  PageWrapper,
  FilterWrapper,
  MonthTitle,
  Card,
  CardTitle,
  CardBody,
  SummaryData,
  SummaryTab,
  SummaryWeekList,
  SingleContentWrapper,
  MultipleContentWrapper
} from './style';

import LoadingOverlay from '../../components/LoadingOverlay';

import AverageDailyChart from '../../components/InsightCharts/AverageDailyEnergy';
import TimeOfCheckIn from '../../components/InsightCharts/TimeOfCheckIns';
import WordCloud from '../../components/InsightCharts/WordCloud';
import WordCloudBar from '../../components/InsightCharts/WordCloudBar';

import withServices from '../../services/hoc/insights';
import {
  getAvailableMonths,
  applyMonthFilter,
  getWeekFilterOptions,
  getWeekFilteredData
} from './helpers';

import { getMonthGraphs } from './monthlyGraphs';
import { getWeekGraphs } from './weeklyGraphs';

const Insights = ({ services }) => {
  const { t } = useTranslation();

  const monthSelectRef = useRef();
  const weekSelectRef = useRef();

  const [monthlyGraphs, setMonthlyGraphs] = useState(false);
  const [weeklyGraphs, setWeeklyGraphs] = useState(false);
  const [monthFilter, setMonth] = useState(false);
  const [weekFilter, setWeek] = useState(false);
  const monthFilterOptions = getAvailableMonths();
  const [weekFilterOptions, setWeekFilterOptions] = useState(false);

  // const { month: monthFilter, week: weekFilter } = filters;
  const [isLoading, setIsLoading] = useState(false);
  const [insightsData, setInsightsData] = useState(false);

  const getInsightsData = async () => {
    setIsLoading(true);
    const [responseCheckins, responseReflections, responseMindfulnesses] =
      await Promise.all([
        services.getCheckinData(),
        services.getReflectionsData(),
        services.getMindfulnessData()
      ]);

    const allData = {
      checkins: responseCheckins?.data?.checkIns || [],
      reflections: responseReflections?.data?.reflections || [],
      mindfulnesses: responseMindfulnesses?.data?.mindfulnesses || [],
      hasData:
        responseCheckins?.data?.checkIns.length +
        responseReflections?.data?.reflections.length +
        responseMindfulnesses?.data?.mindfulnesses.length
    };
    setInsightsData(allData);
    setIsLoading(false);
  };

  useEffect(() => {
    getInsightsData();
    if (monthFilterOptions) {
      setMonth(monthFilterOptions[0]);
      monthSelectRef.current.setSelectedItem(monthFilterOptions[0]);
    }
  }, []);

  useEffect(() => {
    if (monthFilter && insightsData) {
      setMonthlyGraphs(
        getMonthGraphs(
          applyMonthFilter(insightsData, monthFilter, false),
          monthFilter,
          false
        )
      );
      setWeekFilterOptions(
        getWeekFilterOptions(monthFilter?.month, monthFilter?.year)
      );
    }
  }, [monthFilter, insightsData]);

  useEffect(() => {
    if (weekFilter && monthFilter && insightsData) {
      const { currentWeekFilteredData, priorWeekFilteredData } =
        getWeekFilteredData(insightsData, weekFilter, weekFilterOptions);

      setWeeklyGraphs(
        getWeekGraphs(currentWeekFilteredData, priorWeekFilteredData)
      );
    }
  }, [weekFilter, insightsData]);

  useEffect(() => {
    if (weekFilterOptions) {
      setWeek(weekFilterOptions[0]);
      weekSelectRef.current.setSelectedItem(weekFilterOptions[0]);
    }
  }, [weekFilterOptions]);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <PageWrapper>
        <SingleContentWrapper>
          <FilterWrapper>
            <MonthTitle>{monthFilter?.monthDisplayRange}</MonthTitle>
            <Select
              items={monthFilterOptions || []}
              onSelect={setMonth}
              shouldValidate={false}
              label="Filter by month"
              placeholder="Select a month"
              ref={monthSelectRef}
            />
          </FilterWrapper>
        </SingleContentWrapper>
        <SingleContentWrapper>
          <Card Area="summary">
            <CardTitle>{t('Summary')}</CardTitle>
            <CardBody>
              <SummaryData>
                <SummaryTab>
                  <span>{t('Check ins')}</span>
                  <span>{monthlyGraphs?.summary?.checkins}</span>
                </SummaryTab>
                <SummaryTab>
                  <span>{t('Reflections')}</span>
                  <span>{monthlyGraphs?.summary?.reflections}</span>
                </SummaryTab>
                <SummaryTab>
                  <span>{t('Mindfulness')}</span>
                  <span>{monthlyGraphs?.summary?.mindfulnesses}</span>
                </SummaryTab>
                <SummaryTab isActions>
                  <span>+{monthlyGraphs?.summary?.actionsCompleted}</span>
                  <span>{t('Actions completed')}</span>
                </SummaryTab>
              </SummaryData>
            </CardBody>
          </Card>
        </SingleContentWrapper>
        <MultipleContentWrapper>
          <Card Area="energy">
            <CardTitle>
              {t('Average daily energy')}:{' '}
              <strong>{monthlyGraphs?.avgDailyEnergy?.average || 0}</strong>
            </CardTitle>
            <CardBody chartContainer>
              <AverageDailyChart
                data={monthlyGraphs?.avgDailyEnergy?.checkins || []}
                month={monthFilter?.text?.substring(0, 3)}
              />
            </CardBody>
          </Card>
          <Card Area="time">
            <CardTitle>{t('Time of Check In')}</CardTitle>
            <CardBody chartContainer>
              <TimeOfCheckIn data={monthlyGraphs?.timeOfCheckIns} />
            </CardBody>
          </Card>
        </MultipleContentWrapper>
        <MultipleContentWrapper monthly>
          <Card Area="cloud">
            <CardTitle>{t('Word cloud')}</CardTitle>
            <CardBody chartContainer>
              <WordCloud data={monthlyGraphs?.wordCloud || []} />
            </CardBody>
          </Card>
          <Card Area="categorized">
            <CardTitle>{t('Words categorized')}</CardTitle>
            <CardBody chartContainer>
              <WordCloudBar data={monthlyGraphs?.wordsCategorized || []} />
            </CardBody>
          </Card>
        </MultipleContentWrapper>
        <SingleContentWrapper>
          <FilterWrapper>
            <MonthTitle>{t('Weekly Report')}</MonthTitle>
            <Select
              items={weekFilterOptions || []}
              onSelect={setWeek}
              label={t('Filter by week')}
              placeholder={t('Select a week')}
              ref={weekSelectRef}
            />
          </FilterWrapper>
        </SingleContentWrapper>
        <MultipleContentWrapper>
          <Card>
            <CardTitle>{t('Summary')}</CardTitle>
            <CardBody chartContainer>
              <SummaryWeekList>
                <li>
                  <span>
                    {t('Total')} # {t('of')} {t('achievements.check ins')}:
                  </span>
                  <span>{weeklyGraphs?.summary?.checkins || 0}</span>
                </li>
                <li>
                  <span>
                    {t('Total')} # {t('of')} {t('achievements.reflections')}:
                  </span>
                  <span>{weeklyGraphs?.summary?.reflections || 0}</span>
                </li>
                <li>
                  <span>
                    {t('Total')} # {t('of')} {t('Mindfulness').toLowerCase()}{' '}
                    {t('Exercises').toLowerCase()}:
                  </span>
                  <span>{weeklyGraphs?.summary?.mindfulnesses || 0}</span>
                </li>
                <li>
                  <span>{t('messages.New actions completed')}:</span>
                  <span>{weeklyGraphs?.summary?.actionsCompleted || 0}</span>
                </li>
              </SummaryWeekList>
            </CardBody>
          </Card>
          <Card>
            <CardTitle>
              {t('Average energy')}:{' '}
              <strong>{weeklyGraphs?.avgDailyEnergy?.average || 0}</strong>
            </CardTitle>
            <CardBody chartContainer>
              <AverageDailyChart
                data={weeklyGraphs?.avgDailyEnergy?.checkins || []}
                colors={weeklyGraphs?.avgDailyEnergy?.barColors}
                month={weekFilter?.text}
                week
              />
            </CardBody>
          </Card>
          <Card>
            <CardTitle>{t('Word cloud')}</CardTitle>
            <CardBody>
              <WordCloud data={weeklyGraphs?.wordCloud || []} />
            </CardBody>
          </Card>
        </MultipleContentWrapper>
      </PageWrapper>
    </>
  );
};

Insights.propTypes = {
  services: PropTypes.object.isRequired
};

export default withServices(Insights);
