import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  ButtonContainer,
  ButtonsContainer,
  ButtonText,
  Container,
  DescriptionContainer,
  InnerContainer,
  TitleContainer
} from './styles';

const ExerciseModal = ({
  isOpen,
  message,
  onClose,
  callback,
  handleConfirm
}) => {
  const { t } = useTranslation();

  const handleOnClick = (canLeave = false) => {
    onClose();
    callback(canLeave);

    if (canLeave) handleConfirm();
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="exercise-modal-title"
      aria-describedby="exercise-modal-description"
    >
      <Container>
        <InnerContainer>
          <TitleContainer>{t('Attention')}</TitleContainer>
          <DescriptionContainer>{message}</DescriptionContainer>
        </InnerContainer>
        <ButtonsContainer>
          <ButtonContainer isCancel onClick={() => handleOnClick()}>
            <ButtonText isCancel>{t('Cancel')}</ButtonText>
          </ButtonContainer>
          <ButtonContainer onClick={() => handleOnClick(true)}>
            <ButtonText>{t('Confirm')}</ButtonText>
          </ButtonContainer>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

ExerciseModal.defaultProps = {
  isOpen: true,
  onClose: () => {},
  callback: () => {},
  handleConfirm: () => {}
};

ExerciseModal.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  callback: PropTypes.func,
  handleConfirm: PropTypes.func
};

export { ExerciseModal };
