import { gql } from '@apollo/client';
import fragments from '../graphql-queries/fragments';

const queries = {
  GET_INSIGHTS: gql`
    query GetInsights($where: InsightsDateRangeFilter!) {
      insights(where: $where) {
        ...InsightProperties
      }
      user {
        id
        name
        isAdmin
      }
    }
    ${fragments.Insights}
  `
};

export default queries;
