import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

import { ContainerIconText, Image, Text } from './styles';

const FileIcon = ({
  iconType,
  isDownloaded,
  isDownloading,
  isPreview,
  text,
  ...props
}) => {
  const { t } = useTranslation();

  const renderIconText = () => {
    const currentText = iconType?.isDownload ? t('Download') : text;

    return (
      <ContainerIconText {...props}>
        <Image src={iconType?.icon} />
        {currentText.length > 0 && <Text>{currentText}</Text>}
      </ContainerIconText>
    );
  };

  if (iconType?.isDownload && isDownloaded) {
    if (isDownloading) {
      return (
        <ContainerIconText {...props}>
          <CircularProgress color="success" size={20} />
        </ContainerIconText>
      );
    }

    return renderIconText();
  }

  if (iconType && !iconType?.isDownload && isPreview) return renderIconText();

  if (iconType && !iconType?.isDownload && !isPreview) return renderIconText();

  return null;
};

FileIcon.defaultProps = {
  iconType: null,
  isPreview: false,
  text: ''
};

FileIcon.propTypes = {
  iconType: PropTypes.shape({
    isDownload: PropTypes.bool,
    icon: PropTypes.node
  }),
  isDownloaded: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool,
  text: PropTypes.string
};

export { FileIcon };
