import { gql } from '@apollo/client';

const fragments = {
  checkIn: gql`
    fragment CheckInProperties on CheckIn {
      energyLevel
      feelingWord
      feelingContext
      createdDate
      weather {
        weatherId
        weatherMain
        weatherIcon
      }
    }
  `,
  Insights: gql`
    fragment InsightProperties on Insights {
      group {
        name
        insightsFilter
      }
      subGroups {
        name
        reflections {
          id
          completedDate
        }
        mindfulness {
          id
          completedDate
        }
        checkIns {
          id
          energyLevel
          createdDate
          feelingWord
          feelingContext
          owner {
            id
          }
        }
      }
    }
  `
};

export default fragments;
