import styled from 'styled-components';

export const Tooltip = styled.section`
  width: 20vh;
  background-color: #fff;
  color: ${({ color }) => color || 'black'};
  border: 2px solid #ccc;
  padding: 0;
  margin: 0;
`;
