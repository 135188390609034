import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useDeleteCheckin } from '../../services/mutations/checkin';

import Modal from '../../components/Modal';

import { Title, Line, WrapperButtons, Button } from './style';

const DeleteCheckinModal = ({ isVisible, closeModal, checkinId, refetch }) => {
  const { t } = useTranslation();
  const [deleteCheckIn] = useDeleteCheckin();

  const handleDelete = async () => {
    const deleteVariables = {
      variables: {
        request: { id: checkinId }
      }
    };

    try {
      await toast.promise(deleteCheckIn(deleteVariables), {
        loading: `${t('Saving')}...`,
        success: t('messages.Your CheckingIn was deleted with success'),
        error: t(
          'messages.Your CheckingIn could not be deleted Please try again'
        )
      });
      refetch();
      closeModal();
    } catch (e) {
      console.error('Error on delete checkin', e);
      closeModal();
    }
  };

  return (
    <Modal isVisible={isVisible} closeModal={closeModal}>
      <div>
        <Title>{t('messages.Do you really want to delete the check-in')}</Title>
        <Line />
        <WrapperButtons>
          <Button color="#888" type="button" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button color="#ea6944" type="button" onClick={handleDelete}>
            {t('Delete')}
          </Button>
        </WrapperButtons>
      </div>
    </Modal>
  );
};

DeleteCheckinModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  checkinId: PropTypes.string.isRequired
};

export default DeleteCheckinModal;
