const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
  color: 'brand.primary',
  fontWeight: 700
};

const variants = {
  floating: {
    container: {
      _focusWithin: {
        label: {
          ...activeLabelStyles
        }
      },
      'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
        {
          ...activeLabelStyles
        },
      'input:not(:placeholder-shown) + .chakra-input__right-element + label, .chakra-input__group + .chakra-input__right-element + label, textarea:not(:placeholder-shown) ~ label':
        {
          ...activeLabelStyles
        },
      label: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'absolute',
        backgroundColor: 'transparent',
        pointerEvents: 'none',
        my: 2,
        transformOrigin: 'left top'
      }
    }
  }
};

export default { variants };
