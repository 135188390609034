import { gql } from '@apollo/client';

const queries = {
  GET_WHITE_LABEL_APP: gql`
    query Query {
      whiteLabelApp {
        meta
      }
    }
  `
};

export default queries;
