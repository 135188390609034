export const getDateOptions = () => {
  const months = {
    0: { label: 'Jan.', name: 'January' },
    1: { label: 'Feb.', name: 'February' },
    2: { label: 'Mar.', name: 'March' },
    3: { label: 'Apr.', name: 'April' },
    4: { label: 'May', name: 'May' },
    5: { label: 'Jun.', name: 'June' },
    6: { label: 'Jul.', name: 'July' },
    7: { label: 'Aug.', name: 'August' },
    8: { label: 'Sep.', name: 'September' },
    9: { label: 'Oct.', name: 'October' },
    10: { label: 'Nov.', name: 'November' },
    11: { label: 'Dec.', name: 'December' }
  };
  const currentMonth = new Date().getMonth() - 1;
  const currentYear = new Date().getFullYear();
  const getNumberOfDays = (year, month) =>
    new Date(year, month + 1, 0).getDate();
  const data = [];
  for (let index = currentMonth; index < 12 - currentMonth; index += 1) {
    data.push({
      label: `${months[index + currentMonth].label} ${currentYear - 1}`,
      name: months[index + currentMonth].name,
      phrase: `${months[index + currentMonth].name} 1 to ${getNumberOfDays(
        currentYear - 1,
        index + currentMonth
      )}`,
      value: `${String(index + currentMonth + 1).padStart(2, 0)}-${
        currentYear - 1
      }`,
      start: `${String(index + currentMonth + 1).padStart(2, 0)}-01-${
        currentYear - 1
      }`,
      end: `${String(index + currentMonth + 1).padStart(
        2,
        0
      )}-${getNumberOfDays(currentYear - 1, index + currentMonth)}-${
        currentYear - 1
      }`
    });
  }
  for (let index = 0; index <= currentMonth + 1; index += 1) {
    data.push({
      label: `${months[index].label} ${currentYear}`,
      name: months[index].name,
      phrase: `${months[index].name} 1 to ${getNumberOfDays(
        currentYear,
        index
      )}`,
      value: `${String(index + 1).padStart(2, 0)}-${currentYear}`,
      start: `${String(index + 1).padStart(2, 0)}-01-${currentYear}`,
      end: `${String(index + 1).padStart(2, 0)}-${getNumberOfDays(
        currentYear,
        index
      )}-${currentYear}`
    });
  }
  return data;
};

const getLastDateFromMonth = (year, monthIndex) => {
  const date = new Date(year, monthIndex);
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  return {
    displayMonth: {
      short: date.toLocaleDateString('en-US', { month: 'short' }),
      long: date.toLocaleDateString('en-US', { month: 'long' })
    },
    lastDate
  };
};

const getMonthOptions = (date) => {
  let [year, month] = [date.getFullYear(), date.getMonth() + 1];
  const dates = [];
  for (let i = 0; i < 12; i++, month--) {
    if (month === 0) {
      month = 12;
      year--;
    }
    dates.push({
      year,
      month
    });
  }
  return dates;
};

export const getAvailableMonths = () => {
  const availableMonths = getMonthOptions(new Date());
  return availableMonths.map((date) => {
    const {
      displayMonth: { short, long },
      lastDate
    } = getLastDateFromMonth(date.year, date.month - 1);

    return {
      value: `${date.year}${date.month}`,
      month: date.month,
      year: date.year,
      days: lastDate,
      monthDisplayRange: `${long} 1 to ${lastDate}`,
      text: `${short}. ${date.year}`
    };
  });
};

const monthFilter = (data, filter, dateKey) =>
  data.filter((item) => {
    const itemDate = item[dateKey]?.split('-');
    if (itemDate) {
      return `${itemDate[0]}${itemDate[1]}` === filter;
    }

    return false;
  });

export const applyMonthFilter = (data, filter, isGroup) => {
  const currentFilter = `${filter.year}${
    filter.month < 10 ? `0${filter.month}` : filter.month
  }`;

  if (isGroup) {
    return data.map((subGroup) => ({
      checkins: monthFilter(subGroup.checkIns, currentFilter, 'createdDate'),
      reflections: monthFilter(
        subGroup.reflections,
        currentFilter,
        'completedDate'
      ),
      mindfulnesses: monthFilter(
        subGroup.mindfulness,
        currentFilter,
        'completedDate'
      )
    }));
  }

  return {
    checkins: monthFilter(data.checkins, currentFilter, 'createdDate'),
    reflections: monthFilter(data.reflections, currentFilter, 'completedDate'),
    mindfulnesses: monthFilter(
      data.mindfulnesses,
      currentFilter,
      'completedDate'
    )
  };
};

const getWeekText = (date) =>
  date.toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric',
    day: 'numeric'
  });

const getWeekValue = (date) =>
  `${date.getMonth() + 1}${date.getDate()}${date.getFullYear()}`;

const getFirtSundayOfMonth = (month = '01', year = '2021') =>
  new Date(
    new Date(`${month}/01/${year}`).setDate(
      1 + (new Date(`${month}/01/${year}`).getDay() - 7) * -1
    )
  );

export const getWeekFilterOptions = (month, year) => {
  const dateStartedAtFirstSunday = getFirtSundayOfMonth(month, year);
  const intervals = [];

  while (dateStartedAtFirstSunday.getMonth() + 1 === parseInt(month, 10)) {
    const interval = { text: '', value: '', startTime: 0, endTime: 0 };

    interval.text = getWeekText(dateStartedAtFirstSunday);
    interval.startTime = dateStartedAtFirstSunday.getTime();
    interval.value = getWeekValue(dateStartedAtFirstSunday);

    dateStartedAtFirstSunday.setDate(dateStartedAtFirstSunday.getDate() + 7);

    interval.text += ` - ${getWeekText(dateStartedAtFirstSunday)}`;
    interval.endTime = dateStartedAtFirstSunday.getTime();
    interval.value += `-${getWeekValue(dateStartedAtFirstSunday)}`;

    intervals.push(interval);
  }

  return intervals.reverse();
};

const weekFilter = (data = [], filter, dateKey) =>
  data.filter((item) => {
    const itemDate = item[dateKey]?.split('T');

    if (itemDate) {
      const currentTime = new Date(itemDate[0]).getTime();

      if (currentTime >= filter.start && currentTime <= filter.end) {
        return true;
      }
    }

    return false;
  });

const applyWeekFilter = (data = [], filter) => {
  const currentFilter = {
    start: filter?.startTime,
    end: filter?.endTime
  };

  return {
    checkins: weekFilter(data.checkins, currentFilter, 'createdDate'),
    reflections: weekFilter(data.reflections, currentFilter, 'completedDate'),
    mindfulnesses: weekFilter(
      data.mindfulnesses,
      currentFilter,
      'completedDate'
    )
  };
};

const getPriorWeekFilter = (selectedFilter, allWeekFilters) => {
  let selectedIndex = -1;

  allWeekFilters.find((filter, i) => {
    if (filter.id === selectedFilter.id) {
      selectedIndex = i + 1;
      return true;
    }
    return false;
  });

  return allWeekFilters[selectedIndex];
};

export const getWeekFilteredData = (data, currentfilter, allWeekFilters) => {
  const priorWeekFilter = getPriorWeekFilter(currentfilter, allWeekFilters);

  return {
    priorWeekFilteredData: applyWeekFilter(data, priorWeekFilter),
    currentWeekFilteredData: applyWeekFilter(data, currentfilter)
  };
};
