import { getMachineClient } from '../../../../services/apollo-client';

import forgotPasswordQueries from '../../../../services/queries/ForgotPassword';
import whiteLabelAppQueries from '../../../../services/queries/WhiteLabelApp';
import forgotPasswordMutations from '../../../../services/mutations/ForgotPassword';

import { GetTokenValidationProps, ChangePasswordProps } from '../types';

const useForgotPassword = ({ token }: { token: string }) => {
  const machineClient = getMachineClient(token);

  const getTokenValidation = async () =>
    machineClient.query<GetTokenValidationProps>({
      query: forgotPasswordQueries.GET_TOKEN_VALIDATION
    });

  const resetPassword = async (newPassword: string) =>
    machineClient.mutate<ChangePasswordProps>({
      mutation: forgotPasswordMutations.RESET_PASSWORD,
      variables: {
        params: {
          newPassword
        }
      }
    });

  const getRedirectLink = async () => {
    const {
      data: { whiteLabelApp: appData }
    } = await machineClient.query({
      query: whiteLabelAppQueries.GET_WHITE_LABEL_APP
    });

    return appData?.meta?.links?.website;
  };

  return {
    // Queries
    getTokenValidation,
    getRedirectLink,

    // Mutations
    resetPassword
  };
};

export { useForgotPassword };
