import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { formatUser } from '../../helpers/user';

export const withUser = (Component) => (props) => {
  const { user, logout } = useAuth0();
  const formattedUser = formatUser(user);

  return <Component user={formattedUser} logout={logout} {...props} />;
};
