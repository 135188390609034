import styled, { css } from 'styled-components';
import { breakpoints, colors, spacing } from '@checkingin/component-library';
import { Box, LinearProgress } from '@mui/material';
import { FontAwesomeIcon as InnerFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIconProps } from './types';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1.5rem;
  margin: 0.625rem 0.625rem 8rem 0.625rem;
  background-color: ${colors.white.absolute};
  width: 100%;
  height: fit-content;
  gap: 2rem;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);

  @media (min-width: ${breakpoints.tablet}px) {
    width: max-content;
    min-width: 50%;
    max-width: 50%;
  }
`;

export const Audio = styled.audio`
  max-height: auto;
  max-width: 25rem;
  width: 100%;
`;

export const Button = styled.div.attrs(
  (props: { disabled?: boolean }) => props
)`
  border-radius: 1.5rem;
  width: 80%;
  text-align: center;
  background-color: ${colors.primary};
  color: ${colors.white.absolute};
  padding: ${spacing.xs} ${spacing.xl};
  cursor: pointer;

  @media (min-width: ${breakpoints.tablet}px) {
    width: calc(50% + 2rem);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #f4f4f2;
      color: #7c7c7a;
      cursor: not-allowed;
    `}
`;

export const ButtonText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-style: normal;
  font-weight: bold;
  line-height: 1.5rem;
`;

export const ButtonContainer = styled.div`
  padding: 1.25rem 1.5rem;
  background-color: ${colors.white.absolute};
  width: 100%;
`;

export const ProgressBar = styled(LinearProgress)`
  width: 100%;

  background-color: #e4e4e4 !important;

  .MuiLinearProgress-bar {
    background-color: ${colors.primary};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FontAwesomeIcon = styled(
  InnerFontAwesomeIcon
)<FontAwesomeIconProps>`
  font-size: 1.3rem;
  cursor: pointer;

  color: ${({ $isReport }) => ($isReport ? '#e78165' : '#636463')} !important;
`;

export const HeaderTitle = styled.span`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.875rem;
  color: #636463;
`;
