import axios from 'axios';

export function getWeatherName(weatherId, weatherIcon) {
  if (weatherId && weatherIcon) {
    const weatherIdNumber = Number(weatherId);

    if (
      weatherIcon.charAt(2) === 'd' &&
      (weatherIdNumber === 800 || weatherIdNumber === 801)
    ) {
      return 'Sunny';
    }
    if (
      weatherIcon.charAt(2) === 'n' &&
      (weatherIdNumber === 800 || weatherIdNumber === 801)
    ) {
      return 'Clear';
    }
    if (
      weatherIcon.charAt(2) === 'd' &&
      weatherIdNumber >= 802 &&
      weatherIdNumber <= 804
    ) {
      return 'Cloudy';
    }
    if (
      weatherIcon.charAt(2) === 'n' &&
      weatherIdNumber >= 802 &&
      weatherIdNumber <= 804
    ) {
      return 'Cloudy';
    }
    if (weatherIdNumber >= 600 && weatherIdNumber <= 622) {
      return 'Snow';
    }
    if (
      weatherIcon.charAt(2) === 'd' &&
      weatherIdNumber >= 200 &&
      weatherIdNumber <= 531
    ) {
      return 'Rain';
    }
    if (
      weatherIcon.charAt(2) === 'n' &&
      weatherIdNumber >= 200 &&
      weatherIdNumber <= 531
    ) {
      return 'Rain';
    }
    if (weatherIdNumber === 701) {
      return 'Fog';
    }
    if (weatherIdNumber === 711) {
      return 'Smoke';
    }
    if (weatherIdNumber === 721) {
      return 'Haze';
    }
    if (
      weatherIdNumber === 731 ||
      weatherIdNumber === 751 ||
      weatherIdNumber === 761
    ) {
      return 'Dust';
    }
    if (weatherIdNumber === 741) {
      return 'Fog';
    }
    if (weatherIdNumber === 781) {
      return 'Tornado';
    }
    return false;
  }
  return null;
}

export function getWeatherIconName(weatherId, weatherIcon) {
  if (weatherId && weatherIcon) {
    const weatherIdNumber = Number(weatherId);

    if (
      weatherIcon.charAt(2) === 'd' &&
      (weatherIdNumber === 800 || weatherIdNumber === 801)
    ) {
      return 'sun';
    }
    if (
      weatherIcon.charAt(2) === 'n' &&
      (weatherIdNumber === 800 || weatherIdNumber === 801)
    ) {
      return 'moon';
    }
    if (
      weatherIcon.charAt(2) === 'd' &&
      weatherIdNumber >= 802 &&
      weatherIdNumber <= 804
    ) {
      return 'clouds';
    }
    if (
      weatherIcon.charAt(2) === 'n' &&
      weatherIdNumber >= 802 &&
      weatherIdNumber <= 804
    ) {
      return 'clouds-moon';
    }
    if (weatherIdNumber >= 600 && weatherIdNumber <= 622) {
      return 'snowflakes';
    }
    if (
      weatherIcon.charAt(2) === 'd' &&
      weatherIdNumber >= 200 &&
      weatherIdNumber <= 531
    ) {
      return 'cloud-sun-rain';
    }
    if (
      weatherIcon.charAt(2) === 'n' &&
      weatherIdNumber >= 200 &&
      weatherIdNumber <= 531
    ) {
      return 'cloud-moon-rain';
    }
    if (weatherIdNumber === 701) {
      return 'fog';
    }
    if (weatherIdNumber === 711) {
      return 'smoke';
    }
    if (weatherIdNumber === 721) {
      return 'sun-haze';
    }
    if (
      weatherIdNumber === 731 ||
      weatherIdNumber === 751 ||
      weatherIdNumber === 761
    ) {
      return 'sun-dust';
    }
    if (weatherIdNumber === 741) {
      return 'fog';
    }
    if (weatherIdNumber === 781) {
      return 'tornado';
    }
    return false;
  }
  return null;
}

export async function getWeatherFromAPI(lat, lng) {
  const apikey = process.env.REACT_APP_WEATHER_API_KEY;
  let weather;
  try {
    const { data } = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&APPID=${apikey}`
    );
    weather = {
      coordLat: data.coord.lat || null,
      coordLng: data.coord.lon || null,
      weatherId: data.weather[0].id || null,
      weatherMain: data.weather[0].main || null,
      weatherIcon: data.weather[0].icon || null,
      temp: data.main.temp || null,
      pressure: data.main.pressure || null,
      humidity: data.main.humidity || null,
      tempMin: data.main.temp_min || null,
      tempMax: data.main.temp_max || null,
      visibility: data.visibility || null,
      windSpeed: data.wind.speed || null,
      windDeg: data.wind.deg || null,
      clouds: data.clouds.all || null,
      sunrise: data.sys.sunrise || null,
      sunset: data.sys.sunset || null,
      cityName: data.name || null
    };
  } catch (err) {
    console.error('Error getting weather: ', err);
  }

  return weather;
}
