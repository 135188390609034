import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useContent } from '../../contexts/ContentContext';
import contentHistoryGraph from '../../services/graphql-queries/contentHistory';
import programsImg from '../../assets/img/programs-button@3x.png';
import CardHeader from '../../components/CardHeader';
import { isProgramLocked } from '../../helpers/programs';
import ContentLabel from '../../components/ContentLabel';
import { ExerciseDetailsInfo } from './ExerciseDetailsInfo';
import { ExerciseSectionsPreview } from './ExerciseSectionsPreview';
import { a11yProps } from '../../helpers/material-ui';
import { TabPanel } from '../../components/TabComponent/TabPanel';
import { ExerciseModal } from '../../components/Modal/ExerciseModal';
import LoadingSpinner from '../../components/LoadingSpinner';

import {
  ContainerCard,
  Container,
  ContentImg,
  Tabs,
  Tab,
  FailContainer,
  FailText,
  Button,
  ExerciseTitleWrapper,
  ExerciseTitle,
  ButtonWrapper
} from './styles';

const Exercise = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { exerciseId } = useParams();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const publicationId = params.get('publicationId');
  const redirectedFromExercise = params.get('redirectedFromExercise');
  const redirectedFromProgram = params.get('redirectedFromProgram');
  const redirectedFromPublication = params.get('redirectedFromPublication');

  const { exercises, programs, addContentHistories, contentHistories } =
    useContent();

  const [exercise, setExercise] = useState(null);
  const [program, setProgram] = useState(null);

  const [currentTab, setCurrentTab] = useState(0);
  const [userAlertProgress, setUserAlertProgress] = useState(false);

  const [getContentHistoryExercise, { loading, data: exerciseContentHistory }] =
    useLazyQuery(contentHistoryGraph.GET_CONTENT_HISTORY_RESPONSE, {
      fetchPolicy: 'network-only',
      variables: {
        params: {
          contentExerciseId: exercise?.id,
          publicationRecordId: exercise?.publicationId
        }
      },
      onCompleted: () => {
        if (exerciseContentHistory?.contentHistoryResponse) {
          setExercise({
            ...exercise,
            responseData:
              exerciseContentHistory.contentHistoryResponse?.responseData
          });
          addContentHistories({
            exerciseContentHistories: [
              exerciseContentHistory.contentHistoryResponse
            ]
          });
        }
      }
    });

  const [saveContentHistory] = useMutation(
    contentHistoryGraph.SAVE_CONTENT_HISTORY,
    {
      onCompleted: async (data) => {
        await addContentHistories({
          exerciseContentHistories: [data.saveContentHistory]
        });
      }
    }
  );

  useEffect(() => {
    if (exerciseId && exercises.length > 0) {
      const exerciseSelected = publicationId
        ? exercises.find(
            (item) =>
              item.id === exerciseId && item?.publicationId === publicationId
          )
        : exercises.find((item) => item.id === exerciseId);

      if (exerciseSelected) {
        const responseData = contentHistories?.find(
          (item) =>
            item.cmsId === exerciseSelected.id &&
            item.publicationRecordId === exerciseSelected.publicationId
        );

        setExercise({
          ...exerciseSelected,
          sections: exerciseSelected?.sections?.sort(
            (a, b) => a.order - b.order
          ),
          responseData: responseData?.responseData
        });

        if (!responseData) {
          getContentHistoryExercise();
        }

        const programSelected = programs.find(
          (currentItem) =>
            currentItem.id === exerciseSelected?.categories[0].id &&
            currentItem.publicationId === exerciseSelected?.publicationId
        );
        setProgram(programSelected);
      } else {
        setExercise(null);
        setProgram(null);
      }
    }
  }, [exerciseId, publicationId, exercises, contentHistories]);

  const handleStartExercise = async () => {
    await saveContentHistory({
      variables: {
        params: {
          cmsId: exercise.id,
          publicationRecordId: exercise.publicationId,
          responseData: []
        }
      }
    });

    push(
      `/exercise/${exercise.id}/content?publicationId=${exercise.publicationId}`,
      {
        exercise,
        program,
        redirectedFrom: {
          exerciseId: redirectedFromExercise,
          programId: redirectedFromProgram,
          publicationId: redirectedFromPublication
        }
      }
    );
  };

  const onClickBegin = async () => {
    if (exercise?.responseData && exercise?.responseData.length > 0) {
      setUserAlertProgress(true);
      return;
    }

    await handleStartExercise();
  };

  const handleCloseModal = () => {
    setUserAlertProgress(false);
  };

  const handleConfirmModal = async () => {
    await handleStartExercise();
    handleCloseModal();
  };

  const source = useMemo(
    () => ({ uri: exercise?.backgroundImage || exercise?.imageUrl }),
    [exercise?.backgroundImage, exercise?.imageUrl]
  );

  const isLocked = useMemo(
    () => isProgramLocked(exercise?.flag),
    [exercise?.flag]
  );

  const tabs = useMemo(() => {
    const availableTabs = [
      {
        label: t('Summary'),
        content: <ExerciseDetailsInfo exercise={exercise} />
      }
    ];

    if (exercise?.sections?.length > 1) {
      availableTabs.push({
        label: exercise?.sectionsName,
        content: (
          <ExerciseSectionsPreview
            sections={exercise?.sections.map((section) => ({
              sectionId: section.id,
              title: section.contentJson.title || 'title',
              isDone: exercise?.responseData?.some(
                (current) => section.id === current.sectionId
              )
            }))}
          />
        )
      });
    }

    return availableTabs;
  }, [exercise]);

  if (loading) {
    return <LoadingSpinner wrapper />;
  }

  if (!exercise && !loading) {
    return (
      <FailContainer>
        <FailText>{t('Fail to load this exercise')}</FailText>
      </FailContainer>
    );
  }

  return (
    <ContainerCard>
      <CardHeader
        title={t('Exercise Details')}
        goPrevious={() => {
          if (
            redirectedFromExercise &&
            redirectedFromProgram &&
            redirectedFromPublication
          ) {
            push(
              `/program/${redirectedFromProgram}?publicationId=${redirectedFromPublication}`
            );
            return;
          }

          push(
            `/program/${exercise.categories[0].id}?publicationId=${exercise.publicationId}`
          );
        }}
      />
      <Container>
        <ContentImg backgroundImage={source.uri || programsImg}>
          <ContentLabel status={exercise?.flag} position="under-top" />
          <ExerciseTitleWrapper>
            <ExerciseTitle>{exercise.title}</ExerciseTitle>
          </ExerciseTitleWrapper>
        </ContentImg>
        <Tabs
          value={currentTab}
          onChange={(_event, newValue) => setCurrentTab(newValue)}
          aria-label="Exercise-Tab"
          centered
          variant="fullWidth"
        >
          {tabs.map(({ label }, tabIndex) => (
            <Tab
              key={`Tab-${tabIndex}`}
              label={label}
              {...a11yProps(tabIndex)}
              wrapped
            />
          ))}
        </Tabs>
        {tabs.map(({ content }, tabIndex) => (
          <TabPanel
            value={currentTab}
            index={tabIndex}
            key={`TabPanel-${tabIndex}`}
          >
            {content}
          </TabPanel>
        ))}
        <ButtonWrapper>
          <Button
            label={isLocked ? t('Upcoming') : t('Begin')}
            disabled={isLocked}
            onClick={() => onClickBegin()}
          />
        </ButtonWrapper>
      </Container>
      <ExerciseModal
        isOpen={userAlertProgress}
        onClose={() => handleCloseModal()}
        message={t('messages.Are you sure you want to start over')}
        handleConfirm={() => handleConfirmModal()}
      />
    </ContainerCard>
  );
};

export { Exercise };
