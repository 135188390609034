import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import { PropTypes } from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import { fetchContentJSON } from '../services/fetchContentJSON';
import { useUserData } from './UserDataContext';
import { loadPublicationsV2, loadPublicationsV3 } from '../helpers/programs';
import LoadingSpinner from '../components/LoadingSpinner';
import userGraph from '../services/graphql-queries/user';

const ContentContext = createContext({});

function useContent() {
  return useContext(ContentContext);
}

function ContentProvider({ children }) {
  const { userData } = useUserData();

  const [getUserPublications, { loading, refetch: refetchContent }] =
    useLazyQuery(userGraph.GET_USER_PUBLICATIONS);

  const [programs, setPrograms] = useState([]);
  const [exercises, setExercises] = useState([]);

  const [contentHistories, setContentHistory] = useState([]);

  const addContentHistories = useCallback(
    async ({ exerciseContentHistories = [], reloadFlags = false }) => {
      setContentHistory([
        ...contentHistories.filter(
          (item) =>
            !exerciseContentHistories.some(
              (o) =>
                o.cmsId === item.cmsId &&
                o.publicationRecordId === item.publicationRecordId
            )
        ),
        ...exerciseContentHistories
      ]);

      if (reloadFlags) {
        await refetchContent();
      }
    },
    [contentHistories]
  );

  const fetchPublications = async () => {
    const res = await getUserPublications({
      variables: { params: { version: '2.4.0', platform: 'web' } }
    });

    const userContentHistory = res?.data;

    if (!userContentHistory?.user?.publications) return;

    const publicationsAvailable = userContentHistory?.user.publications;

    const publicationData = await Promise.all(
      publicationsAvailable.map(async (publication) => ({
        data: await fetchContentJSON(publication.objectName),
        publication
      }))
    );

    let publicationsLoaded;

    if (publicationData[0]?.data?.content) {
      publicationsLoaded = loadPublicationsV3({
        publicationsAPI: userContentHistory?.user?.publications,
        publicationsJSON: publicationData
      });
    } else {
      publicationsLoaded = loadPublicationsV2({
        publicationsAPI: userContentHistory?.user?.publications,
        publicationsJSON: publicationData
      });
    }

    const programsLoaded = publicationsLoaded?.programsLoaded || [];
    const exercisesLoaded = publicationsLoaded?.exercisesLoaded || [];

    setPrograms(programsLoaded);
    setExercises(exercisesLoaded);
  };

  useEffect(() => {
    if (userData && !loading) {
      fetchPublications();
    }
  }, [userData, loading]);

  return (
    <ContentContext.Provider
      value={{
        loading,
        refetchContent,
        refetchPublications: fetchPublications,
        programs,
        exercises,
        contentHistories,
        addContentHistories
      }}
    >
      {loading ? <LoadingSpinner wrapper /> : children}
    </ContentContext.Provider>
  );
}

ContentProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export { ContentProvider, useContent };
