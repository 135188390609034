import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  width: max(${({ width }) => width || '27vw, 520px'});
  height: max(${({ height }) => height || '30vh'});
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 1vh;
  grid-template-rows: 3vh 1fr;

  & > div:first-of-type {
    width: 100%;
    height: 3vh;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1vw;

    & > span:first-of-type {
      font-size: 24px;
    }

    & > img:first-of-type {
      width: 24px;
      height: 24px;
    }
  }

  & > div:last-of-type {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;
