const navigateToHome = (historyPush) => {
  historyPush('/home');
};

export const handleNavigationOnComplete = ({
  historyPush,
  program,
  exercise,
  redirectedFrom
}) => {
  if (redirectedFrom?.exerciseId) {
    historyPush(
      `/exercise/${redirectedFrom.exerciseId}?publicationId=${redirectedFrom.publicationId}`
    );

    return;
  }

  const programExerciseLength = program?.exercises?.length;

  if (programExerciseLength === 1) {
    navigateToHome(historyPush);
    return;
  }

  const currentExercisePosition = program?.exercises?.findIndex(
    (exerciseIndex) => exerciseIndex.id === exercise.id
  );

  if (currentExercisePosition === programExerciseLength - 1) {
    navigateToHome(historyPush);
  } else {
    historyPush(
      `/program/${exercise.categories[0].id}?publicationId=${exercise.publicationId}`
    );
  }
};
