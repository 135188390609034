import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
`;
export const CheckIn = styled.div`
  display: inline-block;
  > span,
  h1 {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
`;

export const Chart = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span,
  h1 {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  > svg {
    position: absolute;
    width: 10vw;
    height: 10vh;
    left: 50%;
    top: 0;
    transform: translate(-50%, 30%);
  }
`;
