import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ChartCard from '../../ChartCard';
import MostCommonIcon from '../../../assets/svg/Trends/star.svg';

import { Table } from './style';

const MostCommon = ({ words }) => {
  const { t } = useTranslation();
  const [wordCount, setWordCount] = useState([]);

  useEffect(() => {
    let count = [];
    [...new Set(words)].forEach((word) => {
      count.push({ word, count: 0 });
    });
    words.forEach((word) => {
      const index = count.findIndex((element) => element.word === word);
      if (index > -1) {
        count[index].count += 1;
      }
    });
    count = count.sort((a, b) => b.count - a.count);
    setWordCount(count.slice(0, 5));
  }, []);

  return (
    <ChartCard title={t('Most common')} titleIcon={MostCommonIcon}>
      <Table>
        <thead>
          <tr>
            <th>{t('Word')}</th>
            <th>{t('Count')}</th>
          </tr>
        </thead>
        <tbody>
          {wordCount.map(({ word, count }) => (
            <tr key={word}>
              <td>{word}</td>
              <td>{count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </ChartCard>
  );
};

MostCommon.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MostCommon;
