import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { replaceHtmlDeepLink } from '../../../helpers/replaceHtmlDeepLink';
import { stripTextFromHtml } from '../../../helpers/html';

import { Title, Text, Separator, SummaryInnerContainer } from './styles';

export const EMPTY_AUTHOR = 'empty author';

const ExerciseDetailsInfo = ({ exercise }) => {
  const { t } = useTranslation();

  return (
    <>
      {stripTextFromHtml(exercise?.description) && (
        <>
          <Title>{t('Description').toUpperCase()}</Title>
          <Separator />
          <Text
            dangerouslySetInnerHTML={{
              __html: replaceHtmlDeepLink(exercise.description, exercise)
            }}
          />
        </>
      )}

      {stripTextFromHtml(exercise?.benefits) && (
        <SummaryInnerContainer>
          <Title>{t('Benefits').toUpperCase()}</Title>
          <Separator />
          <Text
            dangerouslySetInnerHTML={{
              __html: replaceHtmlDeepLink(exercise.benefits, exercise)
            }}
          />
        </SummaryInnerContainer>
      )}

      {stripTextFromHtml(exercise?.benefits) &&
        exercise.author.background !== '' &&
        exercise.author.name.toLowerCase() !== EMPTY_AUTHOR && (
          <SummaryInnerContainer>
            <Title>
              {t('About').toUpperCase()} {exercise.author.name}
            </Title>
            <Separator />
            <Text
              dangerouslySetInnerHTML={{ __html: exercise.author.background }}
            />
          </SummaryInnerContainer>
        )}
    </>
  );
};

ExerciseDetailsInfo.defaultProps = {
  exercise: null
};

ExerciseDetailsInfo.propTypes = {
  exercise: PropTypes.shape({
    description: PropTypes.string,
    benefits: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
      background: PropTypes.string
    })
  })
};

export { ExerciseDetailsInfo };
