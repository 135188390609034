import { Styles, mode } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: (props) => ({
    html: {
      // Increase the base font for larger breakpoints to scale everthing up and reduce extra whitespace
      fontSize: {
        base: '16px'
      }
    },
    body: {
      fontFamily: 'body',
      color: 'neutral.700',
      bg: mode('neutral.100', 'whiteAlpha.400')(props),
      transition: 'background-color 0.2s',
      lineHeight: 'base',
      fontVariantLigatures: 'no-common-ligatures'
    },
    'h1, h2': {
      fontFamily: 'heading',
      fontWeight: 'bold',
      color: 'neutral.750',
      lineHeight: 'heading'
    },
    '*::placeholder': {
      color: 'neutral.750'
    },
    '*, *::before, &::after': {
      borderColor: 'neutral.600',
      wordWrap: 'break-word'
    }
  })
};
