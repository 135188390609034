import React from 'react';

import PropTypes from 'prop-types';
import { getClient } from '../apollo-client';
import queries from '../queries/Insights';
import checkinQueries from '../queries/Checkin';
import reflectionsQueries from '../queries/Reflections';
import mindfulnessQueries from '../queries/Mindfulness';

const withServices = (Component) => {
  const Service = ({ services, ...props }) => {
    const client = getClient();

    const newServices = {
      // queries
      getInsightsData: async ({ start, end }) =>
        client.query({
          query: queries.GET_INSIGHTS, // query
          variables: {
            where: { start: new Date(start), end: new Date(end) }
          }, // variables
          context: { fetchPolicy: 'no-cache' } // context
        }),
      getCheckinData: async () =>
        client.query({
          query: checkinQueries.GET_CHECKINS, // query
          variables: {}, // variables
          context: { fetchPolicy: 'no-cache' } // context
        }),
      getReflectionsData: async () =>
        client.query({
          query: reflectionsQueries.GET_REFLECTIONS, // query
          variables: {}, // variables
          context: { fetchPolicy: 'no-cache' } // context
        }),
      getMindfulnessData: async () =>
        client.query({
          query: mindfulnessQueries.GET_MINDFULNESSES, // query
          variables: {}, // variables
          context: { fetchPolicy: 'no-cache' } // context
        })

      // mutations
    };

    if (services) {
      return (
        <Component services={{ ...services, ...newServices }} {...props} />
      );
    }
    return <Component {...props} services={newServices} />;
  };

  Service.propTypes = {
    services: PropTypes.any
  };

  Service.defaultProps = {
    services: undefined
  };
  return Service;
};

export default withServices;
