import React from 'react';
import PropTypes from 'prop-types';

import { LoadingContainer } from './styles';

const LoadingOverlay = ({ children }) => (
  <LoadingContainer>{children}</LoadingContainer>
);

LoadingOverlay.propTypes = {
  children: PropTypes.element
};

LoadingOverlay.defaultProps = {
  children: <></>
};

export default LoadingOverlay;
