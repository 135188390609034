import styled, { css } from 'styled-components';
import { CircularProgress as InnerCircularProgress } from '@mui/material';
import LockOutIcon from '@mui/icons-material/LockOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) =>
    theme.colors.forgotPassword.pageBackgroundColor};
`;

export const SubContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  max-width: 320px;
`;

export const LogoImage = styled.div`
  height: ${({ theme }) => theme.forgotPassword.imageHeight};
  width: ${({ theme }) => theme.forgotPassword.imageWidth};

  background-size: cover;
  background-image: url(${({ theme }) => theme.images.forgotPassword.logo});
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.colors.forgotPassword.topBackgroundColor};
  width: 100%;
  padding: ${({ theme }) => theme.padding.xxxs} 0
    ${({ theme }) => theme.padding.xxxs} 0;
  border-radius: ${({ theme }) => theme.borderRadius.s}
    ${({ theme }) => theme.borderRadius.s} 0 0;
`;

export const TitleInnerContainer = styled.div`
  margin-top: ${({ theme }) => theme.padding.xxs};
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.l};
  color: ${({ theme }) => theme.colors.forgotPassword.topTextColor};
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white[500]};
  width: 100%;
  border-radius: 0 0 ${({ theme }) => theme.borderRadius.s}
    ${({ theme }) => theme.borderRadius.s};
`;

export const InputInnerContainer = styled.div`
  margin-top: ${({ theme }) => theme.padding.xs};
  display: flex;
  flex-direction: column;

  width: 80%;
`;

export const InputSecondInputContainer = styled.div`
  margin-top: ${({ theme }) => theme.padding.xxxs};
`;

export const InputText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.s};
  color: ${({ theme }) => theme.colors.gray[500]};
`;

export const InputTextError = styled(InputText)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const FormInnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.padding.xs} 0
    ${({ theme }) => theme.padding.xs} 0;
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 80%;
  text-align: center;
`;

export const DangerRoundedIcon = styled(ErrorOutlineRoundedIcon)`
  font-size: ${({ theme }) => theme.fontSize.xxxl} !important;
  color: ${({ theme }) => theme.colors.gray[500]} !important;
`;

export const ConfirmContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  padding: ${({ theme }) => theme.padding.xxxs} 0
    ${({ theme }) => theme.padding.xxxs} 0;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 ${({ theme }) => theme.borderRadius.s}
    ${({ theme }) => theme.borderRadius.s};
`;

export const ConfirmButtonImage = styled.img.attrs({
  alt: 'Confirm button image'
})<{ disabled: boolean }>`
  height: 42px;
  width: 43px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`;

export const LockOutlinedIcon = styled(LockOutIcon)`
  color: ${({ theme }) => theme.colors.gray[1000]} !important;
  font-size: ${({ theme }) => theme.fontSize.s} !important;
`;

export const CircularProgress = styled(InnerCircularProgress)<{
  customColor?: string;
}>`
  color: ${({ theme, customColor }) =>
    customColor || theme.colors.primary} !important;
`;
