import React from 'react';
import PropTypes from 'prop-types';
import { faThumbsUp, faThumbsDown } from '@fortawesome/pro-solid-svg-icons';
import { faSpa } from '@fortawesome/pro-light-svg-icons';

import { formatDate } from '../../helpers/date';
import { RATING } from '../../constants/exercise';

import {
  Container,
  Word,
  TimeLine,
  PhraseWrapper,
  ExerciseImageContainer,
  Avatar,
  ExerciseIconContainer,
  ExerciseContainer,
  ExerciseInformationContainer,
  FontAwesomeIcon
} from './styles';

const ExerciseCard = ({ contentExercise, completedDate, rating }) => (
  <Container>
    <ExerciseImageContainer>
      {contentExercise?.backgroundImage ? (
        <Avatar
          alt="Exercise background"
          src={contentExercise?.backgroundImage}
        />
      ) : (
        <ExerciseIconContainer>
          <FontAwesomeIcon icon={faSpa} exerciseIcon />
        </ExerciseIconContainer>
      )}
    </ExerciseImageContainer>
    <ExerciseContainer>
      <Word>{contentExercise.title}</Word>
      <ExerciseInformationContainer>
        <TimeLine>{formatDate(completedDate)}</TimeLine>
        {String(rating) !== RATING.NONE && (
          <FontAwesomeIcon
            icon={String(rating) === RATING.UP ? faThumbsUp : faThumbsDown}
            rating={rating}
          />
        )}
      </ExerciseInformationContainer>
    </ExerciseContainer>
    <PhraseWrapper />
  </Container>
);

ExerciseCard.defaultProps = {
  completedDate: null,
  rating: null
};

ExerciseCard.propTypes = {
  contentExercise: PropTypes.shape({
    title: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string
  }).isRequired,
  completedDate: PropTypes.string,
  rating: PropTypes.number
};

export { ExerciseCard };
