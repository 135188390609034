/**
 * @description
 * Average from Array of V by keyGetter(V)
 * Takes an Array, a keyGetter and Optional decimal points (defaults to 1)
 * and returns the average by key.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 * @param decimals? Optional parameter to determine how many decimals should be returned
 *
 * @returns Average of KeyGetter() from array
 */
function avgByKey<V>(
  array: Array<V>,
  keyGetter: (_input: V) => number,
  decimals?: number
): number {
  const sum = array.reduce((acc, cur: V) => keyGetter(cur) + acc, 0);
  return parseFloat((sum / array.length).toFixed(decimals || 1));
}

export default avgByKey;
