export const typography = {
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700
  },
  fonts: {
    heading: 'Lato, Roboto, sans-serif',
    body: 'Lato, Roboto, sans-serif'
  },
  fontSizes: {
    xxs: '0.625rem',
    xs: '0.75rem',
    s: '0.875rem',
    m: '1rem',
    l: '1.25rem',
    xl: '1.5rem',
    xxl: '2.125rem',
    xxxl: '3.75rem'
  }
};

export type Typography = typeof typography;
