import React from 'react';
import PropTypes from 'prop-types';

import { useAfterCheckinMessage } from '../../services/mutations/checkin';

import { Message } from './style';

const AfterCheckinMessage = ({ fellingWord }) => {
  const firstWord = fellingWord
    .match(/(\w+)((,\s)|(\s)|(,))?/)[1]
    .toLowerCase();
  const { data, loading } = useAfterCheckinMessage({ fellingWord: firstWord });

  if (loading) {
    return null;
  }

  const { afterCheckinMessage } = data;

  return <Message>&quot;{afterCheckinMessage}&quot;</Message>;
};

AfterCheckinMessage.propTypes = {
  fellingWord: PropTypes.string.isRequired
};

export default AfterCheckinMessage;
