import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getFromSession } from '../../helpers/session';
import { withUser } from '../../services/hoc/user_old';
import { formatSurvey, getDaysLeft } from '../../helpers/well-being';
import {
  generateReportLink,
  useSurveyData
} from '../../services/well-being-survey';

import SurveyCard from '../../components/SurveyCard';
import LoadingSpinner from '../../components/LoadingSpinner';

import {
  MainContainer,
  NotAvailableContainer,
  NotAvailableTitle,
  CardsContainer,
  Title,
  Line
} from './style';

const Challenges = ({ user }) => {
  const { t } = useTranslation();
  const [loading, data] = useSurveyData(user.mailchimpId);

  const [loadingReport, setloadingReport] = useState(false);
  const [reportUrl, setReportUrl] = useState(
    getFromSession('CheckingIn.reportUrl')
  );

  if (!data) {
    return (
      <MainContainer>
        <NotAvailableContainer>
          <NotAvailableTitle>
            {t('messages.Survey not available')}
          </NotAvailableTitle>
          <h4>
            {t('messages.Reach out to hello@checkingin.co to get started')}
          </h4>
        </NotAvailableContainer>
      </MainContainer>
    );
  }

  const surveys = {
    baseline: formatSurvey(data.baseline?.[0]),
    exit: formatSurvey(data.exit?.[0])
  };

  const handleGenerateReportClick = async (id) => {
    setloadingReport(true);
    await generateReportLink(id, setReportUrl);
    setloadingReport(false);
  };

  if (loading || loadingReport) {
    return (
      <LoadingSpinner
        wrapper
        message={t(
          'messages.Your report is being generated a download link will be available shortly'
        )}
      />
    );
  }

  return (
    <MainContainer>
      <Title>{t('messages.Well-Being Challenge Survey Results')}</Title>
      <Line />
      <CardsContainer>
        <SurveyCard
          title={t('messages.Baseline Survey')}
          finishedAt={surveys.baseline.finishedAt}
          startedAt={surveys.baseline.startedAt}
          assessmentLink={`https://wellbeing-survey.checkingin.co/${user.mailchimpId}`}
        />
        <SurveyCard
          title={t('messages.Post Challenge Survey')}
          finishedAt={surveys.exit.finishedAt}
          startedAt={surveys.exit.startedAt}
          daysLeft={getDaysLeft(surveys.baseline.originalFinishedAt)}
          assessmentLink={`https://wellbeing-survey.checkingin.co/exit/${user.mailchimpId}`}
        />
        <SurveyCard
          title={t('messages.Final Report')}
          isDownloadAvailable={
            !!(surveys.baseline.finishedAt && surveys.exit.finishedAt)
          }
          reportUrl={reportUrl}
          generateReportLink={() => handleGenerateReportClick(user.mailchimpId)}
        />
      </CardsContainer>
    </MainContainer>
  );
};

Challenges.propTypes = {
  user: PropTypes.shape({
    mailchimpId: PropTypes.string.isRequired
  }).isRequired
};

export default withUser(Challenges);
