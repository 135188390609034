import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  height: 90%;
  margin-top: 2vh;
  text-align: center;

  > thead > tr > th {
    font-weight: 700;
    line-height: 1.25rem;
    font-size: 1.5rem;
    padding-bottom: 1vh;
    border-bottom: 2px solid #ccc;
  }

  > tbody > tr > td:last-of-type {
    font-weight: 500;
  }
`;
