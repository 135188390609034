import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';

import CardHeader from '../../../../components/CardHeader';
import CarrouselDots from '../../../../components/CarrouselDots';

import '../../../../styles/rangeSliderStyle.css';
import '../../../../styles/progressCircleStyle.css';

import {
  StepContainer,
  CardBody,
  CardTextContainer,
  CircleProgress,
  RangeSlider,
  RangeWrapper,
  NextButton,
  RangeSideText
} from './style';

const EnergyLevelStep = ({
  checkinData,
  setCheckinData,
  goNext,
  goPrevious,
  wizardStep,
  stepSize
}) => {
  const { t } = useTranslation();

  const [energyLevel, setEnergyLevel] = useState(checkinData?.energyLevel || 1);

  useEffect(() => {
    setCheckinData({ ...checkinData, energyLevel });
  }, [energyLevel]);

  return (
    <StepContainer data-testid="step-container">
      <CardHeader title={t("What's your number")} goPrevious={goPrevious} />
      <CardBody>
        <CardTextContainer>
          <CircleProgress
            styles={buildStyles({
              strokeLinecap: 'butt',
              textSize: '35px'
            })}
            strokeWidth={18}
            counterClockwise
            value={energyLevel}
            text={String(energyLevel)}
            maxValue={10}
            minValue={1}
          />
        </CardTextContainer>
        <div>{t('Energy Level')}</div>
        <RangeWrapper>
          <RangeSideText>{t('wordsClassifications.Low')}</RangeSideText>
          <RangeSlider
            min={1}
            max={10}
            step={1}
            onChange={setEnergyLevel}
            value={energyLevel}
            tooltip={false}
          />
          <RangeSideText>{t('High')}</RangeSideText>
        </RangeWrapper>
      </CardBody>
      <NextButton onClick={goNext}>{t('Next')}</NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

EnergyLevelStep.propTypes = {
  checkinData: PropTypes.shape({
    energyLevel: PropTypes.number
  }).isRequired,
  setCheckinData: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default EnergyLevelStep;
