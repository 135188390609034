import { lazy } from 'react';
import {
  faBarChart,
  faBookOpen,
  faClockRotateLeft,
  faHome,
  faPenToSquare,
  faPeopleGroup,
  faPlus
  // faShareNodes
} from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

import { NavbarItem, AvatarLinkProps } from '../types/components';
import { _404_ } from '../pages/_404_';
import { Checkin } from '../pages/Checkin';
import { History } from '../pages/History';
import Trends from '../pages/Trends';
import Insights from '../pages/Insights';
import GroupInsights from '../pages/GroupInsights';
import Challenges from '../pages/Challenges';
import { Profile } from '../pages/Profile';
import { Home } from '../pages/Home';
import { Exercise } from '../pages/Exercise';
import { Program } from '../pages/Program';
import { Mindfulness } from '../pages/Mindfulness';
import { ExerciseContent } from '../pages/Exercise/ExerciseContent';
import { ExerciseEvaluation } from '../pages/Exercise/ExerciseEvaluation';

const Baseline = lazy(() => import('../pages/wellbeing-survey/baseline'));
const Exit = lazy(() => import('../pages/wellbeing-survey/exit'));

const ADMIN_URL = process.env.REACT_APP_WEB_ADMIN_URL;
const CMS_URL = process.env.REACT_APP_WEB_CMS_URL;

export const DEFAULT_SECTION = 'Root';

export const NAV_BAR_ITEMS: NavbarItem[] = [
  {
    label: 'Home',
    href: '/',
    icon: faHome,
    section: DEFAULT_SECTION,
    isAdmin: false,
    isEnterprise: false,
    isContentCreator: false
  },
  {
    label: 'Add Check in',
    href: '/checkin',
    icon: faPlus,
    section: DEFAULT_SECTION,
    isAdmin: false,
    isEnterprise: false,
    isContentCreator: false
  },
  {
    label: 'My Insights',
    href: '/insights',
    icon: faBarChart,
    section: DEFAULT_SECTION,
    isAdmin: false,
    isEnterprise: false,
    isContentCreator: false
  },
  {
    label: 'My History',
    href: '/history',
    icon: faClockRotateLeft,
    section: DEFAULT_SECTION,
    isAdmin: false,
    isEnterprise: false,
    isContentCreator: false
  },
  // {
  //   label: 'Publishing',
  //   href: '/publishing',
  //   icon: faShareNodes,
  //   section: 'AdminPanel',
  //   isAdmin: true,
  //   isEnterprise: false
  // },
  {
    label: 'Content',
    href: '/content',
    icon: faPenToSquare,
    section: 'AdminPanel',
    isAdmin: false,
    isEnterprise: false,
    isContentCreator: true
  },
  {
    label: 'Library',
    href: '/library',
    icon: faBookOpen,
    section: 'AdminPanel',
    isAdmin: false,
    isEnterprise: false,
    isContentCreator: true,
    items: [
      {
        label: 'Program',
        href: '/program'
      }
    ]
  },
  {
    label: 'GroupManagement',
    href: '',
    icon: faPeopleGroup,
    section: 'AdminPanel',
    isAdmin: true,
    isEnterprise: true,
    isContentCreator: false,
    items: [
      {
        label: 'About',
        href: '/group_settings'
      },
      {
        label: 'Members',
        href: '/members'
      },
      {
        label: 'Subgroups',
        href: '/subgroups'
      },
      {
        label: 'Group Insights',
        href: '/group_insights'
      }
    ]
  }
];

// eslint-disable-next-line no-unused-vars
export const AVATAR_LINKS = (push: (location: any, state?: any) => void) =>
  [
    {
      icon: faUser,
      alt: 'profile_icon',
      title: 'My Profile',
      action: () => push('/profile')
    }
  ] as AvatarLinkProps[];

export const PAGES = [
  {
    menu_name: false,
    custom_header: false,
    page_component: _404_,
    custom_footer: false,
    page_key: '404'
  },
  {
    menu_name: 'Home',
    custom_header: false,
    page_component: Home,
    custom_footer: false,
    page_key: 'home',
    isRoot: true
  },
  {
    menu_name: 'Mindfulness',
    custom_header: false,
    page_component: Mindfulness,
    custom_footer: false,
    page_key: 'mindfulness'
  },
  {
    menu_name: 'Program',
    custom_header: false,
    page_component: Program,
    custom_footer: false,
    page_key: 'program/:programId'
  },
  {
    menu_name: 'Exercise',
    custom_header: false,
    page_component: Exercise,
    custom_footer: false,
    page_key: 'exercise/:exerciseId',
    exact: true
  },
  {
    menu_name: 'Exercise Evaluation',
    custom_header: false,
    page_component: ExerciseEvaluation,
    custom_footer: false,
    page_key: 'exercise/:exerciseId/evaluation',
    exact: true
  },
  {
    menu_name: 'ExerciseContent',
    custom_header: false,
    page_component: ExerciseContent,
    custom_footer: false,
    page_key: 'exercise/:exerciseId/content',
    exact: true
  },
  {
    menu_name: 'Check In',
    custom_header: false,
    page_component: Checkin,
    custom_footer: false,
    page_key: 'checkin'
  },
  {
    menu_name: 'My History',
    custom_header: false,
    page_component: History,
    custom_footer: false,
    page_key: 'history'
  },
  {
    menu_name: 'Insights',
    custom_header: false,
    page_component: Insights,
    custom_footer: false,
    page_key: 'insights'
  },
  {
    menu_name: 'Group Insights',
    custom_header: false,
    page_component: GroupInsights,
    custom_footer: false,
    page_key: 'group_insights'
  },
  {
    menu_name: 'Trends',
    custom_header: false,
    page_component: Trends,
    custom_footer: false,
    page_key: 'trends'
  },
  {
    menu_name: 'Well-Being',
    custom_header: false,
    page_component: Challenges,
    custom_footer: false,
    page_key: 'challenges'
  },
  {
    menu_name: 'Group Settings',
    custom_header: false,
    // page_component: Challenges,
    external_link: `${ADMIN_URL}/group_settings`,
    custom_footer: false,
    page_key: 'group_settings'
  },
  {
    menu_name: 'Content',
    custom_header: false,
    external_link: `${CMS_URL}/content`,
    custom_footer: false,
    page_key: 'content'
  },
  {
    menu_name: 'Program',
    custom_header: false,
    external_link: `${CMS_URL}/library/program`,
    custom_footer: false,
    page_key: 'program'
  },
  {
    menu_name: 'About',
    custom_header: false,
    // page_component: Challenges,
    external_link: `${ADMIN_URL}/subgroups`,
    custom_footer: false,
    page_key: 'subgroups'
  },
  {
    menu_name: 'Members',
    custom_header: false,
    // page_component: Challenges,
    external_link: `${ADMIN_URL}/members`,
    custom_footer: false,
    page_key: 'members'
  },
  {
    custom_header: false,
    page_component: Profile,
    custom_footer: false,
    page_key: 'profile'
  },
  {
    menu_name: 'Wellbeing - Baseline',
    custom_header: false,
    page_component: Baseline,
    custom_footer: false,
    page_key: 'wellbeing/:userId',
    exact: true
  },
  {
    menu_name: 'Wellbeing - Exit',
    custom_header: false,
    page_component: Exit,
    custom_footer: false,
    page_key: 'wellbeing/exit/:userId',
    exact: true
  }
];
