import styled from 'styled-components';

export const StyledInput = styled.textarea`
  -webkit-appearance: none;
  font-size: 1.2rem;
  outline: none;
  border: none;
  border-bottom: 2px solid #6b6b69;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.02);
  text-align: justify;
  :focus {
    border-bottom: 2px solid #3d8284;
  }
`;
