import styled from 'styled-components';

export const Chart = styled.div`
  display: inline-flex;
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span,
  h1 {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  > svg {
    position: absolute;
    width: 10vw;
    height: 10vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
  }
`;
