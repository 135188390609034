import { extendTheme } from '@chakra-ui/react';
import { base } from './base';
import components from './components';
import { styles } from './styles';

export type ThemeDirection = 'ltr' | 'rtl';
const direction: ThemeDirection = 'ltr';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false
};

const theme = extendTheme({
  direction,
  ...base,
  components,
  config,
  styles
});

export { theme };
