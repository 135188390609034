import { gql } from '@apollo/client';

const queries = {
  GET_USER_DETAILS: gql`
    query GetUserDetails {
      user {
        id
        name
        email
        mailchimpId
        createdDate
        updatedDate
        subscriptionType
        groupId
        groupName
        groupRoleId
        groupInsightsFilter
        subGroupName
        isAdmin
      }
      userSetting {
        id
        active
        type
        platform
        meta
        createdDate
      }
    }
  `,
  GET_GROUP_BY_USER_EMAIL: gql`
    query useGetGroupByUserEmail($email: String!) {
      groupByUserEmail(email: $email) {
        id
        name
        alias
        cityId
        provinceId
        countryId
        createdDate
        updatedDate
        subGroup {
          name
        }
        role {
          name
        }
      }
    }
  `,
  GET_GROUPS: gql`
    query GetGroups {
      groups {
        id
        name
        alias
        cityId
        createdDate
        updatedDate
      }
    }
  `
};

export default queries;
