import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

export const Title = styled.h4`
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
`;

export const ButtonToCheckin = styled.button`
  font-weight: 700;
  color: #297aff;
  border: 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export const MainContainer = styled.div`
  margin: 0.625rem 0.625rem 8rem 0.625rem;

  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 90vw;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 1rem;
  max-width: 1024px;
  cursor: pointer;

  gap: 1rem;
`;

export const FilterTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterTitle = styled.span`
  font-size: 1rem;
  color: hsl(120, 1%, 52%);
`;

export const FilterOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const FilterOption = styled.span`
  padding: 0.75rem;

  border: 1px solid ${colors.orange.normal};
  border-radius: 16px;

  background-color: ${({ selected }) =>
    selected ? `${colors.orange.normal}` : `${colors.white.absolute}`};
  font-size: 1rem;
  color: ${({ selected }) =>
    selected ? `${colors.white.absolute}` : `${colors.orange.normal}`};
`;
