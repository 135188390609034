import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  height: 88%;
  position: relative;
  padding: 0;
  display: grid;
  grid-template-rows: 35% 65%;
  :first-child {
    margin: 0;
    padding: 0;
  }
  :last-child {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ToggleButton = styled.img`
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -100%);
  cursor: pointer;
`;

export const Moment = styled.span`
  display: inline-block;
  position: absolute;
  top: 0%;
  left: 11%;
`;

export const MomentContainer = styled.section`
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #ccc;
  padding: 10px 0 10px;
  box-sizing: border-box;

  > div {
    position: relative;
    cursor: pointer;
  }

  > div > img {
    width: 40px;
    height: 40px;
  }
  > div > span {
    width: 40px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    text-align: center;
    color: #f2845b;
    font-weight: bold;
  }
`;

export const ContentWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const TopWords = styled.ul`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 10%;

  > li {
    width: 40%;
    list-style: circle;
    height: 15%;
  }

  ::before {
    content: 'Top words';
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%);
    font-weight: bold;
  }
`;
