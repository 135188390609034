import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faCheck,
  faThumbsUp,
  faThumbsDown
} from '@fortawesome/pro-regular-svg-icons';

import { usePrompt } from '../../../hooks/events';
import { useContent } from '../../../contexts/ContentContext';
import contentHistoryGraph from '../../../services/graphql-queries/contentHistory';
import { useUserData } from '../../../contexts/UserDataContext';
import DefaultImage from '../../../assets/img/DefaultImage.png';
import { handleNavigationOnComplete } from '../../../helpers/exercise';
import { FailContainer, FailText } from '../styles';
import {
  EXERCISE_COMMENT_MAX_LENGTH,
  RATING
} from '../../../constants/exercise';

import {
  Container,
  TopContainer,
  BottomContainer,
  ExercisePreviewContainer,
  ExerciseTitle,
  FontAwesomeIcon,
  Avatar,
  RatingContainer,
  RatingInnerContainer,
  ThumbsIcon,
  BottomTitle,
  TextField,
  Button
} from './styles';

const ExerciseEvaluation = () => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const { push, action, go } = useHistory();
  const { exercise, program, redirectedFrom } = state;

  const { isPlus } = useUserData();
  const { addContentHistories } = useContent();

  const [rating, setRating] = useState(RATING.NONE);
  const [failedLoadingImg, setFailedLoadingImg] = useState(false);
  const [exerciseComment, setExerciseComment] = useState('');
  const [userCanLeave, setUserCanLeave] = useState(false);

  const Prompt = usePrompt({
    when: !userCanLeave,
    message: t('messages.Are you sure you want to leave this exercise')
  });

  const [saveContentHistory] = useMutation(
    contentHistoryGraph.SAVE_CONTENT_HISTORY,
    {
      onCompleted: async (data) => {
        await addContentHistories({
          exerciseContentHistories: [data.saveContentHistory],
          reloadFlags: true
        });
      }
    }
  );

  const handleOnSubmit = async () => {
    const contentHistoryPayload = {
      cmsId: exercise.id,
      publicationRecordId: exercise.publicationId,
      rating,
      comment: exerciseComment || '',
      responseData:
        exercise?.responseData?.map(({ __typename, ...item }) => item) || null
    };

    await saveContentHistory({
      variables: {
        params: contentHistoryPayload
      }
    });

    setUserCanLeave(true);
    handleNavigationOnComplete({
      historyPush: push,
      program,
      exercise,
      redirectedFrom
    });
  };

  useEffect(
    () => () => {
      // Prevent user from going to previous screen with browser back button
      if (action === 'POP') go(1);
    },
    [action, go]
  );

  if (!exercise) {
    return (
      <FailContainer>
        <FailText>{t('Fail to load this exercise')}</FailText>
      </FailContainer>
    );
  }

  const imageSource = failedLoadingImg
    ? DefaultImage
    : exercise?.backgroundImage;

  return (
    <Container>
      <TopContainer>
        <ExercisePreviewContainer datatype="exercise-preview-container">
          <Avatar src={imageSource} onError={() => setFailedLoadingImg(true)} />
          <ExerciseTitle>{exercise?.title}</ExerciseTitle>
          <FontAwesomeIcon icon={faCheck} />
        </ExercisePreviewContainer>
        <RatingContainer>
          {t('Did you enjoy this exercise')}

          <RatingInnerContainer>
            <ThumbsIcon
              icon={faThumbsUp}
              onClick={() => setRating(RATING.UP)}
              marked={rating === RATING.UP}
            />
            <ThumbsIcon
              icon={faThumbsDown}
              onClick={() => setRating(RATING.DOWN)}
              marked={rating === RATING.DOWN}
            />
          </RatingInnerContainer>
        </RatingContainer>
      </TopContainer>
      <BottomContainer>
        <BottomTitle>{t('Self Reflection')}</BottomTitle>
        <TextField
          id="comment"
          label={t('My confidential journal entry')}
          variant="standard"
          defaultValue={exerciseComment || ''}
          onChange={(event) => setExerciseComment(event.target.value)}
          inputProps={{
            maxLength: isPlus ? undefined : EXERCISE_COMMENT_MAX_LENGTH
          }}
          helperText={`${exerciseComment?.length || 0}/${
            isPlus ? t('Unlimited') : EXERCISE_COMMENT_MAX_LENGTH
          }`}
        />
        {/* {isPlus && <TryPlusText>{t('Try Plus to remove limit')}</TryPlusText>} */}
        <Button label={t('Complete')} onClick={() => handleOnSubmit()} />
      </BottomContainer>
      <Prompt />
    </Container>
  );
};

export { ExerciseEvaluation };
