import { gql } from '@apollo/client';

export const REFLECTION_TYPE = 'Reflection';

const graph = {
  GET_REFLECTIONS: gql`
    query GetReflections {
      reflections {
        id
        cmsId
        unlockDate
        viewedDate
        completedDate
        comment
      }
    }
  `
  // VIEWED_REFLECTION: gql`
  // 	mutation ViewedReflection($id: ID!) {
  // 		viewedReflection(id: $id) {
  // 			id
  // 			viewedDate
  // 		}
  // 	}
  // `,
  // SAVE_REFLECTION: gql`
  // 	mutation SaveReflection($params: SaveReflectionRequest, $timezone: String) {
  // 		saveReflection(params: $params, timezone: $timezone) {
  // 			id
  // 			completedDate
  // 			comment
  // 		}
  // 	}
  // `,
  // GET_UNLOCKED_REFLECTION_ID: gql`
  // 	{
  // 		unlockedReflectionId @client
  // 	}
  // `,
};

export default graph;
