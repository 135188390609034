import React from 'react';
import { Center, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { CustomIconProps } from '../../../types/components';

const CustomIcon = ({
  icon,
  color = 'yellow.500',
  size = 70,
  text,
  isSelected = false,
  textProps,
  ...centerProps
}: CustomIconProps) => (
  <Center {...centerProps}>
    <FontAwesomeIcon icon={icon as IconProp} fontSize={size} color={color} />
    {text && (
      <Text
        fontSize="md"
        fontWeight={isSelected ? 'bold' : 'normal'}
        whiteSpace="pre-line"
        {...textProps}
      >
        {text}
      </Text>
    )}
  </Center>
);

export { CustomIcon };
