import { mergeAllSubGroups } from './helpers';
import {
  SubGroup,
  SubGroupLegacy,
  DaysFilter
} from '../../services/insights/types';
import {
  getWordCloud,
  getWordsCategorized
} from '../../services/insights/words';
import { getAvgDailyEnergyData } from '../../services/insights/calcullations';
import { getTimeOfCheckIns } from '../../services/insights/time';

// TODO: Fix this API, it does not make sense to have an Array or not as the data being called.
const getSummaryData = (data: SubGroupLegacy | SubGroupLegacy[]) => {
  const summarizeData = (summary: SubGroupLegacy) => {
    // The Insights API returns it as checkIns (capital I) and mindfulnesses (plural)
    // TODO: We should normalize the API return or the UI to have a single name
    const checkins =
      summary?.checkIns?.length || summary?.checkins?.length || 0;
    const reflections = summary?.reflections?.length || 0;
    const mindfulnesses =
      summary?.mindfulnesses?.length || summary?.mindfulness?.length || 0;
    return {
      name: summary?.name,
      checkins,
      reflections,
      mindfulnesses,
      actionsCompleted: checkins + reflections + mindfulnesses
    };
  };

  if (Array.isArray(data)) {
    return data
      .filter(
        (subgroup) =>
          (data.length > 1 && subgroup.name !== 'Not Assigned') ||
          (data.length > 1 &&
            subgroup.name === 'Not Assigned' &&
            (subgroup?.checkIns?.length ?? 0) > 0) ||
          data.length === 1
      )
      .map((subgroup) => summarizeData(subgroup));
  }

  // FIXME: I am almost sure this never runs, for further investigation
  return summarizeData(data);
};

const getMonthGraphs = (
  data: SubGroup,
  filter: DaysFilter,
  isGroup: boolean
) => {
  const summary = getSummaryData(data);
  if (isGroup) {
    const mergedData = mergeAllSubGroups(data);
    return {
      summary,
      avgDailyEnergy: getAvgDailyEnergyData(
        mergedData.checkins || [],
        filter.days
      ),
      timeOfCheckIns: getTimeOfCheckIns(mergedData.checkins || []),
      wordCloud: getWordCloud(mergedData.checkins || []),
      wordsCategorized: getWordsCategorized(mergedData.checkins || [])
    };
  }
  const timeOfCheckIns = getTimeOfCheckIns(data.checkins || []);
  console.log('timeOfCheckIns', timeOfCheckIns);
  return {
    summary,
    avgDailyEnergy: getAvgDailyEnergyData(data.checkins || [], filter.days),
    timeOfCheckIns,
    wordCloud: getWordCloud(data.checkins || []),
    wordsCategorized: getWordsCategorized(data.checkins || [])
  };
};

export {
  getMonthGraphs,
  getSummaryData,
  getAvgDailyEnergyData,
  getTimeOfCheckIns
};
