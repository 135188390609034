import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { Button } from '@checkingin/component-library';

import { Controls, Label, PdfWrapper } from './style';

const Embed = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(numPages) {
    setTotalPages(numPages);
  }

  return (
    <PdfWrapper>
      <Controls>
        <Button
          label="<"
          onClick={() => pageNumber - 1 && setPageNumber(pageNumber - 1)}
        />
        <Label>
          {t('Page')} {pageNumber} {t('of')} {totalPages}
        </Label>
        <Button
          label=">"
          onClick={() =>
            pageNumber + 1 <= totalPages && setPageNumber(pageNumber + 1)
          }
        />
      </Controls>

      <Document
        file={{ url: searchParams.get('url') }}
        onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
      >
        <Page pageNumber={pageNumber} />
      </Document>
    </PdfWrapper>
  );
};

export default Embed;
