import styled, { keyframes } from 'styled-components';

const colors = {
  baseBlue: '#3c7778',
  titleBlue: '#204040',
  disabled: '#ccc',
  grey: '#979797'
};

const AnimationType = keyframes`
  from{
      width: 0;
  }
  to {
      width: 15ch;
  }
`;

export const Loading = styled.section`
  width: 100%;
  height: 80%;
  position: relative;

  & span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 40%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    font-size: 46px;
    line-height: 52px;
    animation: ${AnimationType} 3s forwards infinite;
  }
`;

export const PageWrapper = styled.section`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0 1vw 5vw;
  display: grid;
  grid-template-rows: 5vh repeat(3, 30vh);
  gap: 3vh;
`;

export const Tabs = styled.section`
  width: 80%;
  display: grid;
  gap: 2px;
  background-color: ${colors.baseBlue};
  margin: 0 auto 0 auto;
  height: 5vh;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 16px;
  overflow: hidden;
  padding: 0 1px 0 1px;
  justify-content: center;
  align-items: center;
`;

export const Tab = styled.button`
  display: inline-block;
  width: 100%;
  height: 4.8vh;
  font-size: 1.5rem;
  line-height: 5vh;
  font-weight: ${({ active }) => (active ? '700' : '300')};
  color: ${({ active }) => (active ? '#fff' : colors.baseBlue)};
  cursor: pointer;
  background: ${({ active }) => (active ? 'transparent' : '#f2f2f2')};
  border: 0;

  &:first-of-type {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  &:last-of-type {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

export const ChartRow = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;

  > section {
    margin-right: 3%;
  }
`;
