import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MoodDictionary from '../../../services/apollo-client/statics/felling-words.json';
import MoodSummaryIcon from '../../../assets/svg/Trends/pie-chart.svg';

import {
  ChartItem,
  ChartWrapper,
  SelectList,
  Item,
  Title,
  colors
} from './style';

import ChartCard from '../../ChartCard';

// const objectDeepKeys = (obj) => {
//   return Object.keys(obj).filter(key => typeof obj[key] === 'object' && !Array.isArray(obj[key])).map(key => objectDeepKeys(obj[key])).reduce((x, y) => x.concat(y), Object.keys(obj))
// }

// const objectValues = (obj) => {
//   return Object.values(obj).map(element => !Array.isArray(element) ? objectValues(element) : element).flat()
// }

const getDictionaryData = (dict) =>
  [...dict.map(({ title, content }) => [title, ...content])].flat();

const MoodSummary = ({ checkIns }) => {
  const { t } = useTranslation();

  const [words] = useState(
    checkIns
      ? checkIns.map(({ feelingWord }) => feelingWord.split(',')).flat()
      : []
  );
  const [stats, setStats] = useState(null);
  const [selected, setSelected] = useState('Happy');
  const [showChart, setShowChart] = useState(true);

  useEffect(() => {
    if (words) {
      const categorization = {};
      const dictionaryWords = getDictionaryData(MoodDictionary);
      const containedWordsTotal = words
        .map((word) =>
          dictionaryWords
            .map((e) => e.toLowerCase())
            .includes(word.toLowerCase())
            ? 1
            : 0
        )
        .reduce((acc, curr) => acc + curr, 0);
      const getWordsContained = (defaultWords) =>
        words.filter((word) =>
          defaultWords.map((e) => e.toLowerCase()).includes(word.toLowerCase())
        );
      // console.log({ dictionaryWords, containedWordsTotal });
      let defaultWords = [];
      let contained = [];

      for (const { title, content } of MoodDictionary) {
        defaultWords = [title, ...content];
        contained = getWordsContained(defaultWords);
        categorization[title] = {
          defaultWords: [...defaultWords],
          contained: [...contained].filter(
            (word, index, arr) => index === arr.indexOf(word)
          ),
          percentage:
            contained.length > 0
              ? (contained.length * 100) / containedWordsTotal
              : 0
        };
        defaultWords.length = 0;
        contained.length = 0;
      }
      // console.log({ categorization });
      setStats(categorization);
    }
  }, [words]);

  const handleSelect = (part) => {
    setSelected(part);
  };

  if (!stats) {
    return (
      <ChartCard title={t('Mood Summary')} titleIcon={MoodSummaryIcon}>
        <p>{t('Loading')}...</p>
      </ChartCard>
    );
  }

  const getStroke = (word) =>
    `${stats[word]?.percentage} ${100 - stats[word]?.percentage}`;

  const getRotation = (word) => {
    const keys = Object.keys(stats);
    const index = keys.indexOf(word);
    if (index === 0) {
      return `0deg`;
    }
    if (index === keys.length - 1) {
      return `${((100 - stats[word]?.percentage) * 360) / 100}deg`;
    }
    const accumulated = keys
      .slice(0, keys.indexOf(word))
      .reduce((acc, curr) => acc + stats[curr].percentage, 0);
    return `${(accumulated * 360) / 100}deg`;
  };

  const showWords = () => setShowChart((previousState) => !previousState);

  return (
    <ChartCard title={t('Mood Summary')} titleIcon={MoodSummaryIcon}>
      <ChartWrapper>
        <SelectList>
          {Object.keys(stats).map((word) => (
            <Item>
              <Title
                Color={colors[word]}
                active={selected === word}
                onClick={() => handleSelect(word)}
              >
                {t(`wordsClassifications.${word}`, word)}
              </Title>
            </Item>
          ))}
        </SelectList>

        {showChart ? (
          <ChartItem
            width="100%"
            height="100%"
            viewBox="0 0 40 40"
            // percentage={energy[selected]?.percentage || 0}
          >
            <circle cx="20" cy="20" r="15.91549430918954" fill="transparent" />
            <circle
              cx="20"
              cy="20"
              r="15.91549430918954"
              fill="transparent"
              strokeWidth="3.5"
            />
            {Object.keys(stats).map(
              (word) =>
                stats[word]?.percentage > 0 && (
                  <circle
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    strokeWidth={word === selected ? 4.5 : 3.5}
                    strokeDasharray={getStroke(word)}
                    strokeDashoffset="25"
                    stroke={colors[word]}
                    style={{
                      transformOrigin: 'center',
                      transform: `rotate(${getRotation(word)})`
                    }}
                  />
                )
            )}
            <g>
              <text y="50%">
                <tspan x="50%">{selected}</tspan>
              </text>
              <text y="60%">
                <tspan x="50%">
                  {stats[selected]?.percentage?.toFixed(0) || 0}%
                </tspan>
              </text>
            </g>
          </ChartItem>
        ) : (
          <ul>
            {stats[selected].contained.map((word, index) => (
              <li key={`${word}-${index}-${Math.random()}`}>{word}</li>
            ))}
          </ul>
        )}
        <button type="button" onClick={showWords}>
          {t('View Words')} ({stats[selected].contained?.length})
        </button>
      </ChartWrapper>
    </ChartCard>
  );
};

MoodSummary.propTypes = {
  checkIns: PropTypes.arrayOf(
    PropTypes.shape({
      createdDate: PropTypes.string.isRequired,
      energyLevel: PropTypes.number.isRequired,
      feelingContext: PropTypes.string.isRequired,
      feelingWord: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      weather: PropTypes.shape({
        weatherIcon: PropTypes.string.isRequired,
        weatherId: PropTypes.string.isRequired,
        weatherMain: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

export default MoodSummary;
