import styled from 'styled-components';
import { ErrorProps } from '../../types';

export const Container = styled.div<ErrorProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 40px;
  border: ${({ theme }) => theme.border.hairLine} solid
    ${({ error }) => (error ? 'red' : '#f1f1f1')};
  background-color: #f1f1f1;
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

export const IconContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: #f1f1f1;
  padding: 0 ${({ theme }) => theme.padding.xxxs} 0
    ${({ theme }) => theme.padding.xxxs};
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.s} 0px 0px
    ${({ theme }) => theme.borderRadius.s};
  max-width: 25px;
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
`;
