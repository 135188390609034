import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Container,
  CorrectAnswersContainer,
  CorrectAnswersText,
  DescriptionContainer,
  DescriptionText,
  ExpectedAnswerText,
  FeedbackContainer,
  FeedbackText,
  TitleContainer,
  TitleText
} from './styles';
import { AlternativeItem } from './AlternativeItem';

const ExerciseAlternative = ({
  content,
  handleCurrentResponse,
  showAnswer,
  sectionAnswered
}) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  const expectedAnswers = useMemo(
    () => content?.options.filter((item) => item.isCorrect === true),
    [content]
  );

  const selectedOptions = useMemo(
    () => options.filter((item) => item.checked),
    [options]
  );

  useEffect(() => {
    setOptions(
      content.options.map((item, index) => {
        if (
          sectionAnswered?.answerAlternative &&
          sectionAnswered.answerAlternative.includes(item.text)
        ) {
          return {
            id: index,
            label: item.text,
            isExpected: item.isCorrect,
            checked: true
          };
        }

        return {
          id: index,
          label: item.text,
          isExpected: item.isCorrect,
          checked: false
        };
      })
    );

    if (sectionAnswered?.answerAlternative) {
      handleCurrentResponse({
        response: sectionAnswered.answerAlternative,
        showConfirm: true,
        disableSubmit: content.isRequired || false
      });
      return;
    }

    handleCurrentResponse({
      response: [],
      showConfirm: true,
      disableSubmit: content.isRequired || false
    });
  }, [content, sectionAnswered]);

  useEffect(() => {
    if (
      selectedOptions.length >= expectedAnswers.length &&
      selectedOptions.length > 0 &&
      content.isRequired
    ) {
      handleCurrentResponse({
        response: selectedOptions.map((item) => item.label)
      });
    } else {
      handleCurrentResponse({
        response: selectedOptions.map((item) => item.label),
        disableSubmit: content.isRequired || false
      });
    }
  }, [options]);

  return (
    <Container>
      <TitleContainer>
        <TitleText>{content.title ?? ''}</TitleText>
      </TitleContainer>
      <DescriptionContainer>
        <DescriptionText>{content.question ?? ''}</DescriptionText>
      </DescriptionContainer>
      {options.map((item, index) => (
        <AlternativeItem
          key={`AlternativeItem-${index}`}
          label={item.label}
          index={item.id}
          isExpected={item.isExpected}
          hasNoExpected={!!expectedAnswers.length}
          showAnswer={showAnswer}
          disabled={
            (!item.checked &&
              expectedAnswers.length &&
              selectedOptions.length >= expectedAnswers.length) ||
            showAnswer
          }
          checked={item?.checked}
          saveAnswer={(newAnswer) => {
            setOptions(
              options.map((currentOptions) =>
                currentOptions.id === newAnswer.id ? newAnswer : currentOptions
              )
            );
          }}
        />
      ))}
      {showAnswer && (
        <>
          {expectedAnswers.length > 0 && (
            <CorrectAnswersContainer>
              <ExpectedAnswerText>
                {t('Expected answer(s)')}:{' '}
              </ExpectedAnswerText>
              <CorrectAnswersText>
                {expectedAnswers.map((item) => item.text).join(', ')}
              </CorrectAnswersText>
            </CorrectAnswersContainer>
          )}
          {content.feedback && (
            <FeedbackContainer>
              <FeedbackText>{content.feedback}</FeedbackText>
            </FeedbackContainer>
          )}
        </>
      )}
    </Container>
  );
};

ExerciseAlternative.defaultProps = {
  sectionAnswered: null
};

ExerciseAlternative.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    question: PropTypes.string.isRequired,
    feedback: PropTypes.string,
    isRequired: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        isCorrect: PropTypes.bool.isRequired
      })
    )
  }).isRequired,
  handleCurrentResponse: PropTypes.func.isRequired,
  showAnswer: PropTypes.bool.isRequired,
  sectionAnswered: PropTypes.shape({
    sectionId: PropTypes.string.isRequired,
    answerAlternative: PropTypes.arrayOf(PropTypes.string)
  })
};

export { ExerciseAlternative };
