import React from 'react';
import PropTypes from 'prop-types';

import { STATUS } from '../../constants/exercise';
import { BadgeContainer, BadgeText, Container } from './styles';

const ContentLabel = ({
  status,
  count,
  shouldHideNewsWithoutCount,
  position = 'over-top'
}) => {
  const type = status === 'new' || status === 'completed' ? status : 'other';

  if (shouldHideNewsWithoutCount && status === 'new' && !count) return null;

  return (
    <Container position={position} status={type}>
      {STATUS[status]}
      {count && count >= 1 && (
        <BadgeContainer>
          <BadgeText>{count}</BadgeText>
        </BadgeContainer>
      )}
    </Container>
  );
};

ContentLabel.defaultProps = {
  count: null,
  shouldHideNewsWithoutCount: false
};

ContentLabel.propTypes = {
  status: PropTypes.oneOf(['new', 'completed', 'upcoming', 'inprogress'])
    .isRequired,
  count: PropTypes.number,
  shouldHideNewsWithoutCount: PropTypes.bool,
  position: PropTypes.oneOf(['under-top', 'over-top']).isRequired
};

export default ContentLabel;
