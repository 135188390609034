import { gql } from '@apollo/client';

const graph = {
  GET_WORDSLIST: gql`
    query GetWordsList {
      fellingWords @client {
        title
        content
      }
    }
  `
};

export default graph;
