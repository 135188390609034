import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isFileUnsupported } from '../../../../../helpers/file';

import { Container, Text, IFrame } from './styles';

const ExerciseFile = ({ fileUrl }) => {
  const { t } = useTranslation();

  const isUnsupported = isFileUnsupported(fileUrl);

  return (
    <>
      {isUnsupported ? (
        <Container>
          <Text>{t(`messages.Previewing this file is not available`)}</Text>
        </Container>
      ) : (
        <IFrame src={fileUrl} />
      )}
    </>
  );
};

ExerciseFile.propTypes = {
  fileUrl: PropTypes.string.isRequired
};

export { ExerciseFile };
