const ONE_DAY = 86400000;
const DAYS_BETWEEN_CHALLENGE = 30;

const getDateToSurvey = (date) => {
  if (!date) {
    return '';
  }

  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const convertFromUTC = (date) =>
  date.includes('Z')
    ? new Date(date)
    : new Date(date.includes('GMT') ? date : `${date} GMT`);

export const formatSurvey = (survey = {}) => {
  const { finishedAt, startedAt } = survey;

  return {
    originalFinishedAt: finishedAt,
    finishedAt: getDateToSurvey(finishedAt),
    startedAt: getDateToSurvey(startedAt)
  };
};

export const getDaysLeft = (baselineFinishedAt) => {
  if (!baselineFinishedAt) {
    return -1;
  }
  const after30Days = convertFromUTC(baselineFinishedAt);
  after30Days.setDate(after30Days.getDate() + DAYS_BETWEEN_CHALLENGE);

  const today = new Date();
  const daysLeft = Math.round((after30Days - today) / ONE_DAY);

  return daysLeft > 0 ? daysLeft : 0;
};

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};
