import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, CarrouselDot } from './style';

const CarrouselDots = ({ currentStep, stepsSize }) => (
  <Wrapper>
    {new Array(stepsSize).fill(0).map((e, i) => (
      <CarrouselDot key={`carrousel-dot-${i}`} isActive={currentStep === i} />
    ))}
  </Wrapper>
);

CarrouselDots.propTypes = {
  currentStep: PropTypes.number.isRequired,
  stepsSize: PropTypes.number.isRequired
};

export default CarrouselDots;
