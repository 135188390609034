const ENTERPRISE_ID = 1168780;

export const hideGroupInsights = (insightsFilter, isAdmin) =>
  (insightsFilter === 'AdminsOnly' && !isAdmin) ||
  insightsFilter === 'NotVisible';

export const formatUser = (user) => {
  let newUser = {};
  if (user) {
    newUser = {
      challengeStartDate: user['https://web.checkingin.co/challengeStartDate'],
      geoip: user['https://web.checkingin.co/geoip'],
      mailchimpId: user['https://web.checkingin.co/mailchimpId'],
      roles: user['https://web.checkingin.co/roles'],
      tags: user['https://web.checkingin.co/tags'],
      name: user.name,
      nickname: user.nickname,
      picture: user.picture,
      sub: user.sub,
      updatedAt: user.updated_at,
      email: user.email,
      isVerifiedEmail: user.email_verified,
      company: user.company,
      publications: user.publications
    };
  }

  return newUser;
};

export const isEnterprise = (user = {}) => {
  const { tags = [] } = user;
  return tags.some((tag) => tag.id === ENTERPRISE_ID);
};

export const SUBSCRIPTION_TYPES = {
  Basic: 'Free',
  Plus: 'Plus',
  Legacy: 'Legacy',
  Enterprise: 'Enterprise',
  Trial: 'Plus'
};

export const UserSettingsTypes = {
  NOTIFICATIONS: 'Notifications',
  PREFERENCES: 'Preferences'
};
