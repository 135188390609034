import { CheckIn } from '../../services/insights/types';
import { avgByKey, groupBy } from '../../helpers/generics/';

/**
 * @deprecated The method should not be used in favor of avgByKey in generic helpers
 */
export const calcAvgFromArray = (array: any[], key: string) => {
  const sum = array.reduce((acc, cur) => cur[key] + acc, 0);

  return parseFloat((sum / array.length).toFixed(1));
};

export const getAvgDailyEnergyData = (
  checkins: CheckIn[],
  monthDays: number
) => {
  // We first group from all passed CheckIns by the day, that way we have a map of {day: Checkins[]}
  const dayCheckins = groupBy(checkins, (d) => d.createdDate.substring(8, 10));

  const energyLevelList = Array.from(dayCheckins, ([day, checkin]) => ({
    day,
    avgEnergyLevel: avgByKey(checkin, (c) => c.energyLevel)
  }));

  const totalAverage = avgByKey(energyLevelList, (c) => c.avgEnergyLevel);

  const avgList = [...new Array(monthDays)].map((_, i) => {
    const result = energyLevelList.find(
      (item: { day: string }) => parseInt(item.day, 10) === i + 1
    );

    return { day: i + 1, Energy: result?.avgEnergyLevel || 0 };
  });

  return {
    average: totalAverage,
    checkins: avgList
  };
};
