import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
`;
export const CarrouselDot = styled.div`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #3c7778;
  box-sizing: content-box;
  flex: 0 1 auto;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: opacity 0.6s ease;
  opacity: 0.5;
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
    `}
`;
