import React, { memo } from 'react';
import ReactWordcloud from 'react-wordcloud';

import PropTypes from 'prop-types';

import emotionList from '../../../services/static/categories.json';
import categoryColours from '../../../services/static/color-categories.json';

function getCategoryByEmotion(emotion) {
  return Object.keys(emotionList).find((category) =>
    emotionList[category].includes(emotion)
  );
}
// select word colour
const callbacks = {
  getWordColor: ({ text }) =>
    categoryColours[getCategoryByEmotion(text.toLowerCase())]
};

const WordCloud = ({ data }) => (
  <div style={{ width: '100%', height: '100%' }}>
    <ReactWordcloud
      callbacks={callbacks}
      words={data}
      // size={[(window.innerWidth / 2) - 200, 400]}
      options={{
        fontStyle: 'normal',
        fontWeight: 'normal',
        colors: ['#51A3A4', '#397677', '#418384'],
        fontSizes: [16, 50],
        padding: 1,
        scale: 'log',
        spiral: 'rectangular',
        transitionDuration: 150
      }}
    />
  </div>
);

WordCloud.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default memo(WordCloud);
