import { useMutation, useQuery } from '@apollo/client';
import queries from '../../graphql-queries/checkin';
import { getWeatherFromAPI } from '../../weather';

export function useAddCheckin() {
  return useMutation(queries.ADD_CHECKIN);
}

export function useDeleteCheckin() {
  return useMutation(queries.DELETE_CHECKIN);
}

export function useUpdateCheckin() {
  return useMutation(queries.UPDATE_CHECKIN);
}

export function useCreateWheater() {
  return useMutation(queries.CREATE_WEATHER);
}

export function useCheckin() {
  const { loading, error, data } = useQuery(queries.GET_CHECKINS, {
    fetchPolicy: 'cache-and-network'
  });
  return { loading, data, error };
}

export function useCheckinPagination(options) {
  const { loading, error, data, fetchMore, refetch } = useQuery(
    queries.GET_CHECKINS_PAGINATION,
    options
  );
  return { loading, data, error, fetchMore, refetch };
}

/**
 * @mutation: Can be whatever graphQL function CheckIn can handle, most cases it will be Add or Update
 */

export async function SaveCheckin(
  mutation,
  createWeather,
  { checkin, latitude, longitude, timezone }
) {
  let weatherId = null;

  if (!('weather' in checkin)) {
    const weather = await getWeatherFromAPI(latitude, longitude);
    if (weather) {
      const newWeather = {
        variables: {
          request: weather
        }
      };

      const resultWeather = await createWeather(newWeather);
      weatherId = resultWeather.data.addWeatherInfo.id;
    }
  }

  const newCheckin = {
    variables: {
      request: checkin,
      timezone,
      weather: weatherId
    }
  };

  const result = await mutation(newCheckin);

  if ('data' in result) {
    const data = result.data.updateCheckIn
      ? result.data.updateCheckIn
      : result.data.addCheckIn;
    return {
      data,
      mutation: result.data.updateCheckIn ? 'update' : 'add'
    };
  }

  return {
    error: result
  };
}

export function useAfterCheckinMessage(variables) {
  const { loading, error, data } = useQuery(queries.AFTER_CHECKIN_MESSAGE, {
    fetchPolicy: 'network-only',
    variables: {
      fellingWord: variables.fellingWord
    }
  });
  return { loading, data, error };
}
