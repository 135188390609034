import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';

import './styles/root.css';
import './styles/animations.css';
import './styles/storybookDefaults.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
