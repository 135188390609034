import React from 'react';

import PropTypes from 'prop-types';

import { Notification } from '@checkingin/component-library';
import { Page, Container, NotificationLine } from './style';

function PageContainer({ notifications = [], children }) {
  return (
    <Page>
      <Container>
        <NotificationLine>
          {Array.isArray(notifications) ? (
            notifications.map(({ flavour, content }) => (
              <Notification flavor={flavour}>
                <span>{content}</span>
              </Notification>
            ))
          ) : (
            <Notification flavor={notifications.flavour}>
              <span>{notifications.message}</span>
            </Notification>
          )}
        </NotificationLine>

        {children}
      </Container>
    </Page>
  );
}

PageContainer.defaultProps = {
  children: null,
  notifications: []
};

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  notifications: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        flavour: PropTypes.string.isRequired
      })
    ),
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      flavour: PropTypes.string.isRequired
    })
  ])
};

export default PageContainer;
