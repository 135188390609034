import styled, { css } from 'styled-components';

export const Overlay = styled.div`
  ${({ isVisible }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1021;
    background-color: rgba(0, 0, 0, 0);

    ${isVisible &&
    css`
      transition: all 0.3s ease-out;
      background-color: rgba(0, 0, 0, 0.3);
      visibility: visible;
    `}
  `}
`;

export const Container = styled.section`
  ${({ isVisible }) => css`
    padding: 2rem;

    width: 60vw;
    max-width: 700px;

    max-height: 80vh;

    overflow: auto;
    background-color: #fff;
    opacity: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transform: translate3d(0, -200px, 0);
    transition: all 0.3s ease-out;

    ${isVisible &&
    css`
      opacity: 1;
      transform: translate3d(0, 0, 0);
    `}
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 2rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);

  :hover {
    color: rgba(0, 0, 0, 0.9);
  }
`;
