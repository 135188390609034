import { useState, useEffect } from 'react';
import axios from 'axios';

import { saveOnSession, getFromSession } from '../../helpers/session';

const WBSAPI = process.env.REACT_APP_API_WBS_SURVEY;

export const useSurveyData = (userId) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    const getReportData = async () => {
      let apiResult = {};
      try {
        apiResult = await axios.get(`${WBSAPI}report-integration/${userId}`);
      } catch (error) {
        console.error('Error on getting survey data', error);
      }
      setData(apiResult?.data || {});
      setLoading(false);
    };
    getReportData();
  }, [userId]);

  return [loading, data];
};

export const generateReportLink = async (userId, setReportUrl) => {
  const reportRequest = {
    uid: userId,
    tk: getFromSession('CheckingIn.token')
  };

  const apiResult = await axios.post(
    `${WBSAPI}report-generator`,
    reportRequest
  );

  saveOnSession('CheckingIn.reportUrl', apiResult.data.file);
  setReportUrl(apiResult.data.file);
};
