import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ChartCard from '../../ChartCard';

import {
  ChartItem,
  ChartWrapper,
  SelectList,
  Item,
  Title,
  Energy
} from './style';

import WhenDoYouChackInIcon from '../../../assets/svg/Trends/clock.svg';

const WhenDoYouCheckIn = ({ checkIns }) => {
  const { t } = useTranslation();

  const colors = {
    Morning: '#f2845b',
    'Mid-Day': '#007e6c',
    Evening: '#6d757b'
  };

  const classifyCheckin = (checkin) => {
    const hour = new Date(checkin.createdDate).getHours();
    if (hour >= 5 && hour <= 11) {
      return { moment: 'Morning', energy: checkin.energyLevel, checkin };
    }
    if (hour > 11 && hour <= 16) {
      return { moment: 'Mid-Day', energy: checkin.energyLevel, checkin };
    }
    return { moment: 'Evening', energy: checkin.energyLevel, checkin };
  };

  const [selected, setSelected] = useState('Evening');
  const [classified] = useState(checkIns?.map(classifyCheckin) || []);
  const [energy, setEnergy] = useState({
    Morning: {
      average: 0,
      percentage: 0
    },
    'Mid-Day': {
      average: 0,
      percentage: 0
    },
    Evening: {
      average: 0,
      percentage: 0
    }
  });

  useEffect(() => {
    if (classified) {
      const consolidate = (whichMoment) => {
        const itens = classified.filter(({ moment }) => moment === whichMoment);
        if (itens.length === 0)
          return {
            average: 0,
            percentage: 0
          };
        return {
          average: Number(
            parseFloat(
              itens.reduce((acc, val) => acc + val.energy, 0) / itens.length
            ).toFixed(1)
          ),
          percentage: Number(
            parseFloat((itens.length * 100) / classified.length).toFixed(0)
          )
        };
      };

      setEnergy({
        Morning: consolidate('Morning'),
        'Mid-Day': consolidate('Mid-Day'),
        Evening: consolidate('Evening')
      });
    }
  }, [classified]);

  const handleSelect = (part) => {
    setSelected(part);
  };

  const getStroke = (moment) =>
    `${energy[moment]?.percentage} ${100 - energy[moment]?.percentage}`;

  const getRotation = (moment) => {
    if (moment === 'Morning') {
      return `0deg`;
    }
    if (moment === 'Mid-Day') {
      return `${(energy.Morning?.percentage * 360) / 100}deg`;
    }
    return `${((100 - energy.Evening?.percentage) * 360) / 100}deg`;
  };

  return (
    <ChartCard
      title={t('messages.When do you check-in')}
      titleIcon={WhenDoYouChackInIcon}
    >
      <ChartWrapper>
        <ChartItem
          width="100%"
          height="100%"
          viewBox="0 0 40 40"
          // percentage={energy[selected]?.percentage || 0}
        >
          <circle cx="20" cy="20" r="15.91549430918954" fill="transparent" />
          <circle
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            strokeWidth="3.5"
          />
          {energy.Morning?.percentage > 0 && (
            <circle
              cx="20"
              cy="20"
              r="15.91549430918954"
              fill="transparent"
              strokeWidth={selected === 'Morning' ? 4.5 : 3.5}
              strokeDasharray={getStroke('Morning')}
              strokeDashoffset="25"
              stroke={colors.Morning}
              style={{
                transformOrigin: 'center',
                transform: `rotate(${getRotation('Morning')})`
              }}
            />
          )}
          {energy['Mid-Day']?.percentage > 0 && (
            <circle
              cx="20"
              cy="20"
              r="15.91549430918954"
              fill="transparent"
              strokeWidth={selected === 'Mid-Day' ? 4.5 : 3.5}
              strokeDasharray={getStroke('Mid-Day')}
              strokeDashoffset="25"
              stroke={colors['Mid-Day']}
              style={{
                transformOrigin: 'center',
                transform: `rotate(${getRotation('Mid-Day')})`
              }}
            />
          )}
          {energy.Evening?.percentage > 0 && (
            <circle
              cx="20"
              cy="20"
              r="15.91549430918954"
              fill="transparent"
              strokeWidth={selected === 'Evening' ? 4.5 : 3.5}
              strokeDasharray={getStroke('Evening')}
              strokeDashoffset="25"
              stroke={colors.Evening}
              style={{
                transformOrigin: 'center',
                transform: `rotate(${getRotation('Evening')})`
              }}
            />
          )}
          <g>
            <text y="50%">
              <tspan x="50%">{t(selected, t('Evening'))}</tspan>
            </text>
            <text y="60%">
              <tspan x="50%">{energy[selected]?.percentage || 0}%</tspan>
            </text>
          </g>
        </ChartItem>
        <SelectList>
          <Item>
            <Title
              Color={colors.Morning}
              active={selected === 'Morning'}
              onClick={() => handleSelect('Morning')}
            >
              {t('Morning')}
            </Title>
            <Energy>
              {t('Avg Energy')}: {energy?.Morning.average || '--'}
            </Energy>
          </Item>
          <Item>
            <Title
              Color={colors['Mid-Day']}
              active={selected === 'Mid-Day'}
              onClick={() => handleSelect('Mid-Day')}
            >
              {t('Mid-Day')}
            </Title>
            <Energy>
              {t('Avg Energy')}: {energy['Mid-Day']?.average || '--'}
            </Energy>
          </Item>
          <Item>
            <Title
              Color={colors.Evening}
              active={selected === 'Evening'}
              onClick={() => handleSelect('Evening')}
            >
              {t('Evening')}
            </Title>
            <Energy>
              {t('Avg Energy')}: {energy?.Evening.average || '--'}
            </Energy>
          </Item>
        </SelectList>
      </ChartWrapper>
    </ChartCard>
  );
};

WhenDoYouCheckIn.propTypes = {
  checkIns: PropTypes.arrayOf(
    PropTypes.shape({
      createdDate: PropTypes.string.isRequired,
      energyLevel: PropTypes.number.isRequired,
      feelingContext: PropTypes.string.isRequired,
      feelingWord: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      weather: PropTypes.shape({
        weatherIcon: PropTypes.string.isRequired,
        weatherId: PropTypes.string.isRequired,
        weatherMain: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

export default WhenDoYouCheckIn;
