import React, {
  FC,
  ReactNode,
  createContext,
  useState,
  useContext
} from 'react';
import { ThemeProvider } from 'styled-components';

import { ThemeContextProps } from './types';
import theme from '../theme';
import { THEME_CONTEXT_DEFAULT_VALUE } from './constants';
import { ThemeOptions } from '../types';

const ThemeContext = createContext<ThemeContextProps>(
  THEME_CONTEXT_DEFAULT_VALUE
);

const ThemeContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeOptions>(
    () =>
      (localStorage.getItem('ci_theme') as ThemeOptions) ||
      ('checkingin' as ThemeOptions)
  );

  const changeTheme = (newTheme: ThemeOptions) => {
    const possibleThemeValues = Object.keys(theme);
    let newThemeValue: ThemeOptions = 'checkingin';

    if (possibleThemeValues.includes(newTheme)) newThemeValue = newTheme;

    setCurrentTheme(newThemeValue);
    window.localStorage.setItem('theme', newThemeValue);
  };

  return (
    <ThemeContext.Provider value={{ currentTheme, changeTheme }}>
      <ThemeProvider theme={theme[currentTheme]}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => useContext(ThemeContext);

export { ThemeContextProvider, useTheme };
