import React from 'react';
import PropTypes from 'prop-types';

const StepIconComponent = ({ icon }) => <>{icon}</>;

StepIconComponent.propTypes = {
  icon: PropTypes.node.isRequired
};

export { StepIconComponent };
