import React from 'react';
import PropTypes from 'prop-types';

import { Overlay, Container, CloseButton } from './styles';

const Modal = ({ children, closeModal, isVisible }) => {
  const handleClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      closeModal();
    }
  };

  return (
    <Overlay id="modal-overlay" isVisible={isVisible} onClick={handleClick}>
      <Container isVisible={isVisible}>
        <CloseButton onClick={closeModal}>×</CloseButton>
        {children}
      </Container>
    </Overlay>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  closeModal: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default Modal;
