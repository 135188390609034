import Messages from '../../statics/messages.json';
import FellingWords from '../../statics/felling-words.json';

export const afterCheckinMessage = {
  keyArgs: false,
  read(_, { variables }) {
    const { fellingWord } = variables;
    let targetGroup = Messages[fellingWord];

    if (targetGroup) {
      return targetGroup[Math.floor(Math.random() * targetGroup.length)];
    }

    for (const i in FellingWords) {
      if (
        FellingWords[i].content.some(
          (word) => word.toLowerCase() === fellingWord
        )
      ) {
        targetGroup = Messages[FellingWords[i].title.toLowerCase()];
      }
    }

    targetGroup = targetGroup || Messages.other;
    return targetGroup[Math.floor(Math.random() * targetGroup.length)];
  }
};
