import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Chart } from './style';

const ArcChart = ({ AvgEnergy }) => {
  const { t } = useTranslation();

  const [chartHash] = useState(
    `${new Date().getTime() * Math.random()}`.replace('.', '')
  );

  const shouldMove = () =>
    AvgEnergy - parseInt(AvgEnergy, 10) !== 0 &&
    ((Number(parseFloat(AvgEnergy).toFixed(2)) - parseInt(AvgEnergy, 10)) *
      100) %
      2 ===
      0;

  return (
    <Chart>
      <svg
        viewBox="0 0 40 40"
        width="100%"
        height="100%"
        id={`chart-${chartHash}`}
      >
        <defs>
          <linearGradient id="Gradient1" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#edb844" />
            <stop offset="100%" stopColor="#f2845b" />
          </linearGradient>
          <linearGradient id="Gradient2" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#f2845b" />
            <stop offset="100%" stopColor="#edb844" />
          </linearGradient>
          <pattern
            id="Pattern"
            x="0"
            y="0"
            width="40"
            height="40"
            patternUnits="userSpaceOnUse"
          >
            <g transform="rotate(0, 20, 20)">
              <rect
                shapeRendering="crispEdges"
                x="0"
                y="0"
                width="20"
                height="40"
                fill="url(#Gradient1)"
              />
              <rect
                shapeRendering="crispEdges"
                x="20"
                y="0"
                width="20"
                height="40"
                fill="url(#Gradient2)"
              />
            </g>
          </pattern>
        </defs>
        <clipPath id="cut-off">
          <rect x="-20" y="0" width="400" height="40" />
        </clipPath>
        <circle
          r="32"
          cx="20"
          cy="40"
          fill="transparent"
          stroke="url(#Pattern)"
          strokeWidth="12"
          strokeDasharray="1"
          style={{
            transform: shouldMove() ? `rotate(${(0.5 / 10) * 180}deg)` : '',
            transformOrigin: 'bottom'
          }}
        />
        <circle
          r="32"
          cx="20"
          cy="40"
          fill="transparent"
          stroke="#ccc"
          strokeWidth="12"
          strokeDasharray="1"
          clipPath="url(#cut-off)"
          style={{
            transform: `rotate(${(AvgEnergy / 10) * 180}deg)`,
            transformOrigin: 'bottom'
          }}
        />
      </svg>
      <h1>{AvgEnergy}</h1>
      <span>{t('Avg Energy')}</span>
    </Chart>
  );
};

ArcChart.propTypes = {
  AvgEnergy: PropTypes.number.isRequired
};

export default ArcChart;
