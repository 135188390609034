export const spacing = {
  nano: '0.25rem',
  micro: '0.5rem',
  xxxs: '0.75rem',
  xxs: '1rem',
  xs: '1.5rem',
  sm: '2rem',
  md: '2.5rem',
  lg: '3rem',
  xl: '3.5rem',
  xxl: '5rem'
};

export type Spacing = typeof spacing;
