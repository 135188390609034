export const matchYoutubeUrl = (url) => {
  const youTubeRegex =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(youTubeRegex)) {
    return url.match(youTubeRegex)[1];
  }
  return false;
};

export const getYouTubeVideoId = (url) => {
  const newUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return newUrl[2] !== undefined
    ? newUrl[2].split(/[^0-9a-z_-]/i)[0]
    : newUrl[0];
};
