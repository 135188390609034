import React from 'react';

import { InputWithError } from '../InputWithError';
import { TextInputIconProps } from './types';

import { Container, IconContainer, InputContainer } from './styles';

const TextInputIcon = ({
  type,
  id,
  name,
  autoComplete,
  autoCapitalize,
  value,
  onChange,
  placeholder,
  inputStyle,
  iconComponent,
  error,
  ...props
}: TextInputIconProps) => (
  <Container error={error}>
    {iconComponent ? <IconContainer>{iconComponent}</IconContainer> : null}
    <InputContainer>
      <InputWithError
        type={type}
        id={id}
        name={name}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        {...props}
        style={{
          borderRadius: '0px 4px 4px 0px',
          borderWidth: '0',
          color: '#727578',
          fontSize: '0.813rem',
          padding: '0 0.875rem',
          width: '100%',
          ...inputStyle
        }}
      />
    </InputContainer>
  </Container>
);

TextInputIcon.defaultProps = {
  inputStyle: {},
  iconComponent: null
};

export { TextInputIcon };
