type Exercise = {
  id: string;
  categories: { id: string }[];
  publicationId: string;
};

export const replaceHtmlDeepLink = (html: string, exercise: Exercise) => {
  const currentProtocol = window.location.protocol;
  const currentHost = window.location.host;
  let htmlContent = html;

  const pattern = /checkingin:\/\/[^"\\]+/g;

  const link = htmlContent.match(pattern);

  if (link && link.length) {
    const splittedLink = link[0]?.split('/');

    htmlContent = htmlContent.replace(
      link[0],
      `${currentProtocol}//${currentHost}/${splittedLink?.[2]}/${splittedLink?.[3]}` +
        `?redirectedFromExercise=${exercise.id}&redirectedFromProgram=${exercise.categories[0].id}&redirectedFromPublication=${exercise.publicationId}`
    );
  }

  return htmlContent;
};
