import { z } from 'zod';

export const forgotPasswordSchema = z
  .object({
    password: z.string().min(1),
    confirmPassword: z.string().min(1)
  })
  .superRefine(({ confirmPassword, password }, { addIssue }) => {
    if (confirmPassword !== password) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords don't match",
        path: ['password', 'confirmPassword']
      });
    }
  });
