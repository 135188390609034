export const COLORS = {
  primary: '#D23929',
  secondary: '#F9A156',
  black: {
    400: '#1E1E1E',
    500: '#000000'
  },
  white: {
    500: '#FFFFFF'
  },
  forgotPassword: {
    topBackgroundColor: '#000000',
    topTextColor: '#FFFFFF',
    pageBackgroundColor: '#1E1E1E'
  },
  gray: {
    500: '#666666',
    1000: '#888888'
  }
};

export const IMAGES = {
  forgotPassword: {
    logo: '/forgot-password/TaSkwalwentn-Logo.png'
  }
};
