import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withUser } from '../../services/hoc/user_old';
import {
  useCheckin,
  useReflections,
  useMindfulness
} from '../../services/mutations';

import {
  Summary,
  AvgEnergy,
  MostCommon,
  MoodSummary,
  WhenDoYouCheckIn,
  Weather
} from '../../components/TrendsCharts';

import { PageWrapper, Loading, Tabs, Tab, ChartRow } from './style';

const Trends = ({ user }) => {
  const { t } = useTranslation();

  const dataInsights = {};
  const {
    // loading: loadingCheckins,
    // error: errorCheckins,
    data: dataCheckins
  } = useCheckin();
  const {
    // loading: loadingReflections,
    // error: errorReflections,
    data: dataReflections
  } = useReflections();
  const {
    // loading: loadingMindfulnesses,
    // error: errorMindfulnesses,
    data: dataMindfulnesses
  } = useMindfulness();

  const [minLoad, setMinLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [checkIns, setCheckIns] = useState(null);
  const [insights, setInsights] = useState(null);
  const [mindfulnesses, setMindfulnesses] = useState(null);
  const [reflections, setReflections] = useState(null);

  const [activeTab, setActiveTab] = useState('current');

  useEffect(() => {
    setTimeout(() => setMinLoading(false), 1000);
  }, []);

  useEffect(() => {
    // get data
    if (dataInsights && dataCheckins && dataReflections && dataMindfulnesses) {
      setLoading(false);
      const allData = {
        ...dataCheckins,
        ...dataInsights,
        ...dataMindfulnesses,
        ...dataReflections
      };
      if (
        allData?.checkIns &&
        allData?.insights &&
        allData?.mindfulnesses &&
        allData?.reflections
      ) {
        setCheckIns(allData.checkIns);
        setInsights(allData.insights);
        setMindfulnesses(allData.mindfulnesses);
        setReflections(allData.reflections);
      }
      console.log({
        user,
        ...allData,
        setted: { insights, mindfulnesses, reflections }
      });
    }
  }, [dataInsights, dataCheckins, dataReflections, dataMindfulnesses]);

  const getEnergyLevel = () =>
    Math.round(
      checkIns
        .map(({ energyLevel }) => energyLevel)
        .reduce((acc, curr) => acc + curr, 0) / checkIns.length
    ) || 7;

  const handleChangeTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  if (loading || minLoad) {
    return (
      <Loading>
        <span>{t('Loading')}...</span>
      </Loading>
    );
  }
  return (
    <PageWrapper>
      <Tabs>
        <Tab
          onClick={(e) => handleChangeTab(e, 'current')}
          active={activeTab === 'current'}
        >
          {t('Current')}
        </Tab>
        <Tab
          onClick={(e) => handleChangeTab(e, 'week')}
          active={activeTab === 'week'}
        >
          {t('Week')}
        </Tab>
        <Tab
          onClick={(e) => handleChangeTab(e, 'month')}
          active={activeTab === 'month'}
        >
          {t('Month')}
        </Tab>
        <Tab
          onClick={(e) => handleChangeTab(e, 'year')}
          active={activeTab === 'year'}
        >
          {t('Year')}
        </Tab>
      </Tabs>
      <ChartRow>
        <Summary
          checkInsAmount={checkIns.length || 0}
          AvgEnergy={getEnergyLevel()}
        />
        <AvgEnergy checkIns={checkIns} />
        <MostCommon words={checkIns.map(({ feelingWord }) => feelingWord)} />
      </ChartRow>
      <ChartRow>
        <MoodSummary checkIns={checkIns} />
        <WhenDoYouCheckIn checkIns={checkIns} />
        <Weather checkIns={checkIns} />
      </ChartRow>
    </PageWrapper>
  );
};

Trends.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string
  }).isRequired
};

export default withUser(Trends);
