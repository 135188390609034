import styled, { css } from 'styled-components';
import { fontSizes, colors } from '@checkingin/component-library';

const containerStatusModifier = {
  completed: () => css`
    background-color: ${colors.green.light};
    color: ${colors.white.absolute};
  `,
  new: () => css`
    background-color: ${colors.orange.normal};
    color: ${colors.white.absolute};
  `,
  other: () => css`
    background-color: ${colors.gray.light};
    color: ${colors.black.absolute};
  `
};

const containerPositionModifiers = {
  'over-top': () => css`
    top: -0.625rem;
  `,
  'under-top': () => css`
    top: 0.625rem;
  `
};

export const Container = styled.div`
  display: flex;
  height: 20px;
  width: fit-content;
  align-items: center;
  padding: 0.313rem 0.5rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  position: absolute;
  left: 1rem;
  font-size: ${fontSizes.sm};

  ${({ position, status }) => css`
    ${status && containerStatusModifier[status]()};
    ${position && containerPositionModifiers[position]()};
  `}
`;

export const BadgeContainer = styled.div`
  margin-left: 0.25rem;
`;

export const BadgeText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.white.absolute};
`;
