import React from 'react';
import PropTypes from 'prop-types';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Step, Box, Stepper } from '@mui/material';

import { StepIconComponent } from './StepIconComponent';

import { StepLabel } from './styles';

const ExerciseSectionsPreview = ({ sections }) => (
  <Box>
    <Stepper orientation="vertical">
      {sections?.map(({ title, isDone }, index) => (
        <Step key={`Step-${index}`}>
          <StepLabel
            StepIconComponent={StepIconComponent}
            icon={isDone ? <CheckCircleIcon /> : <PanoramaFishEyeIcon />}
            isDone={isDone}
          >
            {title}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);

ExerciseSectionsPreview.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      sectionId: PropTypes.string,
      title: PropTypes.string,
      isDone: PropTypes.bool
    })
  ).isRequired
};

export { ExerciseSectionsPreview };
