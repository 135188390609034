import styled, { css } from 'styled-components';
import { ErrorProps } from '../../types';

export const Input = styled.input<ErrorProps>`
  ${({ error }) =>
    error &&
    css`
      border-color: red !important;
    `}
`;
