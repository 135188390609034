import React, { useState, useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

import { Tooltip } from './style';

const CheckInTimePieChart = ({ data }) => {
  const [eveningCheckins, setEveningCheckins] = useState(
    Number(data?.evening?.checkins.length) || 0
  );
  const [afternoonCheckins, setAfternoonCheckins] = useState(
    Number(data?.midDay?.checkins.length) || 0
  );
  const [morningCheckins, setMorningCheckins] = useState(
    Number(data?.morning?.checkins.length) || 0
  );

  const [eveningAvgEnergy, setEveningAvgEnergy] = useState(
    Number(data?.evening?.avg) || 0
  );
  const [afternoonAvgEnergy, setAfternoonAvgEnergy] = useState(
    Number(data?.midDay?.avg) || 0
  );
  const [morningAvgEnergy, setMorningAvgEnergy] = useState(
    Number(data?.morning?.avg) || 0
  );

  const [eveningPercentage, setEveningPercentage] = useState(
    Number(data?.evening?.percentage.toFixed(1)) || 0
  );
  const [afternoonPercentage, setAfternoonPercentage] = useState(
    Number(data?.midDay?.percentage.toFixed(1)) || 0
  );
  const [morningPercentage, setMorningPercentage] = useState(
    Number(data?.morning?.percentage.toFixed(1)) || 0
  );

  const getDataset = useCallback(
    () => [
      {
        id: 'Morning',
        label: 'Morning',
        value: morningCheckins,
        averageEnergy: morningAvgEnergy,
        color: '#ffcd80',
        percentage: morningPercentage,
        time: '00:00 - 11:59'
      },
      {
        id: 'Mid-Day',
        label: 'Mid-Day',
        value: afternoonCheckins,
        averageEnergy: afternoonAvgEnergy,
        color: '#51a3a4',
        percentage: afternoonPercentage,
        time: '12:00 - 17:59'
      },
      {
        id: 'Evening',
        label: 'Evening',
        value: eveningCheckins,
        averageEnergy: eveningAvgEnergy,
        color: '#474a57',
        percentage: eveningPercentage,
        time: '18:00 - 23:59'
      }
    ],
    [
      morningCheckins,
      morningAvgEnergy,
      morningPercentage,
      afternoonCheckins,
      afternoonAvgEnergy,
      afternoonPercentage,
      eveningCheckins,
      eveningAvgEnergy,
      eveningPercentage
    ]
  );

  const [pieChartDataset, setPieChartDataset] = useState(getDataset());

  useEffect(() => {
    setEveningCheckins(Number(data?.evening?.checkins.length) || 0);
    setAfternoonCheckins(Number(data?.midDay?.checkins.length) || 0);
    setMorningCheckins(Number(data?.morning?.checkins.length) || 0);
    setEveningAvgEnergy(Number(data?.evening?.avg) || 0);
    setAfternoonAvgEnergy(Number(data?.midDay?.avg) || 0);
    setMorningAvgEnergy(Number(data?.morning?.avg) || 0);
    setEveningPercentage(Number(data?.evening?.percentage.toFixed(1)) || 0);
    setAfternoonPercentage(Number(data?.midDay?.percentage.toFixed(1)) || 0);
    setMorningPercentage(Number(data?.morning?.percentage.toFixed(1)) || 0);
  }, [data]);

  useEffect(() => {
    setPieChartDataset(getDataset());
  }, [eveningPercentage, afternoonPercentage, morningPercentage]);

  const enableRadialLabels = window.innerWidth > 500;

  return (
    <ResponsivePie
      data={pieChartDataset}
      margin={{
        top: 40,
        right: 80,
        bottom: 80,
        left: 80
      }}
      innerRadius={0}
      padAngle={0}
      colors={(d) => {
        if ('data' in d) {
          return d.data.color;
        }
        return d.color;
      }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabel={({ value, data: { averageEnergy } }) => (
        <div>
          <tspan x="0" dy="-0.6em" fontSize="1.25em">
            {value} {value === 1 ? 'check in' : 'check ins'}
          </tspan>
          <tspan x="0" dy="1.2em" fontSize="1em">
            Avg. Energy: {averageEnergy}
          </tspan>
        </div>
      )}
      enableRadialLabels={enableRadialLabels}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={8}
      radialLabelsLinkHorizontalLength={12}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      arcLabel={({ data: { percentage } }) => `${percentage}%`}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor="#FFFFFF"
      animate
      motionStiffness={90}
      motionDamping={15}
      tooltip={({
        datum: {
          data: { color, id, value, time, averageEnergy }
        }
      }) => (
        <Tooltip color={color}>
          <span>
            {id}: {time}
          </span>
          <br />
          <span>
            {value} {value === 1 ? 'check in' : 'check ins'}
          </span>
          <br />
          <span>Avg. Energy: {averageEnergy}</span>
        </Tooltip>
      )}
      legends={[
        {
          anchor: 'bottom',
          display: 'inline-block',
          direction: 'row',
          translateY: 56,
          itemWidth: 80,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
                transform: 'scale(1.5, 1.5)'
              }
            }
          ]
        }
      ]}
    />
  );
};

CheckInTimePieChart.propTypes = {
  data: PropTypes.object.isRequired
};

export default memo(CheckInTimePieChart);
