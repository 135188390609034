import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useContent } from '../../contexts/ContentContext';
import ExerciseItemList from '../../components/ExerciseItemList';

import { ContentList, ContainerCard } from './styles';
import CardHeader from '../../components/CardHeader';
import { ExerciseModal } from '../../components/Modal/ExerciseModal';
import { FailContainer, FailText } from '../Exercise/styles';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useMutation, useQuery } from '@apollo/client';
import contentHistoryGraph from '../../services/graphql-queries/contentHistory';

const Program = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { programId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const publicationId = searchParams.get('publicationId');

  const { programs, addContentHistories, contentHistories } = useContent();

  const [program, setProgram] = useState(null);
  const [modalData, setModalData] = useState({
    hasProgress: false,
    exercise: null
  });

  const { loading, data: publicationContentHistory } = useQuery(
    contentHistoryGraph.GET_CONTENT_HISTORIES,
    {
      fetchPolicy: 'network-only',
      variables: {
        params: {
          publicationRecordId: publicationId || program?.publicationId
        }
      },
      onCompleted: async () => {
        if (publicationContentHistory?.contentHistories?.length > 0) {
          await addContentHistories({
            exerciseContentHistories:
              publicationContentHistory?.contentHistories
          });
        }
      }
    }
  );

  const [saveContentHistory] = useMutation(
    contentHistoryGraph.SAVE_CONTENT_HISTORY,
    {
      onCompleted: async (data) => {
        await addContentHistories({
          exerciseContentHistories: [data.saveContentHistory]
        });
      }
    }
  );

  useEffect(() => {
    let programSelected = programs.find(
      (currentItem) =>
        currentItem.id === programId &&
        currentItem.publicationId === publicationId
    );

    if (!programSelected) {
      programSelected = programs.find(
        (currentItem) => currentItem.id === programId
      );
    }

    if (
      typeof programSelected === 'object' &&
      !Array.isArray(programSelected) &&
      programSelected !== null
    ) {
      setProgram({
        ...programSelected,
        exercises: programSelected?.exercises?.map((exercise) => {
          const responseData = contentHistories?.find(
            (item) =>
              item.cmsId === exercise.id &&
              item.publicationRecordId === exercise.publicationId
          )?.responseData;

          return { ...exercise, responseData };
        })
      });
    }
  }, [publicationId, programId, programs, contentHistories]);

  const handleCloseModal = () => {
    setModalData({
      hasProgress: false,
      exercise: null
    });
  };

  const handleConfirmModal = async () => {
    handleStartExercise(modalData.exercise);
    handleCloseModal();
  };

  const handleOnClickExercise = (exercise) => {
    if (exercise?.exerciseDetails) {
      push(`/exercise/${exercise.id}?publicationId=${exercise.publicationId}`);

      return;
    }

    const hasSomeProgress = exercise?.sections?.some((section) =>
      exercise?.responseData?.some(
        (response) => section.id === response.sectionId
      )
    );

    if (hasSomeProgress) {
      setModalData({
        hasProgress: true,
        exercise
      });
      return;
    }

    handleStartExercise(exercise);
  };

  const handleStartExercise = async (exercise) => {
    await saveContentHistory({
      variables: {
        params: {
          cmsId: exercise.id,
          publicationRecordId: exercise.publicationId,
          responseData: []
        }
      }
    });

    push(
      `/exercise/${exercise.id}/content?publicationId=${exercise.publicationId}`,
      {
        exercise,
        program,
        redirectedFrom: null
      }
    );
  };

  if (loading) {
    return <LoadingSpinner wrapper />;
  }

  if (!program && !loading) {
    return (
      <FailContainer>
        <FailText>{t('messages.Fail to load this program')}</FailText>
      </FailContainer>
    );
  }

  return (
    <ContainerCard>
      <>
        <CardHeader title={program.title} goPrevious={() => push('/home')} />
        <ContentList data-testid="program-content-list">
          {program?.exercises &&
            program?.exercises.map((item, _) => (
              <ExerciseItemList
                key={`ProgramItem-${item.id}`}
                item={item}
                onPress={() => handleOnClickExercise(item)}
              />
            ))}
          {!program?.exercises &&
            programs &&
            programs
              .filter((exercise) =>
                exercise.categories.some(
                  (exerciseCategory) => exerciseCategory?.id === program?.id
                )
              )
              .map((item, _) => (
                <ExerciseItemList
                  key={`ProgramItem-${item.id}`}
                  item={item}
                  onPress={() => handleOnClickExercise(item)}
                />
              ))}
        </ContentList>
        <ExerciseModal
          isOpen={modalData.hasProgress}
          onClose={() => handleCloseModal()}
          message={t('messages.Are you sure you want to start over')}
          handleConfirm={() => handleConfirmModal()}
        />
      </>
    </ContainerCard>
  );
};

export { Program };
