import styled from 'styled-components';

export const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  width: min(100%, 100vw);
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 60vw;
  max-width: 60vw;
  height: 100%;
`;

export const NotificationLine = styled.div`
  width: 100%;
  /* max-height: 180px; */
  /* min-height: 60px; */
  gap: 16px;
  padding-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  overflow-y: auto;
  box-sizing: border-box;
`;
