import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { FileItem } from './FileItem';

import {
  Container,
  ContentContainer,
  DescriptionContainer,
  DescriptionText,
  Divider,
  TitleContainer,
  TitleText
} from './styles';

const ExerciseMedia = ({
  exercise,
  content,
  handleCurrentResponse,
  handleSubmitSection
}) => {
  const shouldTitleAppear = useMemo(
    () => content?.files?.some((file) => !!file?.text),
    [content?.files]
  );

  const isUniqueAndHasNotFileText = useMemo(
    () => content?.files?.length === 1 && !content?.files[0]?.text,
    [content?.files]
  );

  useEffect(() => {
    handleCurrentResponse({});
  }, [content]);

  return (
    <Container>
      {shouldTitleAppear && (
        <TitleContainer>
          <TitleText>{content.title ?? ''}</TitleText>
        </TitleContainer>
      )}
      {content?.description && (
        <>
          <DescriptionContainer>
            <DescriptionText
              dangerouslySetInnerHTML={{
                __html: content.description.replaceAll('\n', '<br/>')
              }}
            />
          </DescriptionContainer>
          <Divider />
        </>
      )}
      <ContentContainer>
        {content?.files?.map((file, fileIndex) => (
          <FileItem
            exercise={exercise}
            key={`File-${file.type}-${fileIndex}`}
            isUnique={content.files.length === 1}
            file={file}
            isDownloaded={content?.isDownloaded}
            subscribeSectionTitle={
              isUniqueAndHasNotFileText ? content.title : null
            }
            autoPlay={content?.autoPlay}
            handleSubmitSection={handleSubmitSection}
          />
        ))}
      </ContentContainer>
    </Container>
  );
};

ExerciseMedia.propTypes = {
  exercise: PropTypes.object.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    isDownloaded: PropTypes.bool,
    autoPlay: PropTypes.bool,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        thumbnailUrl: PropTypes.string,
        type: PropTypes.string.isRequired,
        fileUrl: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  handleCurrentResponse: PropTypes.func.isRequired,
  handleSubmitSection: PropTypes.func.isRequired
};

export { ExerciseMedia };
