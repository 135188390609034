import AllCategories from '../../services/static/categories.json';
import AllColorCategories from '../../services/static/color-categories.json';
import {
  WordCategories,
  WordColorCategories,
  Word,
  WordFrequency
} from '../../services/insights/types';

const Categories: WordCategories = <WordCategories>AllCategories;
const ColorCategories: WordColorCategories = <WordColorCategories>(
  AllColorCategories
);

const getWordFrequency = (data: Word[]) =>
  data.reduce(
    (acc: WordFrequency, cur: Word) => ({
      ...acc,
      [cur.feelingWord.toLowerCase()]:
        acc[cur.feelingWord.toLowerCase()] + 1 || 1
    }),
    {}
  );

const getWordCloud = (data: Word[]) => {
  const wordFrequency: WordFrequency = getWordFrequency(data);

  const listWordsCloud = [];

  for (const key in wordFrequency) {
    if (key) {
      listWordsCloud.push({
        text: key.charAt(0).toUpperCase() + key.slice(1),
        value: wordFrequency[key]
      });
    }
  }

  return listWordsCloud;
};

const getWordsCategorized = (data: Word[]) => {
  const wordFrequency: WordFrequency = getWordFrequency(data);
  const words = Object.keys(wordFrequency);

  const categorizedWords = [];

  for (const categoryKey in Categories) {
    if (categoryKey) {
      const checkins = Categories[categoryKey].reduce((acc, cur) => {
        const targetWord = words.find((word) => cur === word);
        if (targetWord) {
          words.splice(words.indexOf(targetWord), 1);
          return acc + wordFrequency[targetWord];
        }

        return acc;
      }, 0);

      categorizedWords.push({
        checkins,
        color: ColorCategories[categoryKey],
        emotion: `${categoryKey[0].toUpperCase()}${categoryKey.slice(1)}`,
        percentage:
          parseFloat(((checkins * 100) / data.length).toFixed(0)) || null
      });
    }
  }

  const sumOtherWords = words.reduce(
    (acc, word) => acc + wordFrequency[word],
    0
  );

  categorizedWords.push({
    checkins: sumOtherWords,
    color: ColorCategories.other,
    emotion: `Other`,
    percentage:
      parseFloat(((sumOtherWords * 100) / data.length).toFixed(0)) || null
  });

  return categorizedWords.sort((a, b) => a.checkins - b.checkins);
};

export { getWordCloud, getWordsCategorized };
