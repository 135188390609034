import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-CA';
import moment from 'moment';

export function formatDate(dateString) {
  try {
    const preFormattedDate = new Date(dateString).toLocaleTimeString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });

    const untilCommaIndex = preFormattedDate.lastIndexOf(',') + 1;
    const headString = preFormattedDate.substr(0, untilCommaIndex);
    const tailString = preFormattedDate.substr(untilCommaIndex);

    return `${headString} at${tailString}`;
  } catch (e) {
    return 'Date was not provide';
  }
}

export const formatCalenderDayWithoutTime = (timestamp) => {
  const year = timestamp.substring(0, 4);
  const month = Number(timestamp.substring(5, 7)) - 1;
  const day = timestamp.substring(8, 10);
  return format(new Date(year, month, day), 'eeee, LL/dd/yyyy', {
    locale: enLocale
  });
};

export const DaysSinceDate = (timestamp) =>
  moment().utc().diff(moment(timestamp).startOf('day'), 'days');
