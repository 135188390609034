import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

const WordCloudBarChart = ({ data }) => (
  <ResponsiveBar
    theme={{ fontSize: '1em' }}
    data={data}
    keys={['percentage']}
    indexBy="emotion"
    margin={{
      top: 50,
      right: 60,
      bottom: 50,
      left: 80
    }}
    tooltip={({ indexValue, color, data: { checkins } }) => (
      <strong style={{ color }}>
        {indexValue}: {checkins} check ins
      </strong>
    )}
    enableGridY={false}
    groupMode="grouped"
    layout="horizontal"
    colors={({ data: { color } }) => color}
    colorBy="index"
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickValues: [
        0,
        data[0]?.percentage || 0,
        data[data.length - 1]?.percentage || 0
      ],
      tickSize: 4,
      tickPadding: 4,
      tickRotation: 0,
      legend: 'Percent (%)',
      legendPosition: 'middle',
      legendOffset: 40
    }}
    labelSkipWidth={0}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['brighter', 2]] }}
    animate={false}
    motionStiffness={90}
    motionDamping={15}
  />
);

WordCloudBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
};

export default WordCloudBarChart;
