import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { z } from 'zod';
import toast from 'react-hot-toast';

import { TextInputIcon } from '../../../components/TextInputIcon';
import { ForgotPasswordPageProps, ForgotPasswordDataProps } from './types';
import { forgotPasswordSchema } from '../../../validations/forgotPassword';
import { ErrorProp } from '../../../types';
import { useTheme } from '../../../contexts/ThemeContext';
import { useForgotPassword } from './hooks';

import {
  ConfirmButtonImage,
  ConfirmContainer,
  Container,
  LogoImage,
  FormContainer,
  InputInnerContainer,
  InputSecondInputContainer,
  InputText,
  LockOutlinedIcon,
  SubContainer,
  Title,
  TitleContainer,
  TitleInnerContainer,
  FormInnerContainer,
  CircularProgress,
  InputTextError,
  DangerRoundedIcon,
  ErrorContainer
} from './styles';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { token, slug, email } = useParams<ForgotPasswordPageProps>();
  const { currentTheme, changeTheme } = useTheme();
  const { getTokenValidation, getRedirectLink, resetPassword } =
    useForgotPassword({
      token
    });

  const formInitialValues = {
    password: '',
    confirmPassword: ''
  };
  const [formData, setFormData] =
    useState<ForgotPasswordDataProps>(formInitialValues);
  const [loading, setLoading] = useState({
    page: false,
    form: false
  });
  const [errors, setErrors] = useState<ErrorProp[]>([]);
  const [isFormEnable, setIsFormEnable] = useState(true);
  const [redirectLink, setRedirectLink] = useState<string>(
    'https://checkingin.co/'
  );

  useLayoutEffect(() => {
    if (currentTheme !== slug) changeTheme(slug);
  }, [currentTheme, slug]);

  const handleOnChangeForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    const validateTokenAndRedirect = async () => {
      setLoading((prevState) => ({
        ...prevState,
        page: true
      }));

      try {
        const {
          data: { validateToken: isTokenValid }
        } = await getTokenValidation();

        const appRedirectLink = await getRedirectLink();

        if (appRedirectLink) {
          setRedirectLink(appRedirectLink);
        }

        if (!isTokenValid) setIsFormEnable(false);
      } catch (err) {
        toast.error(t('The token could not be validated Please try again'));

        setIsFormEnable(false);
      } finally {
        setLoading((prevState) => ({
          ...prevState,
          page: false
        }));
      }
    };

    validateTokenAndRedirect();
  }, [token]);

  const handleOnConfirmForm = async (event: any) => {
    event.preventDefault();

    setLoading((prevState) => ({
      ...prevState,
      form: true
    }));

    try {
      await forgotPasswordSchema.parseAsync(formData);

      setErrors([]);

      await resetPassword(formData.password);

      setFormData(formInitialValues);
      toast.success(t('Password reset successfully'));

      setTimeout(() => {
        window.location.replace(redirectLink);
      }, 3000);
    } catch (err) {
      if (err instanceof z.ZodError) {
        const { issues } = err;

        setErrors(
          issues.map((issue) => ({
            ...issue,
            path: issue.path.join('.')
          }))
        );

        toast.error(
          t('Please fill in all required fields marked with a red border')
        );
      } else {
        toast.error(t('The password could not be changed Please try again'));
      }
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        form: false
      }));
    }
  };

  if (loading.page)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );

  return (
    <Container>
      <SubContainer>
        <TitleContainer>
          <LogoImage />
          <TitleInnerContainer>
            <Title>{t('Change Password')}</Title>
          </TitleInnerContainer>
        </TitleContainer>
        <FormContainer onSubmit={(event) => handleOnConfirmForm(event)}>
          <FormInnerContainer>
            {!isFormEnable ? (
              <ErrorContainer>
                <DangerRoundedIcon />
                <br />
                <InputTextError>
                  {t(
                    'This link is no longer valid Please start the process again'
                  )}
                </InputTextError>
              </ErrorContainer>
            ) : (
              <>
                <InputText>{t('Enter a new password for')}</InputText>
                <br />
                <InputText>{email}</InputText>
                <InputInnerContainer>
                  <TextInputIcon
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="off"
                    autoCapitalize="off"
                    placeholder={t('Your new password')}
                    iconComponent={<LockOutlinedIcon />}
                    value={formData.password}
                    onChange={(event) => handleOnChangeForm(event)}
                    error={errors.some(({ path }) => path.includes('password'))}
                    disabled={!isFormEnable}
                  />
                  <InputSecondInputContainer>
                    <TextInputIcon
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      autoComplete="off"
                      autoCapitalize="off"
                      placeholder={t('Confirm your new password')}
                      iconComponent={<LockOutlinedIcon />}
                      value={formData.confirmPassword}
                      onChange={(event) => handleOnChangeForm(event)}
                      error={errors.some(({ path }) =>
                        path.includes('confirmPassword')
                      )}
                      disabled={!isFormEnable}
                    />
                  </InputSecondInputContainer>
                </InputInnerContainer>
              </>
            )}
          </FormInnerContainer>
          <ConfirmContainer>
            <IconButton type="submit" disabled={!isFormEnable}>
              {isFormEnable && loading.form ? (
                <CircularProgress customColor="white" />
              ) : (
                <ConfirmButtonImage
                  src="/forgot-password/IconRight.svg"
                  disabled={!isFormEnable}
                />
              )}
            </IconButton>
          </ConfirmContainer>
        </FormContainer>
      </SubContainer>
    </Container>
  );
};

export { ForgotPassword };
