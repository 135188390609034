export const EXTENSIONS_NOT_SUPPORTED = ['docx', 'doc'];

export const getFileExtension = (fileUrl) => {
  const fileUrlSplitted = fileUrl.split('.');

  return fileUrlSplitted[fileUrlSplitted.length - 1];
};

export const isFileUnsupported = (fileUrl) => {
  const fileExtension = getFileExtension(fileUrl);

  return EXTENSIONS_NOT_SUPPORTED.includes(fileExtension);
};
