import { gql } from '@apollo/client';
import fragments from '../graphql-queries/fragments';

const queries = {
  GET_CHECKINS: gql`
    query GetCheckIns {
      checkIns {
        id
        ...CheckInProperties
      }
    }
    ${fragments.checkIn}
  `,
  GET_CHECKINS_PAGINATION: gql`
    query GetCheckInsWithPagination($offset: Int, $limit: Int) {
      checkInsWithPagination(offset: $offset, limit: $limit) {
        total
        checkIns {
          id
          ...CheckInProperties
        }
      }
    }
    ${fragments.checkIn}
  `,

  GET_SHAREDCHECKINS: gql`
    query Get($request: SharedCheckinsRequest!) {
      sharedWithMe(params: $request) {
        user {
          id
          name
        }
        checkIns {
          id
          ...CheckInProperties
        }
      }
    }
    ${fragments.checkIn}
  `,
  AFTER_CHECKIN_MESSAGE: gql`
    query GetAfterCheckinMessage($fellingWord: String) {
      afterCheckinMessage(fellingWord: $fellingWord) @client
    }
  `
};

export default queries;
