import {
  COLORS as CheckingInColors,
  IMAGES as CheckingInImages
} from './CheckingIn';
import { COLORS as SquamishColors, IMAGES as SquamishImages } from './Squamish';
import { COLORS as SqewletsColors, IMAGES as SqewletsImages } from './Sqewlets';
import { COLORS as TlaaminColors, IMAGES as TlaaminImages } from './Tlaamin';
import {
  COLORS as StzuminusColors,
  IMAGES as StzuminusImages,
  FORGOT_PASSWORD as StzuminusForgotPassword
} from './Stzuminus';

import { COMMON_THEME_VALUES } from './Common';

const THEME = {
  checkingin: {
    ...COMMON_THEME_VALUES,
    colors: CheckingInColors,
    images: CheckingInImages
  },
  squamish: {
    ...COMMON_THEME_VALUES,
    colors: SquamishColors,
    images: SquamishImages
  },
  sqewlets: {
    ...COMMON_THEME_VALUES,
    colors: SqewletsColors,
    images: SqewletsImages
  },
  tlaamin: {
    ...COMMON_THEME_VALUES,
    colors: TlaaminColors,
    images: TlaaminImages
  },
  stzuminus: {
    ...COMMON_THEME_VALUES,
    colors: StzuminusColors,
    images: StzuminusImages,
    forgotPassword: StzuminusForgotPassword
  }
};

export default THEME;
