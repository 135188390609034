import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import 'react-vis/dist/style.css';
// import { XYPlot, VerticalBarSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis } from 'react-vis';
import { Bar } from 'react-chartjs-2';

import ChartCard from '../../ChartCard';

import AvgEnergyIcon from '../../../assets/svg/Trends/calendar.svg';

const AvgEnergy = ({ checkIns, numberOfDaysToShow = 7 }) => {
  const { t } = useTranslation();

  const mutateDate = (date) =>
    `${date.getFullYear()} ${date.getMonth()} ${date.getDate()}`;

  const generateEnergy = () => {
    const getNewDate = (index) => {
      const today = new Date();
      today.setDate(today.getDate() - index);
      return mutateDate(today);
      // return today;
    };

    const data = new Array(numberOfDaysToShow)
      .fill(0)
      .map((_, index) => ({ energyLevel: 0, date: getNewDate(index) }))
      .reverse();
    return data;
  };

  const getFormattedDate = (dateString) => {
    const today = new Date();
    if (mutateDate(today) === dateString) return t('Today');
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    const splitted = dateString.split(' ');

    if (Number(splitted[0]) === today.getFullYear()) {
      // if (Number(splitted[1]) === today.getMonth()) {
      //   //same month, return just the day
      //   return splitted[2]
      // } else {
      // new month, return month abbreviation and day
      return `${months[Number(splitted[1])]} ${splitted[2]}`;
      // }
    }
    // last year, return everything
    return `${splitted[0].slice(2)} ${months[Number(splitted[1])]} ${
      splitted[2]
    }`;
  };

  const [energy, setEnergy] = useState([]);

  useEffect(() => {
    const lastCheckins = checkIns?.slice(-numberOfDaysToShow) || [];
    const tempEnergies = generateEnergy();

    for (const checkin of lastCheckins) {
      const day = mutateDate(new Date(checkin.createdDate));
      const toChange = tempEnergies.findIndex(({ date }) => date === day);
      if (toChange !== -1) {
        tempEnergies[toChange].energyLevel = checkin.energyLevel;
      }
    }

    const ctx = document.getElementById('bar')?.getContext('2d');
    const gradient = ctx?.createLinearGradient(0, 0, 0, 400);
    gradient?.addColorStop(0, '#f2845b');
    gradient?.addColorStop(1, '#edb844');

    const data = tempEnergies.map(({ energyLevel }) => energyLevel);
    const backgroundColor = tempEnergies.map(({ energyLevel }) =>
      energyLevel === 0 ? '#ccc' : gradient
    ); // rgb(60, 179, 113)

    const chartData = {};
    chartData.labels = tempEnergies.map(({ date }) => getFormattedDate(date));
    chartData.parsing = false;
    chartData.datasets = [
      {
        label: t('Energy level'),
        data,
        backgroundColor,
        borderColor: backgroundColor
      }
    ];
    setEnergy(chartData);
  }, []);

  return (
    <ChartCard
      title={t('messages.Your last energy reports')}
      titleIcon={AvgEnergyIcon}
    >
      <Bar
        id="bar"
        data={energy}
        options={{
          legend: {
            display: false
          },
          title: {
            display: true,
            text: ''
          },
          scales: {
            // y: {
            //   max: 10,
            // },
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          responsive: true,
          maintainAspectRatio: false
        }}
      />
    </ChartCard>
  );
};

AvgEnergy.propTypes = {
  checkIns: PropTypes.arrayOf(
    PropTypes.shape({
      createdDate: PropTypes.string.isRequired,
      energyLevel: PropTypes.number.isRequired,
      feelingContext: PropTypes.string.isRequired,
      feelingWord: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      weather: PropTypes.shape({
        weatherIcon: PropTypes.string.isRequired,
        weatherId: PropTypes.string.isRequired,
        weatherMain: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  numberOfDaysToShow: PropTypes.number.isRequired
};

export default AvgEnergy;
