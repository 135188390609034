import styled from 'styled-components';

export const Container = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  background-color: #e9eff0;
  color: #478b93;
  text-align: center;
`;

export const TitleContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.h2`
  font-weight: 700;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 30px;
  flex: 4;
`;

export const GenerateButton = styled.button`
  width: 60%;
  border: 0;
  border-radius: 25px;
  padding: 12px;
  background-color: #e66842;
  color: #fff;
  transition: none;

  :hover {
    color: #fff;
    background-color: #f59088;
  }

  :active {
    background-color: #dd491d;
  }
`;
