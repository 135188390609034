import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ExerciseItemList from '../../components/ExerciseItemList';

import { useContent } from '../../contexts/ContentContext';
import { useUserData } from '../../contexts/UserDataContext';

import checkinsImg from '../../assets/img/check-in.jpg';
import mindfulnessImg from '../../assets/img/mindfulness.jpg';

import {
  ContentList,
  ContainerCard,
  Greeting,
  HeaderContent,
  Item
} from './styles';

const Home = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { userData } = useUserData();
  const { programs } = useContent();

  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting(t('Good morning'));
    } else if (currentHour < 17) {
      setGreeting(t('Good afternoon'));
    } else {
      setGreeting(t('Good evening'));
    }
  }, []);

  const filteredFeaturedPrograms = useMemo(
    () =>
      programs.filter((program) =>
        program?.visibleOnly && program.visibleOnly.length > 0
          ? program.visibleOnly.find((screen) => screen === 'home')
          : false
      ),
    [programs]
  );

  return (
    <ContainerCard data-testid="home-container">
      <Greeting>
        {greeting}
        {userData.name ? `, ${userData.name}` : ''}
      </Greeting>
      <>
        <HeaderContent>{t('Today')}</HeaderContent>

        <ContentList>
          <Item
            backgroundImage={checkinsImg}
            boxShadow="rgba(29, 92, 93, 0.65)"
            onClick={() => {
              push('/checkin');
            }}
          >
            {t('Check In')}
          </Item>

          <Item
            backgroundImage={mindfulnessImg}
            onClick={() => {
              push(`/mindfulness`);
            }}
          >
            {t('Mindfulness')}
          </Item>
        </ContentList>
      </>
      {filteredFeaturedPrograms?.length > 0 && (
        <>
          <HeaderContent>
            {userData && userData?.groupName
              ? t('Company Programs', { companyName: userData.groupName })
              : t('Featured Exercise')}
          </HeaderContent>
          <ContentList>
            {filteredFeaturedPrograms.map((contentItem, contentItemKey) => (
              <ExerciseItemList
                key={`FeaturedProgramItem-${contentItemKey}`}
                item={contentItem}
                shouldHideNewsWithoutCount
                onPress={() =>
                  contentItem?.exercises
                    ? push(
                        `/program/${contentItem.id}?publicationId=${contentItem.publicationId}`
                      )
                    : push(
                        `/exercise/${contentItem.id}?publicationId=${contentItem.publicationId}`
                      )
                }
              />
            ))}
          </ContentList>
        </>
      )}
    </ContainerCard>
  );
};

export { Home };
