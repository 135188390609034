import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MediaItem } from './MediaItem';
import { ExerciseAudioVideo } from '../AudioVideo';
import { ExerciseImage } from '../Image';
import { ExerciseFile } from '../File';
import { Preview } from './Preview';

import externalLinkIcon from '../../../../../assets/svg/external-link-square.svg';
import eyeIcon from '../../../../../assets/svg/eye.svg';
import fileDownloadIcon from '../../../../../assets/svg/file-download.svg';
import playCircleIcon from '../../../../../assets/svg/play-circle.svg';

import { MEDIA_TYPES } from '../../../../../helpers/media';
import { getFileExtension } from '../../../../../helpers/file';
import { matchYoutubeUrl } from '../../../../../helpers/video';
import { replaceHtmlDeepLink } from '../../../../../helpers/replaceHtmlDeepLink';

import { Box } from './styles';
import { EXERCISE_MODAL_CLASS_NAME } from '../../../../../constants/exercise';

const iconsTypes = {
  video: [
    {
      icon: playCircleIcon
    },
    {
      icon: fileDownloadIcon,
      isDownload: true
    }
  ],
  audio: [
    {
      icon: playCircleIcon
    },
    {
      icon: fileDownloadIcon,
      isDownload: true
    }
  ],
  file: [
    {
      icon: eyeIcon
    },
    {
      icon: fileDownloadIcon,
      isDownload: true
    }
  ],
  link: [
    {
      icon: externalLinkIcon
    },
    null
  ],
  image: [
    {
      icon: eyeIcon
    },
    {
      icon: fileDownloadIcon,
      isDownload: true
    }
  ]
};

const FileItem = ({
  exercise,
  isUnique,
  file,
  isDownloaded,
  subscribeSectionTitle,
  autoPlay,
  handleSubmitSection
}) => {
  const { type, fileUrl } = file;

  const { t } = useTranslation();

  const [isDownloading, setIsDownloading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (
      autoPlay &&
      (type === MEDIA_TYPES.audio.value || type === MEDIA_TYPES.video.value)
    )
      handleSubmitSection();

    setOpen(false);
  };

  const isYoutube = matchYoutubeUrl(fileUrl);

  const isLinkOrFile =
    type === MEDIA_TYPES.link.value || type === MEDIA_TYPES.file.value;

  const exerciseModalIsOpen = !!document.getElementsByClassName(
    EXERCISE_MODAL_CLASS_NAME
  ).length;

  const functionOnFileType = {
    audio: () => (
      <ExerciseAudioVideo
        fileUrl={fileUrl}
        type={type}
        autoPlay={autoPlay}
        onEnded={handleClose}
      />
    ),
    video: () => (
      <ExerciseAudioVideo
        fileUrl={fileUrl}
        type={type}
        autoPlay={autoPlay}
        onEnded={handleClose}
      />
    ),
    file: () => <ExerciseFile fileUrl={fileUrl} />,
    image: () => <ExerciseImage fileUrl={fileUrl} />
  };

  const handleOnPlay = () => {
    if (exerciseModalIsOpen) return;

    if (type === 'link') {
      const newLink = replaceHtmlDeepLink(fileUrl, exercise);
      const newWindow = window.open(newLink, '_blank', 'noopener,noreferrer');

      if (newWindow) newWindow.opener = null;
    } else handleOpen();
  };

  const handleOnDownload = () => {
    if (isYoutube) {
      toast.error(t(`messages.Is not possible to download a youtube video`));
      return;
    }

    const date = new Date();
    const fileExtension = getFileExtension(fileUrl);

    setIsDownloading(true);

    fetch(fileUrl)
      .then((response) => {
        response.blob().then((blob) => {
          // Creating new object of file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `CheckingIn_${Math.floor(
            date.getTime() + date.getSeconds() / 2
          )}.${fileExtension}`;
          alink.click();
        });
      })
      .catch(() => {
        toast.error(t('messages.Error downloading the file'));
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const renderUniqueMedia = () => {
    if (isLinkOrFile) {
      return (
        <MediaItem
          file={file}
          iconsTypes={iconsTypes}
          isDownloaded={isDownloaded}
          subscribeSectionTitle={subscribeSectionTitle}
          isDownloading={isDownloading}
          autoPlay={autoPlay}
          isPreview
          defaultImage={exercise?.backgroundImage}
          handleOnPlay={handleOnPlay}
          handleOnDownload={handleOnDownload}
        />
      );
    }

    return (
      <Preview
        file={file}
        autoPlay={autoPlay}
        defaultImage={exercise?.backgroundImage}
        handleOnPlay={handleOnPlay}
      />
    );
  };

  return (
    <>
      {isUnique && renderUniqueMedia()}

      {!isUnique && (
        <MediaItem
          file={file}
          iconsTypes={iconsTypes}
          isDownloaded={isDownloaded}
          isDownloading={isDownloading}
          defaultImage={exercise?.backgroundImage}
          handleOnPlay={handleOnPlay}
          handleOnDownload={handleOnDownload}
        />
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          {functionOnFileType[type] ? functionOnFileType[type]() : null}
        </Box>
      </Modal>
    </>
  );
};

FileItem.defaultProps = {
  subscribeSectionTitle: null,
  autoPlay: false
};

FileItem.propTypes = {
  exercise: PropTypes.object.isRequired,
  isUnique: PropTypes.bool.isRequired,
  isDownloaded: PropTypes.bool.isRequired,
  file: PropTypes.shape({
    type: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string
  }).isRequired,
  subscribeSectionTitle: PropTypes.string,
  autoPlay: PropTypes.bool,
  handleSubmitSection: PropTypes.func.isRequired
};

export { FileItem };
