import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme as muiCreateTheme,
  THEME_ID
} from '@mui/material/styles';

import { AppRoutes } from './routes';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { theme as chakraTheme } from './chakra-theme';

function App() {
  return (
    <ChakraProvider theme={chakraTheme} resetCSS>
      <MaterialThemeProvider theme={{ [THEME_ID]: muiCreateTheme() }}>
        <ThemeContextProvider>
          <AppRoutes />
        </ThemeContextProvider>
      </MaterialThemeProvider>
    </ChakraProvider>
  );
}

export { App };
