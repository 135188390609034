import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@checkingin/component-library';

import {
  PageWrapper,
  FilterWrapper,
  MonthTitle,
  Card,
  CardTitle,
  CardBody,
  LockedCardBody,
  SummaryData,
  SummaryTab,
  SummaryWeekList,
  SingleContentWrapper,
  MultipleContentWrapper
} from '../Insights/style';

import LoadingOverlay from '../../components/LoadingOverlay';

import AverageDailyChart from '../../components/InsightCharts/AverageDailyEnergy';
import TimeOfCheckIn from '../../components/InsightCharts/TimeOfCheckIns';
import WordCloud from '../../components/InsightCharts/WordCloud';
import WordCloudBar from '../../components/InsightCharts/WordCloudBar';

import LockedIcon from '../../assets/svg/locked.svg';

import withServices from '../../services/hoc/insights';
import { getAvailableMonths, getWeekFilterOptions } from '../Insights/helpers';
import { hideGroupInsights } from '../../helpers/user';
import {
  applySubGroupFilter,
  getPeriod,
  getSubGroupsFilterOptions,
  getWeekFilteredData,
  mergeAllSubGroups
} from './helpers';
import { getMonthGraphs } from './monthlyGraphs';
import { getWeekGraphs } from './weeklyGraphs';

const GroupInsights = ({ services }) => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const subGroupSelectRef = useRef();
  const monthSelectRef = useRef();
  const weekSelectRef = useRef();

  const [monthlyGraphs, setMonthlyGraphs] = useState(false);
  const [weeklyGraphs, setWeeklyGraphs] = useState(false);

  const monthFilterOptions = getAvailableMonths();
  const [weekFilterOptions, setWeekFilterOptions] = useState(false);
  const [subGroupsFilterOptions, setSubGroupsFilterOptions] = useState([]);

  const [monthFilter, setMonth] = useState(false);
  const [weekFilter, setWeek] = useState(false);
  const [subGroupFilter, setSubGroup] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [insightsData, setInsightsData] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [lockedMessage, setLockedMessage] = useState(
    t('messages.Locked due to insufficient data')
  );
  const [isAdmin, setIsAdmin] = useState(false);

  const getInsightsData = async (year, month) => {
    setIsLoading(true);
    const response = await services.getInsightsData(getPeriod({ year, month }));
    if (response?.data?.user) {
      setIsAdmin(response?.data?.user.isAdmin);
    }
    if (response?.data?.insights) {
      setInsightsData(response?.data?.insights);
      setSubGroupsFilterOptions(
        getSubGroupsFilterOptions(response?.data?.insights)
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (insightsData) {
      setMonthlyGraphs(
        getMonthGraphs(
          applySubGroupFilter(insightsData?.[0], subGroupFilter),
          monthFilter,
          true
        )
      );
    }
  }, [insightsData]);

  useEffect(() => {
    const insightsFilter = insightsData?.[0]?.group?.insightsFilter;
    if (hideGroupInsights(insightsFilter, isAdmin)) {
      setIsLocked(true);
      setLockedMessage(t('messages.Graphics are not available at the moment'));
    }
  }, [insightsData, isLocked]);

  useEffect(() => {
    if (subGroupFilter && insightsData) {
      let lock = false;
      if (subGroupFilter?.value === 0) {
        const checkins =
          insightsData?.[0]?.subGroups?.reduce(
            (acc, curr) => [...curr.checkIns, ...acc],
            []
          ) || [];
        const owners = checkins
          .map(({ owner: { id } }) => id)
          .filter((id, index, arr) => arr.indexOf(id) === index);
        if (checkins.length < 5 || owners.length < 3) {
          lock = true;
        }
      } else {
        const checkins =
          insightsData?.[0]?.subGroups?.[subGroupFilter?.value - 1]?.checkIns ||
          [];
        const owners =
          checkins
            ?.map(({ owner: { id } }) => id)
            ?.filter((id, index, arr) => arr.indexOf(id) === index) || [];
        if (checkins?.length < 5 || owners?.length < 3) {
          lock = true;
        }
      }
      setIsLocked(lock);
    } else {
      setIsLocked(true);
    }
  }, [subGroupFilter, insightsData]);

  useEffect(() => {
    if (monthFilter) {
      getInsightsData(monthFilter?.year, monthFilter?.month);
      setWeekFilterOptions(
        getWeekFilterOptions(monthFilter?.month, monthFilter?.year)
      );
    }
  }, [monthFilter]);

  useEffect(() => {
    if (subGroupFilter && insightsData) {
      setMonthlyGraphs(
        getMonthGraphs(
          applySubGroupFilter(insightsData?.[0], subGroupFilter),
          monthFilter,
          true
        )
      );

      const mergedData = mergeAllSubGroups(
        applySubGroupFilter(insightsData?.[0], subGroupFilter)
      );
      const { currentWeekFilteredData, priorWeekFilteredData } =
        getWeekFilteredData(mergedData, weekFilter, weekFilterOptions);

      setWeeklyGraphs(
        getWeekGraphs(currentWeekFilteredData, priorWeekFilteredData)
      );
    }
  }, [subGroupFilter]);

  useEffect(() => {
    if (weekFilter && monthFilter && insightsData && subGroupFilter) {
      const mergedData = mergeAllSubGroups(
        applySubGroupFilter(insightsData?.[0], subGroupFilter)
      );
      const { currentWeekFilteredData, priorWeekFilteredData } =
        getWeekFilteredData(mergedData, weekFilter, weekFilterOptions);

      setWeeklyGraphs(
        getWeekGraphs(currentWeekFilteredData, priorWeekFilteredData)
      );
    }
  }, [weekFilter]);

  useEffect(() => {
    if (subGroupsFilterOptions) {
      setSubGroup(subGroupsFilterOptions[0]);
      subGroupSelectRef.current.setSelectedItem(subGroupsFilterOptions[0]);
    }
  }, [subGroupsFilterOptions]);

  useEffect(() => {
    if (weekFilterOptions) {
      setWeek(weekFilterOptions[0]);
      weekSelectRef.current.setSelectedItem(weekFilterOptions[0]);
    }
  }, [weekFilterOptions]);

  useEffect(() => {
    if (monthFilterOptions) {
      setMonth(monthFilterOptions[0]);
      monthSelectRef.current.setSelectedItem(monthFilterOptions[0]);
    }
    window.addEventListener(
      'resize',
      () => setIsMobile(window.innerWidth < 768),
      true
    );
    return () => {
      window.removeEventListener(
        'resize',
        () => setIsMobile(window.innerWidth < 768),
        true
      );
    };
  }, []);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <PageWrapper>
        <SingleContentWrapper>
          <FilterWrapper>
            <MonthTitle>{monthFilter?.monthDisplayRange}</MonthTitle>
            <Select
              items={subGroupsFilterOptions || []}
              onSelect={setSubGroup}
              shouldValidate={false}
              label={t('Filter Teams')}
              placeholder={t('Select a team')}
              ref={subGroupSelectRef}
              width="100%"
            />
            <Select
              items={monthFilterOptions || []}
              onSelect={setMonth}
              shouldValidate={false}
              label={t('Filter by month')}
              placeholder={t('Select a month')}
              ref={monthSelectRef}
              width="100%"
            />
          </FilterWrapper>
        </SingleContentWrapper>
        <SingleContentWrapper>
          <Card Area="summary">
            <CardTitle>{t('Summary')}</CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody>
                {Array.isArray(monthlyGraphs?.summary) ? (
                  <SummaryData isGroup>
                    <table>
                      <thead>
                        <tr>
                          <th scope="col" aria-label="empty" />
                          <th>{t('Check ins')}</th>
                          <th>{t('Reflections')}</th>
                          {!isMobile && (
                            <>
                              <th>{t('Mindfulness')}</th>
                              <th>{t('Total')}</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {monthlyGraphs?.summary?.map(
                          ({
                            checkins,
                            reflections,
                            mindfulnesses,
                            name,
                            actionsCompleted
                          }) => (
                            <tr key={`sumary-${Math.random()}`}>
                              <td>{name}</td>
                              <td>{checkins}</td>
                              <td>{reflections}</td>
                              {!isMobile && (
                                <>
                                  <td>{mindfulnesses}</td>
                                  <td>{actionsCompleted}</td>
                                </>
                              )}
                            </tr>
                          )
                        )}
                        <tr>
                          <td>{t('Total')}</td>
                          <td>
                            {monthlyGraphs?.summary?.reduce(
                              (acc, { checkins }) => acc + checkins,
                              0
                            )}
                          </td>
                          <td>
                            {monthlyGraphs?.summary?.reduce(
                              (acc, { reflections }) => acc + reflections,
                              0
                            )}
                          </td>
                          {!isMobile && (
                            <>
                              <td>
                                {monthlyGraphs?.summary?.reduce(
                                  (acc, { mindfulnesses }) =>
                                    acc + mindfulnesses,
                                  0
                                )}
                              </td>
                              <td>
                                {monthlyGraphs?.summary?.reduce(
                                  (
                                    acc,
                                    { checkins, reflections, mindfulnesses }
                                  ) =>
                                    acc +
                                    checkins +
                                    reflections +
                                    mindfulnesses,
                                  0
                                )}
                              </td>
                            </>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <SummaryTab>
                      <span>
                        +
                        {monthlyGraphs?.summary?.reduce(
                          (acc, { actionsCompleted }) => acc + actionsCompleted,
                          0
                        )}
                      </span>
                      <span>{t('Actions completed')}</span>
                    </SummaryTab>
                  </SummaryData>
                ) : (
                  <SummaryData>
                    <SummaryTab>
                      <span>{t('Check ins')}</span>
                      <span>{monthlyGraphs?.summary?.checkins}</span>
                    </SummaryTab>
                    <SummaryTab>
                      <span>{t('Reflections')}</span>
                      <span>{monthlyGraphs?.summary?.reflections}</span>
                    </SummaryTab>
                    <SummaryTab>
                      <span>{t('Mindfulness')}</span>
                      <span>{monthlyGraphs?.summary?.mindfulnesses}</span>
                    </SummaryTab>
                    <SummaryTab>
                      <span>+{monthlyGraphs?.summary?.actionsCompleted}</span>
                      <span>{t('Actions completed')}</span>
                    </SummaryTab>
                  </SummaryData>
                )}
              </CardBody>
            )}
          </Card>
        </SingleContentWrapper>
        <MultipleContentWrapper monthly>
          <Card Area="energy">
            <CardTitle>
              {t('Average daily energy')}:{' '}
              <strong>
                {isLocked ? '' : monthlyGraphs?.avgDailyEnergy?.average || 0}
              </strong>
            </CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody chartContainer>
                <AverageDailyChart
                  data={monthlyGraphs?.avgDailyEnergy?.checkins || []}
                  month={monthFilter?.text?.substring(0, 3) ?? ''}
                />
              </CardBody>
            )}
          </Card>

          <Card Area="time">
            <CardTitle>{t('Time of Check In')}</CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody chartContainer>
                <TimeOfCheckIn data={monthlyGraphs?.timeOfCheckIns || []} />
              </CardBody>
            )}
          </Card>
        </MultipleContentWrapper>
        <MultipleContentWrapper monthly>
          <Card Area="cloud">
            <CardTitle>{t('Word cloud')}</CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody chartContainer>
                <WordCloud data={monthlyGraphs?.wordCloud || []} />
              </CardBody>
            )}
          </Card>
          <Card Area="categorized">
            <CardTitle>{t('Words categorized')}</CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody chartContainer>
                <WordCloudBar data={monthlyGraphs?.wordsCategorized || []} />
              </CardBody>
            )}
          </Card>
        </MultipleContentWrapper>
        <SingleContentWrapper>
          <FilterWrapper showFilterTitle>
            <MonthTitle>{t('Weekly Report')}</MonthTitle>
            <Select
              items={weekFilterOptions || []}
              onSelect={setWeek}
              label={t('Filter by week')}
              placeholder={t('Select a week')}
              ref={weekSelectRef}
              width="100%"
            />
          </FilterWrapper>
        </SingleContentWrapper>
        <MultipleContentWrapper>
          <Card>
            <CardTitle>{t('Summary')}</CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody>
                <SummaryWeekList>
                  <li>
                    <span>
                      {t('Total')} # {t('of')} {t('achievements.check ins')}:
                    </span>
                    <span>{weeklyGraphs?.summary?.checkins || 0}</span>
                  </li>
                  <li>
                    <span>
                      {t('Total')} # {t('of')} {t('achievements.reflections')}:
                    </span>
                    <span>{weeklyGraphs?.summary?.reflections || 0}</span>
                  </li>
                  <li>
                    <span>
                      {t('Total')} # {t('of')} {t('Mindfulness').toLowerCase()}{' '}
                      {t('Exercises').toLowerCase()}:
                    </span>
                    <span>{weeklyGraphs?.summary?.mindfulnesses || 0}</span>
                  </li>
                  <li>
                    <span>{t('messages.New actions completed')}:</span>
                    <span>{weeklyGraphs?.summary?.actionsCompleted || 0}</span>
                  </li>
                </SummaryWeekList>
              </CardBody>
            )}
          </Card>
          <Card>
            <CardTitle>
              {t('Average energy')}:{' '}
              <strong>
                {isLocked ? '' : weeklyGraphs?.avgDailyEnergy?.average || 0}
              </strong>
            </CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody chartContainer>
                <AverageDailyChart
                  data={weeklyGraphs?.avgDailyEnergy?.checkins || []}
                  colors={weeklyGraphs?.avgDailyEnergy?.barColors}
                  month={weekFilter?.current?.text}
                  week
                />
              </CardBody>
            )}
          </Card>
          <Card>
            <CardTitle>{t('Word cloud')}</CardTitle>
            {isLocked ? (
              <LockedCardBody chartContainer>
                <img src={LockedIcon} alt="locked" />
                <span>{lockedMessage}</span>
              </LockedCardBody>
            ) : (
              <CardBody>
                <WordCloud data={weeklyGraphs?.wordCloud || []} />
              </CardBody>
            )}
          </Card>
        </MultipleContentWrapper>
      </PageWrapper>
    </>
  );
};

GroupInsights.propTypes = {
  services: PropTypes.object.isRequired
};

export default withServices(GroupInsights);
