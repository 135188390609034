import React, { Fragment } from 'react';
import {
  Box,
  Text,
  Flex,
  IconButton,
  Avatar,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  VStack,
  MenuDivider
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { CustomIcon } from '../CustomIcon';
import logo from '../../../assets/svg/logo_white.svg';
import { CustomHeaderProps } from '../../../types/components';
import { useUserData } from '../../../contexts/UserDataContext';
import { AVATAR_LINKS } from '../../../constants/navigation';
import { HEADER_HEIGHT } from '../../../constants/components';

const Header: React.FC<CustomHeaderProps> = ({ onToggleSidebar }) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { push } = useHistory();
  // @ts-ignore
  const { userData } = useUserData();

  return (
    <Box
      height={HEADER_HEIGHT}
      position="fixed"
      top={0}
      left={0}
      right={0}
      as="header"
      bg="brand.primary"
      px={4}
      py={2}
      display="flex"
      zIndex={999}
    >
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <IconButton
            aria-label="Open navigation menu"
            variant="ghost"
            icon={<CustomIcon icon={faBars} size={24} color="White" />}
            onClick={onToggleSidebar}
            _hover={{
              opacity: 0.5
            }}
            cursor="pointer"
            zIndex={2000}
          />
          <Box ml={4} display="flex" alignItems="center" gap={4}>
            <Image
              src={logo}
              width={116}
              height="auto"
              cursor="pointer"
              onClick={() => push('/')}
            />
            <Text fontSize="m" color="neutral.0">
              {userData?.group}
            </Text>
          </Box>
        </Flex>

        <Menu>
          <MenuButton
            as={IconButton}
            icon={
              <Avatar
                size="sm"
                bg="brand.secondary"
                name={userData?.name}
                src={userData?.avatar}
              />
            }
            aria-label="Open settings menu"
            variant="ghost"
            color="white"
            cursor="pointer"
            _hover={{
              opacity: 0.5
            }}
            _active={{
              opacity: 0.8
            }}
          />
          <MenuList>
            <VStack
              display={{ base: 'none', md: 'flex' }}
              alignItems="flex-start"
              spacing="1"
              ml="3"
            >
              <Text fontSize="sm" fontWeight="bold" color="neutral.1100">
                {userData?.name}
              </Text>
              <Text fontSize="xs" color="neutral.1100">
                {userData?.groupName}
              </Text>
            </VStack>
            <MenuDivider />
            {AVATAR_LINKS(push).map((item, index) => (
              <Fragment key={`MenuItem-${index}`}>
                <MenuItem onClick={item.action}>
                  <CustomIcon
                    icon={item.icon}
                    size={20}
                    color="Black"
                    text={t(item.title)}
                    textProps={{
                      ml: 3,
                      color: 'neutral.1100'
                    }}
                  />
                </MenuItem>
                {AVATAR_LINKS(push).length - 1 > index ? <MenuDivider /> : null}
              </Fragment>
            ))}
            <MenuDivider />
            <MenuItem onClick={() => logout()}>
              <Text
                fontWeight="400"
                fontSize="sm"
                color="feedback.negative.100"
              >
                {t('Logout')}
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

export { Header };
