import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import PageContainer from '../../components/PageContainer';
import { useUserData } from '../../contexts/UserDataContext';
import { LANGUAGES } from '../../constants/language';
import { UserSettingsTypes } from '../../helpers/user';
import mutations from '../../services/mutations/User';
import {
  WizardWrapper,
  ContainerCard,
  Title,
  Info,
  Picture,
  Data,
  Select
} from './style';
import { useContent } from '../../contexts/ContentContext';

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { userData, refetch } = useUserData();
  const { refetchContent } = useContent();

  const [createUserSettings] = useMutation(mutations.CREATE_USER_SETTINGS);

  const [updateUserSettings] = useMutation(mutations.UPDATE_USER_SETTINGS);

  const handleOnChangeLanguage = async (languageCode) => {
    const hasUserSettingsWeb = userData?.userSettings.find(
      (setting) =>
        setting.type === UserSettingsTypes.PREFERENCES &&
        setting.platform === 'web'
    );

    if (!hasUserSettingsWeb) {
      await createUserSettings({
        variables: {
          input: {
            type: UserSettingsTypes.PREFERENCES,
            active: true,
            platform: 'web',
            meta: [
              {
                name: 'language',
                value: languageCode,
                createdAt: new Date(),
                updatedAt: new Date()
              }
            ]
          }
        }
      });
    } else {
      const previousLanguageUserSettings = hasUserSettingsWeb.meta.find(
        (item) => item.name === 'language'
      );

      await updateUserSettings({
        variables: {
          input: {
            id: hasUserSettingsWeb.id,
            active: true,
            meta: [
              ...hasUserSettingsWeb.meta.filter(
                (item) => item.name !== 'language'
              ),
              {
                ...previousLanguageUserSettings,
                value: languageCode,
                updatedAt: new Date()
              }
            ]
          }
        }
      });
    }

    i18n.changeLanguage(languageCode, async () => {
      await refetch();
      await refetchContent();
    });
  };

  return (
    <WizardWrapper>
      <PageContainer>
        <ContainerCard>
          <Title>{t('My Profile')}</Title>
          <Info>
            <Picture
              src={userData.picture || userData.avatar}
              alt="Image of my profile"
            />
            <Data>
              <p>
                <strong>{t('Name')}:</strong> {userData.name}
              </p>
              <p>
                <strong>{t('Email')}:</strong> {userData.email}
              </p>
              <p>
                <strong>{t('Timezone')}:</strong> {userData.timeZone}
              </p>
              <div>
                <strong>{t('Language')}:</strong>
                <Select
                  defaultValue={LANGUAGES[i18n.resolvedLanguage]}
                  items={Object.values(LANGUAGES)}
                  onSelect={({ value }) => handleOnChangeLanguage(value)}
                  shouldValidate={false}
                  width="100%"
                  style={{ marginTop: 8 }}
                />
              </div>
            </Data>
          </Info>
        </ContainerCard>
      </PageContainer>
    </WizardWrapper>
  );
};

export { Profile };
