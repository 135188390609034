import React from 'react';
import PropTypes from 'prop-types';
import { buildStyles } from 'react-circular-progressbar';

import { formatDate } from '../../helpers/date';
import { getWeatherIconName, getWeatherName } from '../../services/weather';
import '../../styles/progressCircleStyle.css';
import {
  Container,
  CircleProgress,
  CircleSection,
  WordWeatherWrapper,
  Word,
  TimeLine,
  WeatherLine,
  WeatherImage,
  PhraseWrapper,
  ActionsWrapper,
  Delete,
  Edit
} from './style';

import penSRC from '../../assets/svg/pen.svg';
import trashSRC from '../../assets/svg/trash.svg';

import cloudMoonRainSRC from '../../assets/svg/weather/cloud-moon-rain.svg';
import cloudSunRainSRC from '../../assets/svg/weather/cloud-sun-rain.svg';
import cloudsSRC from '../../assets/svg/weather/clouds.svg';
import cloudsMoonSRC from '../../assets/svg/weather/clouds-moon.svg';
import fogSRC from '../../assets/svg/weather/fog.svg';
import moonSRC from '../../assets/svg/weather/moon.svg';
import smokeSRC from '../../assets/svg/weather/smoke.svg';
import snowflakesSRC from '../../assets/svg/weather/snowflakes.svg';
import subDustSRC from '../../assets/svg/weather/sub-dust.svg';
import sunHazeSRC from '../../assets/svg/weather/sun-haze.svg';
import sunSRC from '../../assets/svg/weather/sun.svg';
import tornadoSRC from '../../assets/svg/weather/tornado.svg';

const svgs = {
  'cloud-moon-rain': cloudMoonRainSRC,
  'cloud-sun-rain': cloudSunRainSRC,
  clouds: cloudsSRC,
  'clouds-moon': cloudsMoonSRC,
  fog: fogSRC,
  moon: moonSRC,
  smoke: smokeSRC,
  snowflakes: snowflakesSRC,
  'sub-dust': subDustSRC,
  'sun-haze': sunHazeSRC,
  sun: sunSRC,
  tornado: tornadoSRC
};

const CheckInCard = ({
  energyLevel,
  feelingWord,
  feelingContext,
  createdDate,
  weather,
  actions,
  deleteAction,
  editAction
}) => {
  const weatherName = weather
    ? getWeatherName(weather.weatherId, weather.weatherIcon)
    : null;
  const weatherIconName = weather
    ? getWeatherIconName(weather.weatherId, weather.weatherIcon)
    : null;

  return (
    <Container>
      <CircleSection>
        <CircleProgress
          styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '35px'
          })}
          strokeWidth={18}
          counterClockwise
          value={energyLevel}
          text={String(energyLevel)}
          maxValue={10}
          minValue={0}
        />
      </CircleSection>
      {weather && (
        <WordWeatherWrapper>
          <Word>{feelingWord}</Word>
          <TimeLine>{formatDate(createdDate)}</TimeLine>
          <WeatherLine>
            {weatherIconName && (
              <WeatherImage
                alt={weatherName || 'weather-image'}
                src={svgs[weatherIconName]}
              />
            )}
            {weatherName}
          </WeatherLine>
        </WordWeatherWrapper>
      )}
      <PhraseWrapper>{feelingContext}</PhraseWrapper>
      {actions && (
        <ActionsWrapper>
          <Delete
            onClick={deleteAction}
            alt="A trash bucket icon"
            src={trashSRC}
          />
          <Edit onClick={editAction} alt="A pen icon" src={penSRC} />
        </ActionsWrapper>
      )}
    </Container>
  );
};

CheckInCard.defaultProps = {
  actions: false,
  weather: null,
  deleteAction: () => {},
  editAction: () => {}
};

CheckInCard.propTypes = {
  createdDate: PropTypes.string.isRequired,
  energyLevel: PropTypes.number.isRequired,
  feelingWord: PropTypes.string.isRequired,
  feelingContext: PropTypes.string.isRequired,
  deleteAction: PropTypes.func,
  editAction: PropTypes.func,
  actions: PropTypes.bool,
  weather: PropTypes.shape({
    weatherId: PropTypes.string,
    weatherIcon: PropTypes.string
  })
};

export { CheckInCard };
