import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 15px;
  font-weight: 700;
`;

export const Line = styled.hr``;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const Button = styled.button`
  border: 0;
  border-radius: 5px;
  padding: 12px;
  color: #fff;
  background-color: ${({ color }) => color};
`;
