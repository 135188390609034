// TODO: Group these types better :)

export interface Insights {
  __typename: string;
  group: Group;
  subGroups: SubGroupLegacy[];
}

export interface Group {
  __typename?: string;
  name: string;
}

export interface SubGroup {
  __typename?: string;
  name: string;
  reflections: Mindfulnes[];
  mindfulnesses: Mindfulnes[];
  checkins: CheckIn[];
}

/**
 * @deprecated The method should not be used in favor of SubGroup
 */
export interface SubGroupLegacy {
  __typename?: string;
  name: string;
  reflections: Reflection[];
  mindfulness?: Mindfulnes[];
  mindfulnesses?: Mindfulnes[];
  checkIns?: CheckIn[];
  checkins?: CheckIn[];
}

export interface Mindfulnes extends BaseEntity {
  completedDate: string;
}
export interface Reflection extends BaseEntity {
  completedDate: string;
}
export interface CheckIn extends BaseEntity {
  energyLevel: number;
  feelingWord: string;
  feelingContext?: string;
  owner?: Owner;
  createdDate: string;
}

export interface Owner {
  __typename?: string;
  id: string;
}

export interface BaseEntity {
  __typename: string;
  id: string;
}

// Words
export interface WordCategories {
  [x: string]: string[];
}
export interface WordColorCategories {
  [x: string]: string;
}

export type CheckinsByTimeOfDay = {
  // eslint-disable-next-line no-unused-vars
  [_x in PeriodOfDay]: MorningOrMidDayOrEvening;
};

export interface MorningOrMidDayOrEvening {
  checkins: CheckIn[];
  avg: number;
  percentage: number;
}

// eslint-disable-next-line no-shadow
export enum PeriodOfDay {
  // eslint-disable-next-line no-unused-vars
  Morning = 'morning',
  // eslint-disable-next-line no-unused-vars
  MidDay = 'midDay',
  // eslint-disable-next-line no-unused-vars
  Evening = 'evening'
}

export interface DaysFilter {
  value: string;
  month: number;
  year: number;
  days: number;
  monthDisplayRange: string;
  text: string;
}

export interface WordFrequency {
  [x: string]: number;
}

export interface Word {
  feelingWord: string;
}
