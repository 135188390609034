import React, { lazy, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';

import { WBS_EXIT_KEY } from '../../../../../constants/exercise';

import { useUserData } from '../../../../../contexts/UserDataContext';

const Baseline = lazy(() => import('../../../../wellbeing-survey/baseline'));
const Exit = lazy(() => import('../../../../wellbeing-survey/exit'));

const ExerciseWbsBaseLineExit = ({
  url,
  disableSubmitSection,
  handleCurrentResponse
}) => {
  const { userData } = useUserData();

  let intervalID = 0;

  const userEmailMd5 = useMemo(
    () => md5(userData.emailFull),
    [userData.emailFull]
  );

  const isWbsExitExercise = Boolean(url.match(WBS_EXIT_KEY));

  useEffect(() => {
    intervalID = setInterval(() => {
      checkIfExerciseIsFinished();
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  function checkIfExerciseIsFinished() {
    const finished =
      document.getElementById('wbs-exit-completed') ||
      document.getElementById('wbs-baseline-completed');

    if (finished && disableSubmitSection) {
      clearInterval(intervalID);
      handleCurrentResponse({});
    }
  }

  // Check first if is exit, otherwise will be baseline
  if (isWbsExitExercise) return <Exit innerUserId={userEmailMd5} />;

  return <Baseline innerUserId={userEmailMd5} />;
};

ExerciseWbsBaseLineExit.propTypes = {
  url: PropTypes.string.isRequired,
  disableSubmitSection: PropTypes.bool.isRequired,
  handleCurrentResponse: PropTypes.func.isRequired
};

export { ExerciseWbsBaseLineExit };
