import styled, { keyframes } from 'styled-components';

export const colors = {
  Happy: '#cbd244',
  Neutral: '#77bc76',
  Sad: '#5288c0',
  Angry: '#11477f',
  Fearful: '#7d0688',
  Other: '#d8a649',
  Surprised: '#007e6c',
  Disgusted: '#f2845b'
};

export const fade = keyframes`
    from {
        opacity: .2;
    }
    to {
        opacity: 1;
    }
`;

const growColumn = keyframes`
  from {
    width: 0px;
  }
  to {
    width: var(--size);
  }
`;

export const ChartItem = styled.svg`
  font-size: 1.5rem;
  margin: 0 auto;
  animation: ${fade} 1s;
  /* --percentage: ${({ percentage }) => percentage || 69}; */
  > circle:nth-of-type(2) {
    stroke: #ebebeb;
  }

  > g {
    fill: ${({ color }) => color || 'lightblue'};
  }

  > g > text > tspan:first-of-type {
    font-size: 0.5em;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
    color: ${({ color }) => color || '#666'};
    fill: ${({ color }) => color || '#666'};
  }

  > g > text > tspan:last-of-type {
    font-size: 0.12em;
    line-height: 1;
    transform: translateY(0.5em);
    text-align: center;
    text-anchor: middle;
    color: ${({ color }) => color || '#666'};
    fill: ${({ color }) => color || '#666'};
  }
`;

export const ChartWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;

  > button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #f2845b;
    font-weight: bold;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  > ul {
    list-style-type: circle;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20%;
    gap: 5%;
    animation: ${fade} 1s;
    > li {
      font-weight: bold;
      width: 7vw;
      text-align: center;
    }
  }
`;

export const SelectList = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Title = styled.div`
  display: inline-block;
  position: relative;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.125rem;

  --size: ${({ active }) => (active ? 3 : 1)}vw;

  &::before {
    content: '';
    display: inline-block;
    background-color: ${({ Color }) => Color || '#ccc'};
    width: var(--size);
    height: max(100%, 1.125rem);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-right: 8px;
    transform: translateY(10%);
    animation: ${growColumn} 300ms ease-out;
  }
`;

export const Energy = styled.span`
  display: inline-block;
  font-size: 0.75rem;
  line-height: 0.75rem;
  align-self: flex-end;
`;
