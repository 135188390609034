import React from 'react';
import styled from 'styled-components';
import {
  Box,
  Avatar as InnerAvatar,
  TextField as InnerTextField
} from '@mui/material';
import {
  breakpoints,
  Button as InnerButton,
  colors,
  spacing
} from '@checkingin/component-library';
import { FontAwesomeIcon as InnerFontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1.5rem;
  margin: 0.625rem 0.625rem 8rem 0.625rem;
  background-color: ${colors.white.absolute};
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  width: 100%;
  height: fit-content;
  gap: 2rem;
  border: none;
  border-radius: 0.25rem;

  @media (min-width: ${breakpoints.tablet}px) {
    width: max-content;
    min-width: 50%;
    max-width: 50%;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
  background-color: #eef6f6;
  padding: 5rem 0rem;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  background-color: ${colors.white.absolute};
  width: 100%;
`;

export const ExercisePreviewContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #adaead;
  padding: 1rem;
  border-radius: 0.5rem;
  gap: 2rem;
  width: 80%;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 90%;
  }
`;

export const ExerciseTitle = styled.span`
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-weight: 700;
  text-align: center;
  line-height: 1.625rem;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
`;

export const FontAwesomeIcon = styled(InnerFontAwesomeIcon)`
  font-size: 1.75rem;
  color: ${colors.primary} !important;
`;

export const ThumbsIcon = styled(
  // eslint-disable-next-line no-unused-vars
  ({ marked, ...props }) => <InnerFontAwesomeIcon {...props} />
)`
  font-size: 3.438rem;
  color: ${({ marked }) =>
    marked ? `${colors.primary}` : '#838683'} !important;

  cursor: pointer;
`;

export const Avatar = styled(InnerAvatar)``;

export const RatingContainer = styled.div``;

export const RatingInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const BottomTitle = styled.span`
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-weight: 700;
  line-height: 1.625rem;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
`;

export const TextField = styled(InnerTextField)`
  width: 50%;

  @media (max-width: ${breakpoints.tablet}px) {
    width: 90%;
  }

  .MuiFormHelperText-root {
    text-align: right !important;
    margin-right: 0 !important;
  }
`;

export const Button = styled(InnerButton)`
  border-radius: 1.5rem !important;
  width: 90%;
  text-align: center;
  background-color: ${colors.primary};
  color: ${colors.white.absolute};
  padding: ${spacing.xs} ${spacing.xl};
  cursor: pointer;
  font-family: 'BCSans', sans-serif !important;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 40%;
  }
`;

export const TryPlusText = styled.span`
  text-align: center;
  font-size: 1rem;
  color: ${colors.primary};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  cursor: pointer;
`;
