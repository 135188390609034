export const WBS_BASELINE_KEY = 'https://wellbeing-survey.checkingin.co';

export const WBS_EXIT_KEY = 'https://wellbeing-survey.checkingin.co/exit';

export const REPORT_KEY =
  'https://api-wellbeing-survey.checkingin.co/report-integration';

export const RATING = {
  NONE: '-1',
  DOWN: '0',
  UP: '1'
};

export const STATUS = {
  upcoming: 'Upcoming',
  completed: 'Completed',
  new: 'New',
  inprogress: 'In Progress'
};

export const EXERCISE_COMMENT_MAX_LENGTH = 250;

export const EXERCISE_MODAL_CLASS_NAME = 'exercise-modal-confirmation';
