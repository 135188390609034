import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  faTimes,
  faChevronLeft,
  faDownload
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { ExerciseText } from './ExerciseText';
import { ExerciseMedia } from './ExerciseMedia';
import { usePrompt } from '../../../hooks/events';
import { ExerciseTextInput } from './ExerciseTextInput';
import { ExerciseSeparator } from './ExerciseSeparator';
import { ExerciseWbsReport } from './ExerciseWbs/Report';
import { ExerciseAlternative } from './ExerciseAlternative';
import { ExerciseWbsBaseLineExit } from './ExerciseWbs/BaseLineExit';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useContent } from '../../../contexts/ContentContext';
import { handleNavigationOnComplete } from '../../../helpers/exercise';
import contentHistoryGraph from '../../../services/graphql-queries/contentHistory';
import {
  RATING,
  REPORT_KEY,
  WBS_BASELINE_KEY,
  WBS_EXIT_KEY
} from '../../../constants/exercise';

import {
  Button,
  Container,
  FontAwesomeIcon,
  HeaderContainer,
  HeaderTitle,
  ProgressBar,
  ButtonText
} from './styles.ts';

const ExerciseContent = () => {
  const { t } = useTranslation();

  const { state } = useLocation();

  const { exercise, program, redirectedFrom } = state;

  const { push } = useHistory();
  const { exercises, addContentHistories } = useContent();

  const [currentSection, setCurrentSection] = useState(0);
  const [disableSubmitSection, setDisableSubmitSection] = useState(true);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [newResponses, setNewResponses] = useState([]);
  const [responsesLoaded, setResponsesLoaded] = useState(null);
  const [userCanLeave, setUserCanLeave] = useState(false);
  const [reportUrl, setReportUrl] = useState('');

  useEffect(() => {
    if (exercise?.responseData) {
      setResponsesLoaded(exercise?.responseData);
    }
  }, []);

  const Prompt = usePrompt({
    when: !userCanLeave,
    message: t('messages.Are you sure you want to leave this exercise')
  });

  const [saveContentHistory] = useMutation(
    contentHistoryGraph.SAVE_CONTENT_HISTORY,
    {
      onCompleted: async (data) => {
        await addContentHistories({
          exerciseContentHistories: [data.saveContentHistory],
          reloadFlags: true
        });
      }
    }
  );

  const contentType = exercise?.sections[currentSection]?.contentType;

  const isTypeSeparator = useMemo(
    () => contentType === 'separator',
    [contentType]
  );

  const checkLastSection = useMemo(
    () => currentSection + 1 === exercise?.sections?.length,
    [currentSection, exercise?.sections]
  );

  const handlePreviousSection = () => {
    setShowConfirmButton(false);
    setDisableSubmitSection(true);
    setCurrentSection(currentSection - 1);
  };

  const handleNextSection = () => {
    setShowConfirmButton(false);
    setDisableSubmitSection(true);
    setCurrentSection(currentSection + 1);
  };

  const handleQuitExercise = async () => {
    await saveContentHistory({
      variables: {
        params: {
          cmsId: exercise?.id,
          publicationRecordId: exercise?.publicationId,
          responseData: newResponses
        }
      }
    });

    let exerciseToRedirect = exercise;

    if (redirectedFrom?.exerciseId) {
      exerciseToRedirect = exercises.find(
        (item) =>
          item.id === redirectedFrom.exerciseId &&
          item.publicationId === redirectedFrom.publicationId
      );
    }

    if (!exerciseToRedirect?.exerciseDetails) {
      push(`/home`);

      return;
    }

    push(
      `/exercise/${exerciseToRedirect.id}?publicationId=${exerciseToRedirect.publicationId}`
    );
  };

  const handleSubmitSection = async () => {
    if (disableSubmitSection) {
      if (contentType === 'alternative') {
        toast.error(t('messages.Ensure that you select all expected answers'));
      } else {
        toast.error(t('messages.Ensure you fill in the required fields'));
      }

      return;
    }

    if (showConfirmButton) {
      setShowConfirmButton(false);
      setShowAnswer(true);
      return;
    }

    setDisableSubmitSection(true);

    let contentHistoryPayload = {
      cmsId: exercise.id,
      publicationRecordId: exercise.publicationId,
      responseData: newResponses
    };

    if (!checkLastSection) {
      await saveContentHistory({
        variables: {
          params: contentHistoryPayload
        }
      });

      handleNextSection();
      return;
    }

    setUserCanLeave(true);

    if (exercise?.autoComplete) {
      contentHistoryPayload = {
        ...contentHistoryPayload,
        rating: RATING.NONE,
        comment: ''
      };

      await saveContentHistory({
        variables: {
          params: contentHistoryPayload
        }
      });

      handleNavigationOnComplete({
        historyPush: push,
        program,
        exercise: { ...exercise, responseData: newResponses },
        redirectedFrom
      });
    } else {
      await saveContentHistory({
        variables: {
          params: contentHistoryPayload
        }
      });

      push(
        `/exercise/${exercise.id}/evaluation?publicationId=${exercise.publicationId}`,
        {
          exercise: { ...exercise, responseData: newResponses },
          program,
          redirectedFrom
        }
      );
    }
  };

  const handleCurrentResponse = useCallback(
    ({ response = '', disableSubmit = false, showConfirm = false }) => {
      if (showConfirm) {
        setShowConfirmButton(true);
        return;
      }

      let currentResponse = {
        sectionId: exercise?.sections[currentSection]?.id,
        sectionType: contentType
      };

      if (contentType === 'textInput') {
        currentResponse = {
          ...currentResponse,
          answerTextInput: response
        };
      }

      if (contentType === 'alternative') {
        currentResponse = {
          ...currentResponse,
          answerAlternative: response
        };
      }

      setShowAnswer(false);
      setDisableSubmitSection(disableSubmit);
      setNewResponses([
        ...newResponses.filter(
          (item) => item.sectionId !== currentResponse.sectionId
        ),
        currentResponse
      ]);
    },
    [currentSection, exercise]
  );

  const renderTextSection = (content, sectionId) => {
    const isWbsBaselineExercise =
      Boolean(content.text.match(WBS_BASELINE_KEY)) ||
      Boolean(content.text.match(WBS_EXIT_KEY));

    const isWbsReport = content.text.match(REPORT_KEY);

    if (isWbsBaselineExercise) {
      return (
        <ExerciseWbsBaseLineExit
          key={sectionId}
          url={content.text}
          disableSubmitSection={disableSubmitSection}
          handleCurrentResponse={handleCurrentResponse}
        />
      );
    }

    if (isWbsReport) {
      return (
        <ExerciseWbsReport
          key={sectionId}
          reportUrl={reportUrl}
          publicationId={exercise.publicationId}
          handleChangeReportUrl={setReportUrl}
          handleCurrentResponse={handleCurrentResponse}
        />
      );
    }

    return (
      <ExerciseText
        key={sectionId}
        content={content}
        exercise={exercise}
        handleCurrentResponse={handleCurrentResponse}
      />
    );
  };

  const renderSections = () => {
    const content = exercise.sections[currentSection].contentJson;
    const sectionId = exercise?.sections[currentSection].id;
    const sectionAnswered = responsesLoaded
      ? responsesLoaded.find((item) => item.sectionId === sectionId)
      : null;

    switch (contentType) {
      case 'text':
        return renderTextSection(content, sectionId, exercise);

      case 'separator':
        return (
          <ExerciseSeparator
            key={sectionId}
            content={content}
            handleCurrentResponse={handleCurrentResponse}
          />
        );

      case 'media':
        return (
          <ExerciseMedia
            exercise={exercise}
            key={sectionId}
            content={content}
            handleCurrentResponse={handleCurrentResponse}
            handleSubmitSection={handleSubmitSection}
          />
        );

      case 'textInput':
        return (
          <ExerciseTextInput
            key={sectionId}
            content={content}
            sectionId={sectionId}
            sectionAnswered={sectionAnswered}
            handleCurrentResponse={handleCurrentResponse}
          />
        );

      case 'alternative':
        return (
          <ExerciseAlternative
            key={sectionId}
            content={content}
            showAnswer={showAnswer}
            sectionAnswered={sectionAnswered}
            handleCurrentResponse={handleCurrentResponse}
          />
        );

      default:
        return null;
    }
  };

  const renderButtonText = () => {
    if (checkLastSection) return showConfirmButton ? t('Confirm') : t('Finish');

    return showConfirmButton ? t('Confirm') : t('Next');
  };

  const handleDownloadReport = () => {
    const link = document.createElement('a');
    link.href = reportUrl;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!exercise?.sections) return <LoadingSpinner wrapper />;

  return (
    <Container data-testid="content-container">
      <HeaderContainer>
        <div>
          {currentSection > 0 && (
            <FontAwesomeIcon
              icon={faChevronLeft}
              onClick={() => handlePreviousSection()}
            />
          )}
        </div>
        <HeaderTitle>{exercise?.title || ''}</HeaderTitle>
        <FontAwesomeIcon
          icon={reportUrl ? faDownload : faTimes}
          onClick={() =>
            reportUrl ? handleDownloadReport() : handleQuitExercise()
          }
          $isReport={reportUrl}
        />
      </HeaderContainer>
      {!isTypeSeparator && exercise?.sections.length > 1 && (
        <ProgressBar
          variant="determinate"
          value={(currentSection * 100) / exercise?.sections?.length}
        />
      )}
      {renderSections()}
      <Button
        onClick={() => handleSubmitSection()}
        disabled={disableSubmitSection}
      >
        <ButtonText>{renderButtonText()}</ButtonText>
      </Button>
      <Prompt />
    </Container>
  );
};

export { ExerciseContent };
