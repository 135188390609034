import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

/* TODO: merge This container and container list with src/pages/Profile/styles.js, src/pages/_404_/styles.js
src/pages/Program/styles.js, src/pages/Mindfulness/styles.js, and src/pages/ExerciseEvaluation/styles.js */
export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: flex-start;
  align-items: flex-start;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  background-color: ${colors.white.absolute};
  padding: 1rem;
  margin: 0.625rem 0.625rem 8rem 0.625rem;

  width: 100%;
  height: fit-content;
`;

export const Greeting = styled.div`
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: black;
`;

export const HeaderContent = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
  color: gray;
  padding-top: 1.4rem;
`;

export const ContentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  margin-top: 1rem;
  width: 100%;
`;

export const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: ${colors.white.absolute};
  border: none;
  border-radius: 0.625rem;
  width: 100%;
  height: 8rem;

  @media (min-width: 768px) {
    width: max-content;
    min-width: 30%;
    max-width: 30%;
  }

  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : ''};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  box-shadow: ${({ boxShadow }) =>
    boxShadow
      ? `inset 0px -50px 50px 25px ${boxShadow}`
      : 'inset 0px -50px 50px 25px rgba(10, 30, 31, 0.66)'};
`;
