import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { replaceHtmlDeepLink } from '../../../../helpers/replaceHtmlDeepLink';

import {
  Container,
  ContentContainer,
  DescriptionText,
  TitleContainer,
  TitleText,
  Divider,
  ThumbnailContainer,
  ThumbnailImage
} from './styles';

const ExerciseText = ({ content, handleCurrentResponse, exercise }) => {
  const [imagePreview, setImagePreview] = useState(content?.thumbnailUrl);

  useEffect(() => {
    handleCurrentResponse({});
  }, [content]);

  return (
    <Container>
      {content?.title && (
        <TitleContainer>
          <TitleText>{content?.title || ''}</TitleText>
        </TitleContainer>
      )}
      {imagePreview && (
        <ThumbnailContainer>
          <ThumbnailImage
            onError={() => setImagePreview(null)}
            src={imagePreview}
          />
        </ThumbnailContainer>
      )}
      {content?.description && (
        <>
          <DescriptionText
            dangerouslySetInnerHTML={{
              __html: content.description.replaceAll('\n', '<br/>')
            }}
          />
          <Divider />
        </>
      )}
      <ContentContainer
        dangerouslySetInnerHTML={{
          __html: replaceHtmlDeepLink(content?.text, exercise)
        }}
      />
    </Container>
  );
};

ExerciseText.defaultProps = {
  content: null,
  exercise: null
};

ExerciseText.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string
  }),
  exercise: PropTypes.shape({
    id: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    ),
    publicationId: PropTypes.string
  }),

  handleCurrentResponse: PropTypes.func.isRequired
};

export { ExerciseText };
