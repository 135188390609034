import styled from 'styled-components';

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff90;
  z-index: 2;
`;
