import {
  CheckIn,
  CheckinsByTimeOfDay,
  PeriodOfDay
} from '../../services/insights/types';
import { avgByKey } from '../../helpers/generics/';

export const getTimeOfCheckIns = (checkins: CheckIn[]): CheckinsByTimeOfDay => {
  function getDayPeriod(date: string) {
    const timeOfDay = parseInt(date.substring(11, 16).replace(':', ''), 10);
    if (timeOfDay >= 0 && timeOfDay < 1159) {
      return PeriodOfDay.Morning;
    }
    if (timeOfDay >= 1200 && timeOfDay < 1759) {
      return PeriodOfDay.MidDay;
    }

    return PeriodOfDay.Evening;
  }

  const checkinsByTimeOfDay = checkins.reduce<CheckinsByTimeOfDay>(
    (acc, cur) => {
      const period = getDayPeriod(cur.createdDate);
      const mergedCheckins = [...acc[period].checkins, cur];
      return {
        ...acc,
        [period]: {
          checkins: mergedCheckins,
          avg: avgByKey(mergedCheckins, (c) => c.energyLevel),
          percentage: parseFloat(
            ((mergedCheckins.length * 100) / checkins.length).toFixed(1)
          )
        }
      };
    },
    {
      [PeriodOfDay.Morning]: {
        checkins: [],
        avg: 0,
        percentage: 0
      },
      [PeriodOfDay.MidDay]: {
        checkins: [],
        avg: 0,
        percentage: 0
      },
      [PeriodOfDay.Evening]: {
        checkins: [],
        avg: 0,
        percentage: 0
      }
    }
  );

  return checkinsByTimeOfDay;
};
