import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import steps from './steps';

import { WizardWrapper, ContainerCard } from './style';

const Checkin = () => {
  const { state } = useLocation();

  const [wizardStep, setWizardStep] = useState(() => {
    if (state?.action === 'update') {
      return 1;
    }

    return 0;
  });

  const [checkinData, setCheckinData] = useState(state?.checkinData || {});

  const goNext = () => {
    if (wizardStep < steps.length - 1) {
      setWizardStep((prevState) => prevState + 1);
    }
  };
  const goPrevious = () => {
    if (wizardStep > 0) {
      setWizardStep((prevState) => prevState - 1);
    }
  };

  const Component = steps[wizardStep];

  return (
    <ContainerCard>
      <WizardWrapper data-testid="wiz-wrapper">
        {Component && (
          <Component
            checkinData={checkinData}
            setCheckinData={setCheckinData}
            goNext={goNext}
            goPrevious={goPrevious}
            wizardStep={wizardStep}
            stepSize={steps.length}
          />
        )}
      </WizardWrapper>
    </ContainerCard>
  );
};

export { Checkin };
