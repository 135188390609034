import styled from 'styled-components';

export const PdfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  gap: 10px;

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
`;

export const Controls = styled.div`
  z-index: 999;
  display: flex;
  text-align: center;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
`;
