import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { faCircle, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { Container, InnerContainer, Text, FontAwesomeIcon } from './styles';

library.add(faCircle, faCheck, faTimes, faCircleSolid);

const AlternativeItem = ({
  label,
  isExpected,
  index,
  saveAnswer,
  showAnswer,
  disabled,
  hasNoExpected,
  checked
}) => {
  const { t } = useTranslation();

  const result = useMemo(() => {
    if (showAnswer && hasNoExpected && checked) {
      if (isExpected) {
        return 'fa-check';
      }
      if (!isExpected) {
        return 'fa-times';
      }
    }

    return checked ? 'selected' : 'fa-circle';
  }, [showAnswer, hasNoExpected, checked, isExpected]);

  const isSelected =
    result === 'fa-circle' || result === 'selected' || !hasNoExpected;

  const iconName = isSelected ? 'fa-circle' : result;

  const iconFamilyWithName = `fa-${
    result === 'selected' ? 'solid' : 'regular'
  } ${iconName}`;

  const handleOnClickItem = () => {
    if (disabled)
      toast.error(
        t('messages.In order to select a new option, deselect another one')
      );
    else saveAnswer({ id: index, label, checked: !checked, isExpected });
  };

  return (
    <Container onClick={() => handleOnClickItem()}>
      <InnerContainer
        isCorrect={result === 'fa-check' && hasNoExpected}
        isIncorrect={result === 'fa-times' && hasNoExpected}
        disabled={disabled}
      >
        <FontAwesomeIcon
          icon={iconFamilyWithName}
          isSelected={result === 'selected'}
          isSelectedConfirm={
            result !== 'fa-circle' && result !== 'selected' && hasNoExpected
          }
        />
        <Text isSelected={isSelected} disabled={disabled}>
          {label}
        </Text>
      </InnerContainer>
    </Container>
  );
};

AlternativeItem.defaultProps = {
  isExpected: false
};

AlternativeItem.propTypes = {
  label: PropTypes.string.isRequired,
  isExpected: PropTypes.bool,
  index: PropTypes.number.isRequired,
  saveAnswer: PropTypes.func.isRequired,
  showAnswer: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  hasNoExpected: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired
};

export { AlternativeItem };
