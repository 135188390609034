import moment from 'moment';

export function sortByField(array, field) {
  return array.sort((a, b) => {
    const fa = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
    const fb = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];

    if (fa === fb) return 0;
    return fa < fb ? -1 : 1;
  });
}

export function removeDuplicates(arr) {
  const set = [...new Set(arr.map((item) => JSON.stringify(item)))];
  const uniqueValuesArray = set.map((s) => JSON.parse(s));
  return uniqueValuesArray;
}

export function getUserFeaturedExercise(exercises) {
  const today = moment();
  const currentDay = today.dayOfYear();
  const daysInYear = today.endOf('year').dayOfYear();

  const featuredIndex = Math.floor(
    ((exercises.length - 1) / daysInYear) * currentDay
  );

  return exercises[featuredIndex];
}

// export const isProgramLocked = (program, userHistory = []) => {
//   let isLockedByPreviousContent = false;

//   if (program.completedDate) {
//     return isLockedByPreviousContent;
//   }

//   if (program?.availableAfterIds && program.availableAfterIds.length > 0) {
//     if (!userHistory || userHistory.length === 0) {
//       isLockedByPreviousContent = true;
//     }

//     isLockedByPreviousContent = !program.availableAfterIds.every(
//       (afterExerciseId) =>
//         userHistory.some(
//           (historic) =>
//             historic.id === afterExerciseId && historic.completedDate
//         )
//     );
//   }

//   const isLockedByDate = !program.availableDate
//     ? false
//     : moment(program.availableDate.substring(0, 16))
//         .local()
//         .isAfter(moment().local().format());

//   return isLockedByDate || isLockedByPreviousContent;
// };
export const isProgramLocked = (status) => status === 'upcoming';

export const countNewExercises = (flags) =>
  flags?.filter((flag) => flag.flag === 'new').length;

export const loadPublicationsV2 = ({ publicationsAPI, publicationsJSON }) => {
  const publicationsLoaded = publicationsJSON.map((publicationData) => {
    const exercises = publicationData.data;

    return exercises.map((exercise) => {
      const exerciseFlags =
        publicationsAPI
          .find(
            (userPublication) =>
              userPublication.id === publicationData.publication.id
          )
          ?.flags?.find((flag) => flag.originId === exercise.id) || [];

      return {
        ...exercise,
        categories: [exercise.categories[0]],
        publicationId: publicationData.publication.id,
        publicationCreatedDate: publicationData.publication.createdDate,
        flags: exerciseFlags,
        flag: exerciseFlags?.flag || 'upcoming'
      };
    });
  });

  const exercisesLoaded = removeDuplicates(publicationsLoaded.flat());

  const programsLoaded = exercisesLoaded
    .reduce((acc, currentExercise) => {
      const { categories, publicationId, flags } = currentExercise;

      // eslint-disable-next-line no-param-reassign
      delete currentExercise.flags;

      categories.forEach((category) => {
        const index = acc.findIndex(
          (item) =>
            item.id === category?.id && item?.publicationId === publicationId
        );

        if (index === -1) {
          acc.push({
            ...category,
            publicationId,
            publicationCreatedDate: currentExercise.publicationCreatedDate,
            exercises: [
              {
                ...currentExercise
              }
            ],
            flags: [flags]
          });
        } else {
          acc[index].exercises.push({
            ...currentExercise
          });
          acc[index].flags.push(flags);
        }
      });

      return acc;
    }, [])
    .map((item) => {
      const categoryAuthors = item.exercises
        .map((exercise) => exercise.author)
        .flat();

      return {
        ...item,
        authors: categoryAuthors,
        newsCount: countNewExercises(item.flags)
      };
    });

  return {
    programsLoaded,
    exercisesLoaded
  };
};

export const loadPublicationsV3 = ({ publicationsAPI, publicationsJSON }) => {
  let publicationsLoaded = publicationsJSON.map((publicationData) => {
    const publicationFlags = publicationsAPI.find(
      (userPublication) => userPublication.id === publicationData.publication.id
    );

    const exercises = publicationData.data.content.map((exercise) => {
      const categories = publicationData.data.category.find(
        (item) => item.id === exercise?.categoryId?.[0]
      );

      const exerciseFlag =
        publicationFlags?.flags?.find((flag) => flag.originId === exercise.id)
          ?.flag || 'upcoming';

      return {
        ...exercise,
        categories: [categories],
        publicationId: publicationData.publication.id,
        flag: exerciseFlag
      };
    });

    const categories = publicationData.data.category
      .map((category) => {
        const categoryExercises = exercises.filter(
          (exercise) => exercise.categoryId[0] === category.id
        );

        const categoryAuthors = categoryExercises
          .map((exercise) => exercise.author)
          .flat();

        const categoryFlags = categoryExercises.map((exercise) => ({
          flag: exercise.flag
        }));

        return {
          ...category,
          republishedFrom: publicationData.data.publication?.republishedFrom,
          newsCount: countNewExercises(categoryFlags),
          exercises: categoryExercises,
          authors: categoryAuthors,
          publicationId: publicationData.publication.id,
          publicationCreatedDate: publicationData.publication.createdDate,
          flags: categoryFlags,
          accessRequests: publicationData.publication?.accessRequests
        };
      })
      .filter((category) => category.exercises.length > 0);

    return {
      exercises,
      categories
    };
  });

  publicationsLoaded = publicationsLoaded.filter((item) => !!item);

  const programsLoaded = publicationsLoaded
    .map((item) => item.categories)
    .flat();
  const exercisesLoaded = publicationsLoaded
    .map((item) => item.exercises)
    .flat();

  return { programsLoaded, exercisesLoaded };
};
