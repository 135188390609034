import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import defaultMediaImg from '../../../../../../assets/img/default-media-thumbnail.png';
import playCircleGreenIcon from '../../../../../../assets/svg/play-circle-green.svg';

import { PreviewImage } from '../styles';
import { PreviewContainer, PreviewIcon, PreviewIconContainer } from './styles';
import { MEDIA_TYPES } from '../../../../../../helpers/media';

const Preview = ({ file, autoPlay, defaultImage, handleOnPlay }) => {
  const { fileUrl, type, thumbnailUrl } = file;

  const [imagePreview, setImagePreview] = useState(
    thumbnailUrl || defaultImage
  );

  useEffect(() => {
    if (autoPlay) handleOnPlay();
  }, []);

  useEffect(() => {
    if (type === MEDIA_TYPES.image.value) setImagePreview(fileUrl);
  }, [file]);

  return (
    <PreviewContainer>
      <PreviewImage
        onError={() => setImagePreview(null)}
        src={imagePreview || defaultMediaImg}
      />
      {type !== MEDIA_TYPES.image.value && (
        <PreviewIconContainer>
          <PreviewIcon
            src={playCircleGreenIcon}
            onClick={() => handleOnPlay()}
          />
        </PreviewIconContainer>
      )}
    </PreviewContainer>
  );
};

Preview.defaultProps = {
  autoPlay: false
};

Preview.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string
  }).isRequired,
  autoPlay: PropTypes.bool,
  defaultImage: PropTypes.string.isRequired,
  handleOnPlay: PropTypes.func.isRequired
};

export { Preview };
