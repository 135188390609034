import { gql } from '@apollo/client';

const queries = {
  GET_TOKEN_VALIDATION: gql`
    query Query {
      validateToken
    }
  `
};

export default queries;
