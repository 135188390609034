export async function fetchContentJSON(url) {
  let data = [];

  try {
    const response = await fetch(url);
    data = await response.json();
  } catch (err) {
    console.log('Failed to fetch JSON', url);
  }

  return data;
}
