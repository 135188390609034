import { spacing } from './spacing';

const largeSizes = {
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem'
};

const container = {
  xs: '500px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1440px',
  '3xl': '1680px',
  '4xl': '1900px',
  full: '1920px'
};

export const sizes = {
  ...spacing,
  ...largeSizes,
  container
};

export type Sizes = typeof spacing &
  typeof largeSizes & { container: typeof container };
