import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { withUser } from '../../../../services/hoc/user_old';
import AfterCheckinMessage from '../../../../components/AfterCheckinMessage';
import CardHeader from '../../../../components/CardHeader';
import { CheckInCard } from '../../../../components/CheckInCard';
import CarrouselDots from '../../../../components/CarrouselDots';

import {
  StepContainer,
  CardBody,
  CardTextContainer,
  NextButton,
  CardTitle
} from './style';

const CongratsStep = ({
  user: { name },
  checkinData,
  goPrevious,
  wizardStep,
  stepSize
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <StepContainer>
      <CardHeader title={t("You've Checked In")} goPrevious={goPrevious} />
      <CardBody>
        <CardTextContainer>
          <CardTitle>
            {t('Congrats')} {name}!
          </CardTitle>
          <CheckInCard
            createdDate={checkinData.createdDate}
            energyLevel={checkinData.energyLevel || 0}
            feelingWord={checkinData.feelingWord || ''}
            feelingContext={checkinData.feelingContext || ''}
            weather={checkinData.weather}
          />
        </CardTextContainer>
      </CardBody>
      <AfterCheckinMessage fellingWord={checkinData.feelingWord} />
      <NextButton onClick={() => push('/history')}>
        {t('messages.View check in history')}
      </NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

CongratsStep.propTypes = {
  checkinData: PropTypes.shape({
    energyLevel: PropTypes.number,
    feelingWord: PropTypes.string,
    feelingContext: PropTypes.string,
    createdDate: PropTypes.string,
    weather: PropTypes.shape({
      weatherId: PropTypes.string,
      weatherIcon: PropTypes.string
    })
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  goPrevious: PropTypes.func.isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default withUser(CongratsStep);
