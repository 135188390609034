import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CardHeader from '../../components/CardHeader';

import {
  ContainerCard,
  CardBody,
  HardText,
  SexyLine,
  SimpleText,
  LinkText
} from './style';

const _404_ = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <ContainerCard>
      <CardHeader title="Oops!" />
      <CardBody>
        <HardText>{t('messages.Sorry we could not find that page')}</HardText>
        <SimpleText>
          {t('Are you trying to')}&nbsp;
          <LinkText onClick={() => push('/checkin')}>{t('check in')}</LinkText>
          &nbsp;?
        </SimpleText>
        <SimpleText>
          {t('Are you trying to')}&nbsp;
          <LinkText onClick={() => push('/history')}>
            {t('see your check ins')}
          </LinkText>
          &nbsp;?
        </SimpleText>
        {/* <SimpleText>
          {t('Are you trying to')}&nbsp;
          <LinkText
            onClick={() => {
              push('/invite');
            }}
          >
            {t('Invite a Friend').toLowerCase()}
          </LinkText>
          &nbsp;?
        </SimpleText> */}
        <SexyLine />
      </CardBody>
    </ContainerCard>
  );
};

export { _404_ };
