import { SubGroup, DaysFilter } from '../../services/insights/types';
import { getTimeOfCheckIns } from '../../services/insights/time';
import {
  getWordCloud,
  getWordsCategorized
} from '../../services/insights/words';
import { getAvgDailyEnergyData } from '../../services/insights/calcullations';

const getSummaryData = (data: SubGroup) => ({
  name: data.name,
  checkins: data?.checkins?.length || 0,
  reflections: data?.reflections?.length || 0,
  mindfulnesses: data?.mindfulnesses?.length || 0,
  actionsCompleted:
    data?.checkins?.length ||
    0 + data?.reflections?.length ||
    0 + data?.mindfulnesses?.length ||
    0
});

const getMonthGraphs = (
  data: SubGroup,
  filter: DaysFilter,
  _isGroup: boolean
) => ({
  summary: getSummaryData(data),
  avgDailyEnergy: getAvgDailyEnergyData(data.checkins || [], filter.days),
  timeOfCheckIns: getTimeOfCheckIns(data.checkins || []),
  wordCloud: getWordCloud(data.checkins || []),
  wordsCategorized: getWordsCategorized(data.checkins || [])
});

export { getMonthGraphs, getSummaryData, getWordCloud };
