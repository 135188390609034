import { gql } from '@apollo/client';

const graph = {
  GET_HISTORY_WITH_PAGINATION: gql`
    query historyWithPagination($params: ParamsHistoryWithPagination!) {
      historyWithPagination(params: $params) {
        countCheckIn
        countContentHistory
        results {
          id

          cmsId
          publicationRecordId
          rating
          comment
          viewDate
          completedDate
          responseData {
            sectionId
            sectionType
            answerTextInput
            answerAlternative
          }
          contentExercise {
            id
            title
            backgroundImage
            exerciseDetails
            autoComplete
            showInHistory
          }

          createdDate
          energyLevel
          feelingWord
          feelingContext
          weather {
            id
            temp
            weatherId
            weatherIcon
            weatherMain
          }
        }
      }
    }
  `,
  GET_CONTENT_HISTORIES: gql`
    query contentHistories($params: ParamsContentHistories) {
      contentHistories(params: $params) {
        id
        cmsId
        publicationRecordId
        rating
        comment
        viewDate
        completedDate
        responseData {
          sectionId
          sectionType
          answerTextInput
          answerAlternative
        }
      }
    }
  `,
  GET_CONTENT_HISTORY_RESPONSE: gql`
    query contentHistoryResponse($params: ParamsContentHistoryResponse!) {
      contentHistoryResponse(params: $params) {
        id
        cmsId
        publicationRecordId
        rating
        comment
        viewDate
        completedDate
        responseData {
          sectionId
          sectionType
          answerTextInput
          answerAlternative
        }
      }
    }
  `,
  SAVE_CONTENT_HISTORY: gql`
    mutation saveContentHistory($params: SaveContentHistoryRequest) {
      saveContentHistory(params: $params) {
        id
        cmsId
        publicationRecordId
        rating
        comment
        viewDate
        completedDate
        responseData {
          sectionId
          sectionType
          answerTextInput
          answerAlternative
        }
      }
    }
  `
};

export default graph;
