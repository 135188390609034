export const LANGUAGES = {
  en: {
    text: 'English',
    value: 'en'
  },
  fr: {
    text: 'French',
    value: 'fr'
  }
};
