const baseStyle = {
  w: '100%',
  mx: 'auto',
  maxW: 'container.full',
  px: {
    base: 4,
    sm: 8
  }
};

export default {
  baseStyle
} as const;
