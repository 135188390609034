import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

export const WizardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 80vh;
`;

export const ContainerCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${colors.white.absolute};
  background-clip: border-box;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  text-align: center;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 60rem;
  min-height: 80vh;
  margin: 0.625rem 0.625rem 8rem 0.625rem;
`;
