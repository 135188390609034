import styled, { css } from 'styled-components';

const wrapperModifier = {
  wrapper: () => css`
    width: 100vw;
    height: 100vh;
  `
};

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  ${({ wrapper }) => css`
    ${wrapper && wrapperModifier.wrapper()}
  `}
`;

export const SpinnerIMG = styled.img`
  width: 15vh;
`;

export const SpinnerText = styled.span`
  margin-top: 15px;
  color: #51a3a4;
  font-size: 20px;
  font-weight: 700;
`;
