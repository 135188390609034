import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import md5 from 'md5';

import { wbsService } from '../../../../../services/wbsService';
import { useUserData } from '../../../../../contexts/UserDataContext';

import LoadingSpinner from '../../../../../components/LoadingSpinner';

import { ErrorContainer, ErrorText, LinkText, Object, Text } from './styles';

const ExerciseWbsReport = ({
  reportUrl,
  publicationId,
  handleChangeReportUrl,
  handleCurrentResponse
}) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { userData } = useUserData();

  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const [retry, setRetry] = useState(5); // We are going to try 5 times to generate the report

  const userEmailMd5 = useMemo(
    () => md5(userData.emailFull),
    [userData.emailFull]
  );

  const generateReportFile = async () => {
    try {
      const token = await getAccessTokenSilently();

      const result = await wbsService.post('report-generator', {
        uid: userEmailMd5,
        tk: token,
        publicationId
      });

      handleChangeReportUrl(result.data.file);
      setDownloadAvailable(true);
      handleCurrentResponse({});
    } catch (err) {
      setDownloadAvailable(false);
      handleChangeReportUrl(null);

      setRetry((prevState) => (prevState === 0 ? 0 : prevState - 1));

      if (retry === 0) return; // Avoiding infinite looping.

      setTimeout(() => {
        handleChangeReportUrl(''); // For showing the loading screen
        generateReportFile();
      }, 1000);
    }
  };

  useEffect(() => {
    if (userEmailMd5.length > 0 && publicationId.length > 0) {
      generateReportFile();
    }
  }, [userEmailMd5, publicationId]);

  if (!downloadAvailable && reportUrl === null) {
    return (
      <ErrorContainer>
        <ErrorText>
          {t(
            'messages.The report is not available at the moment Please try again later'
          )}
        </ErrorText>
      </ErrorContainer>
    );
  }

  if (reportUrl === '') return <LoadingSpinner wrapper />;

  return (
    <Object data={reportUrl}>
      <Text>
        {t(
          'messages.It appears your browser doesnt support this file Its not a problem you can'
        )}{' '}
        <LinkText href={reportUrl}>
          {t('messages.click here to download the file')}
        </LinkText>
        .
      </Text>
    </Object>
  );
};

ExerciseWbsReport.propTypes = {
  reportUrl: PropTypes.string.isRequired,
  publicationId: PropTypes.string.isRequired,
  handleChangeReportUrl: PropTypes.func.isRequired,
  handleCurrentResponse: PropTypes.func.isRequired
};

export { ExerciseWbsReport };
