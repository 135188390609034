import { gql } from '@apollo/client';

const queries = {
  GET_MINDFULNESSES: gql`
    query getMindfulnesses {
      mindfulnesses {
        id
        rating
        comment
        cmsId
        completedDate
        contentExercise {
          id
          title
          backgroundImage
          exerciseDetails
          autoComplete
          showInHistory
        }
      }
    }
  `
};

export default queries;
