import { InMemoryCache } from '@apollo/client';

import { fellingWords } from './fields/fellingWords';
import { afterCheckinMessage } from './fields/afterCheckinMessage';

export const createCache = () => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          fellingWords,
          afterCheckinMessage
        }
      }
    }
  });

  return cache;
};
