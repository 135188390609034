/**
 * Helpers and more helpers!
 *
 *
 * TODO: Most of these helpers are actually hardcoded implementations of already solved generalizations,
 * we must get ride of them in favor of more generic solutions to not have to maintain this codebase
 * Examples of this refactor can be found in avgByKey and groupBy files.
 */

export const getPeriod = ({ year, month }) => {
  let currentDate = new Date();

  if (year && month) {
    currentDate = new Date(year, parseInt(month, 10) - 1);
  }

  const currentPeriod = currentDate.toLocaleDateString('en-US');
  const { lastDate } = getLastDateFromMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  return {
    start: currentPeriod.replace(/\/\d+\//, '/01/'),
    end: currentPeriod.replace(/\/\d+\//, `/${lastDate}/`)
  };
};

const getLastDateFromMonth = (year, monthIndex) => {
  const date = new Date(year, monthIndex);
  const lastDate = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  return {
    displayMonth: {
      short: date.toLocaleDateString('en-US', { month: 'short' }),
      long: date.toLocaleDateString('en-US', { month: 'long' })
    },
    lastDate
  };
};

export const getSubGroupsFilterOptions = (insightsData) => {
  const subGroups = insightsData?.[0]?.subGroups;
  return subGroups.reduce((acc, cur, i) => {
    if (i === 0) {
      return [
        { text: 'All Teams', value: i },
        {
          text: cur.name,
          value: i + 1
        }
      ];
    }

    return [
      ...acc,
      {
        text: cur.name,
        value: i + 1
      }
    ];
  }, []);
};

export const processSubGroupFilter = (
  insightsData,
  tab,
  tabIndexes,
  subGroupId
) => {
  let subGroupsOptions = [];

  if (tab !== 'user') {
    const subGroups =
      insightsData.insights?.[
        tabIndexes[tab] || tabIndexes[Object.keys(tabIndexes)[0]]
      ]?.subGroups;
    // tabIndexes[tab]
    // const subGroupDefault = tabIndexes[Object.keys(tabIndexes)[0]];

    subGroupsOptions = subGroups.reduce((acc, cur, i) => {
      if (i === 0) {
        return [
          { text: 'All Teams', value: i },
          {
            text: cur.name,
            value: i + 1
          }
        ];
      }

      return [
        ...acc,
        {
          text: cur.name,
          value: i + 1
        }
      ];
    }, []);
  }

  const selectedSubGroupData = !subGroupId
    ? subGroupsOptions[0]
    : subGroupsOptions.find(({ value }) => value === subGroupId);

  return { subGroupsOptions, selectedSubGroupData };
};

export const applySubGroupFilter = (data, selectedSubGroupData) => {
  if (!selectedSubGroupData?.value) {
    return data?.subGroups;
  }

  // TODO: We should implement this search by name as soon as we move this guy to typescript
  // I believe that index search may not be applicable if we reapply the filter more than once, the subset of data may never have the other indexes.
  return data.subGroups.filter((_, i) => i + 1 === selectedSubGroupData.value);
};

/**
 * This function should merge all subgroups of a GraphQL response.
 *
 * FIXME: This function should be replaced by a keyNormalizer or at least be a wrapper of one
 * @param data
 * @returns { checkins: [], reflections: [], mindfulnesses: [] }
 * TODO: This function considers only mindfulnesses and checkIns, which means it should only be used in the context of a GraphQL call.
 */
export const mergeAllSubGroups = (data) =>
  data.reduce(
    (acc, cur) => ({
      ...acc,
      checkins: [...acc.checkins, ...cur.checkIns],
      reflections: [...acc.reflections, ...cur.reflections],
      mindfulnesses: [...acc.mindfulnesses, ...cur.mindfulness]
    }),
    { checkins: [], reflections: [], mindfulnesses: [] }
  );

// const getWeekText = (date) =>
//   date.toLocaleDateString('en-US', {
//     month: 'short',
//     year: 'numeric',
//     day: 'numeric'
//   });

// const getWeekValue = (date) =>
//   `${date.getMonth() + 1}${date.getDate()}${date.getFullYear()}`;

// const getWeekFilterOptions = (month, year) => {
//   const dateStartedAtFirstSunday = getFirtSundayOfMonth(month, year);
//   const intervals = [];

//   while (dateStartedAtFirstSunday.getMonth() + 1 === parseInt(month, 10)) {
//     const interval = { text: '', value: '', startTime: 0, endTime: 0 };

//     interval.text = getWeekText(dateStartedAtFirstSunday);
//     interval.startTime = dateStartedAtFirstSunday.getTime();
//     interval.value = getWeekValue(dateStartedAtFirstSunday);

//     dateStartedAtFirstSunday.setDate(dateStartedAtFirstSunday.getDate() + 7);

//     interval.text += ` - ${getWeekText(dateStartedAtFirstSunday)}`;
//     interval.endTime = dateStartedAtFirstSunday.getTime();
//     interval.value += `-${getWeekValue(dateStartedAtFirstSunday)}`;

//     intervals.push(interval);
//   }

//   return intervals.reverse();
// };

const weekFilter = (data = [], filter, dateKey) =>
  data.filter((item) => {
    const itemDate = item[dateKey]?.split('T');

    if (itemDate) {
      const currentTime = new Date(itemDate[0]).getTime();

      if (currentTime >= filter.start && currentTime <= filter.end) {
        return true;
      }
    }

    return false;
  });

const applyWeekFilter = (data = [], filter) => {
  const currentFilter = {
    start: filter?.startTime,
    end: filter?.endTime
  };

  return {
    checkins: weekFilter(data.checkins, currentFilter, 'createdDate'),
    reflections: weekFilter(data.reflections, currentFilter, 'completedDate'),
    mindfulnesses: weekFilter(
      data.mindfulnesses,
      currentFilter,
      'completedDate'
    )
  };
};

const getPriorWeekFilter = (selectedFilter, allWeekFilters) => {
  let selectedIndex = -1;

  allWeekFilters.find((filter, i) => {
    if (filter.id === selectedFilter.id) {
      selectedIndex = i + 1;
      return true;
    }
    return false;
  });

  return allWeekFilters[selectedIndex];
};

export const getWeekFilteredData = (
  data = [],
  currentfilter,
  allWeekFilters
) => {
  const priorWeekFilter = getPriorWeekFilter(currentfilter, allWeekFilters);

  return {
    priorWeekFilteredData: applyWeekFilter(data, priorWeekFilter),
    currentWeekFilteredData: applyWeekFilter(data, currentfilter)
  };
};
