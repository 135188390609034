export const COLORS = {
  primary: '#3269FF',
  secondary: '#31728C',
  black: {
    400: '#1E1E1E',
    500: '#000000'
  },
  white: {
    500: '#FFFFFF'
  },
  forgotPassword: {
    topBackgroundColor: '#e3e6f1',
    topTextColor: '#3269FF',
    pageBackgroundColor: '#040922'
  },
  gray: {
    500: '#666666',
    1000: '#888888'
  }
};

export const IMAGES = {
  forgotPassword: {
    logo: '/forgot-password/Stzuminus-Logo.png'
  }
};

export const FORGOT_PASSWORD = {
  imageHeight: '62px',
  imageWidth: '100px'
};
