import React from 'react';
import styled from 'styled-components';
import InnerStepLabel from '@mui/material/StepLabel';

export const StepLabel = styled(
  // eslint-disable-next-line no-unused-vars
  ({ isDone, ...props }) => <InnerStepLabel {...props} />
)`
  color: ${({ isDone }) => (isDone ? '#07AB71' : '#353535')};
`;
