import { gql } from '@apollo/client';

const graph = {
  GET_MINDFULNESSES: gql`
    query GetMindfulnesses {
      mindfulnesses {
        id
        cmsId
        owner {
          id
          name
        }
        rating
        comment
        completedDate
      }
    }
  `,
  SAVE_MINDFULNESS_EXERCISE_EVALUATION: gql`
    mutation SaveMindfulness($params: SaveMindfulnessRequest) {
      saveMindfulness(params: $params) {
        id
        rating
        comment
      }
    }
  `
};

export default graph;
