import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getFromSession, saveOnSession } from '../../../../helpers/session';
import { withUser } from '../../../../services/hoc/user_old';
import CardHeader from '../../../../components/CardHeader';
import CarrouselDots from '../../../../components/CarrouselDots';
import TextInput from '../../../../components/TextInput';
import TextAreaInput from '../../../../components/TextAreaInput';
import WordExplorer from '../../../../components/WordExplorer';
import {
  StepContainer,
  CardBody,
  CardTextContainer,
  NextButton,
  CallingText,
  TextInputWrapper,
  PraseCharCount
} from './style';

// hooks and queries
import {
  useAddCheckin,
  useUpdateCheckin
} from '../../../../services/mutations/checkin';
import {
  useUser,
  useUpdateFirstName,
  SaveCheckin
} from '../../../../services/mutations/user/index';
import { useCreateWeather } from '../../../../services/mutations/weather';
import { EXERCISE_COMMENT_MAX_LENGTH } from '../../../../constants/exercise';

const WordStep = ({
  checkinData,
  setCheckinData,
  goNext,
  goPrevious,
  wizardStep,
  stepSize,
  user
}) => {
  const { t } = useTranslation();
  const [{ time_zone: timezone, latitude, longitude }] = useState(user.geoip);

  const query = useUser(timezone);

  const [addCheckIn] = useAddCheckin();
  const [updateCheckIn] = useUpdateCheckin();
  const [createWeather] = useCreateWeather();
  const { updateName } = useUpdateFirstName();

  const [isSavingCheckin, setIsSavingCheckin] = useState(false);
  const [feelingWord, setFeelingWord] = useState(
    checkinData?.feelingWord || ''
  );
  const [feelingContext, setFeelingContext] = useState(
    checkinData?.feelingContext || ''
  );

  const inputRef = useRef();

  useEffect(() => {
    if (!query.loading && query.data) {
      try {
        if (
          !getFromSession('CheckingIn.username') &&
          !query.data.user.name &&
          user.name
        ) {
          updateName(user.name);
        }
        saveOnSession('CheckingIn.username', user.name);
      } catch (err) {
        console.error('ERR: ', err);
      }
    }
  }, [query]);

  useEffect(() => {
    setCheckinData({ ...checkinData, feelingWord: feelingWord || '' });
  }, [feelingWord]);

  useEffect(() => {
    setCheckinData({ ...checkinData, feelingContext: feelingContext || '' });
  }, [feelingContext]);

  const handleWordSelect = (wordSelected) => {
    const newWords = feelingWord
      ? `${feelingWord}, ${wordSelected}`
      : wordSelected;
    setFeelingWord(newWords);
    inputRef.current.value(newWords);
  };

  const handleCheckIn = async (e) => {
    e.preventDefault();
    setIsSavingCheckin(true);

    const checkin = {
      energyLevel: checkinData.energyLevel,
      feelingWord,
      feelingContext
    };

    let mutation = addCheckIn;
    if (checkinData.id) {
      checkin.id = checkinData.id;
      mutation = updateCheckIn;
    }

    let result = {};
    const promise = SaveCheckin(mutation, createWeather, {
      checkin,
      latitude,
      longitude,
      timezone
    });

    await toast.promise(promise, {
      loading: `${t('Saving')}...`,
      success: (data) => {
        result = data;

        if (checkin.id) {
          return t('messages.Your CheckingIn was updated with success');
        }

        return t('messages.Your CheckingIn was saved with success');
      },
      error: (error) => {
        result = error;

        if (checkin.id) {
          return t(
            'messages.Your CheckingIn could not be updated Please try again'
          );
        }

        return t(
          'messages.Your CheckingIn could not be saved Please try again'
        );
      }
    });

    setIsSavingCheckin(false);

    if ('data' in result) {
      if (result.mutation === 'update') return goNext();

      let firstWord = checkin.feelingWord;
      if (firstWord.length > 0) {
        [firstWord] = firstWord.split(',');
      }
      checkin.firstWord = firstWord;
      checkin.weather = result.data.weather;
      checkin.createdDate = result.data.createdDate;
      setCheckinData(() => checkin);
    }
    if ('error' in result) {
      console.error('err: ', result);
    } else {
      goNext();
    }
    return null;
  };

  return (
    <StepContainer>
      <CardHeader title={t("What's your word")} goPrevious={goPrevious} />
      <CardBody>
        <CardTextContainer>
          <CallingText marginTop="1rem">
            {t('messages.What word best describes how youre feeling right now')}
          </CallingText>
          <TextInputWrapper marginBottom="2rem">
            <TextInput
              ref={inputRef}
              defaultValue={feelingWord}
              onChange={setFeelingWord}
              placeholder={t('Enter one word')}
            />
          </TextInputWrapper>
          <WordExplorer onSelect={handleWordSelect} />

          <CallingText marginTop="3rem">
            {t('messages.Want to elaborate optional')}
          </CallingText>
          <TextInputWrapper>
            <TextAreaInput
              placeholder={t('messages.Why are you feeling that way')}
              onChange={setFeelingContext}
              defaultValue={feelingContext}
              rows={4}
            />
          </TextInputWrapper>
          <PraseCharCount>
            {feelingContext?.length || 0}/{EXERCISE_COMMENT_MAX_LENGTH}
          </PraseCharCount>
        </CardTextContainer>
      </CardBody>
      <NextButton
        disabled={!feelingWord || isSavingCheckin}
        onClick={handleCheckIn}
      >
        {t('Next')}
      </NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

WordStep.propTypes = {
  checkinData: PropTypes.shape({
    energyLevel: PropTypes.number,
    feelingWord: PropTypes.string,
    feelingContext: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  setCheckinData: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    geoip: PropTypes.object
  }).isRequired,
  goNext: PropTypes.func.isRequired,
  goPrevious: PropTypes.func.isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default withUser(WordStep);
