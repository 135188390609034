import React from 'react';
import PropTypes from 'prop-types';
import { faChevronLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Container, Title } from './styles';

import { FontAwesomeIcon } from '../../pages/Exercise/ExerciseContent/styles';

const CardHeader = ({ goPrevious, title, closeHeader }) => (
  <Container>
    {goPrevious && (
      <FontAwesomeIcon
        icon={faChevronLeft}
        style={{
          fontSize: '1.2rem',
          marginLeft: '1rem'
        }}
        onClick={goPrevious}
      />
    )}
    <Title style={{ flex: '1 1 100%' }}>{title}</Title>
    {closeHeader && (
      <FontAwesomeIcon
        icon={faTimes}
        style={{
          fontSize: '1.2rem',
          marginLeft: '1rem'
        }}
        onClick={closeHeader}
      />
    )}
  </Container>
);

CardHeader.defaultProps = {
  goPrevious: null,
  closeHeader: null
};

CardHeader.propTypes = {
  goPrevious: PropTypes.func,
  title: PropTypes.string.isRequired,
  closeHeader: PropTypes.func
};

export default CardHeader;
