import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon as InnerFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints, colors } from '@checkingin/component-library';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  padding: 1rem;
  margin-top: 1rem;
  border-radius: 20px;
  background-color: #f7f7f7;
  cursor: pointer;

  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }

  ${({ isCorrect }) =>
    isCorrect &&
    css`
      background-color: #05875a;
    `}

  ${({ isIncorrect }) =>
    isIncorrect &&
    css`
      background-color: #e32451;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  color: ${({ isSelected }) =>
    isSelected ? '#636463' : `${colors.white.absolute}`};
  margin-left: 0.625rem;
  font-size: 1rem;
  line-height: 1.375rem;

  ${({ isSelected, disabled }) =>
    isSelected &&
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const FontAwesomeIcon = styled(
  // eslint-disable-next-line no-unused-vars
  ({ isSelected, isSelectedConfirm, ...props }) => (
    <InnerFontAwesomeIcon {...props} />
  )
)`
  font-size: 1.625rem;

  ${({ isSelected, isSelectedConfirm }) =>
    isSelected && !isSelectedConfirm && `color: ${colors.primary};`}

  ${({ isSelected, isSelectedConfirm }) =>
    !isSelected && !isSelectedConfirm && 'color: hsl(120, 1%, 52%);'}

  ${({ isSelectedConfirm }) =>
    isSelectedConfirm && `color: ${colors.white.absolute};`}
`;
