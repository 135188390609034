import React, { useState, memo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const MyResponsiveBar = ({ data, month, colors, week }) => {
  const { t } = useTranslation();

  const [fill] = useState(
    week
      ? [
          {
            match: {
              id: 'current'
            },
            id: 'gradientEnergy'
          },
          {
            match: {
              id: 'prior'
            },
            id: 'priorColor'
          }
        ]
      : [
          {
            match: {
              id: 'Energy'
            },
            id: 'gradientEnergy'
          }
        ]
  );
  return (
    <ResponsiveBar
      data={data}
      keys={week ? ['current', 'prior'] : ['Energy']}
      fill={fill}
      indexBy={week ? 'dayOfWeek' : 'day'}
      margin={{
        top: 50,
        right: 60,
        bottom: 50,
        left: 60
      }}
      minValue={0}
      maxValue={10}
      groupMode="grouped"
      colors={colors || { scheme: 'nivo' }}
      defs={[
        {
          id: 'gradientEnergy',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: '#E6B92A' },
            { offset: 100, color: '#E78164' }
          ]
        },
        {
          id: 'priorColor',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: '#ecebeb' },
            { offset: 100, color: '#bcbcbc' }
          ]
        }
      ]}
      borderColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Energy Level'),
        legendPosition: 'middle',
        legendOffset: -40
      }}
      axisBottom={{
        tickValues: week
          ? data.map(({ dayOfWeek }) => dayOfWeek)
          : data
              ?.map(({ day }) => (day % 2 === 0 ? day : false))
              .filter(Boolean) || [],
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: week ? month : `${month} ${t('Day').toLowerCase()}`,
        legendPosition: 'middle',
        legendOffset: 40
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor="#FFF"
      animate={false}
      motionStiffness={90}
      motionDamping={15}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: '#ffffff',
              strokeWidth: 0,
              fill: 'none'
            }
          }
        },
        grid: {
          line: {
            stroke: '#dddddd',
            strokeWidth: 1,
            fill: 'none'
          }
        }
      }}
    />
  );
};

MyResponsiveBar.defaultProps = {
  week: false,
  colors: null,
  month: ''
};

MyResponsiveBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  month: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  week: PropTypes.bool
};

export default memo(MyResponsiveBar);
