import { HttpLink, ApolloLink, concat } from '@apollo/client';

import { getFromSession } from '../../helpers/session';

export const createLink = () => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: getFromSession('CheckingIn.token') || ''
      }
    });

    return forward(operation);
  });

  return concat(authMiddleware, httpLink);
};

export const createMachineLink = (token) => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'ci-m-authorization': token || ''
      }
    });

    return forward(operation);
  });

  return concat(authMiddleware, httpLink);
};
