import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import moment from 'moment';

import { fetchContentJSON } from '../services/fetchContentJSON';
import { DaysSinceDate } from '../helpers/date';
import { useWaitForDependentRequests } from './links';
import graph from '../services/graphql-queries/checkin';

export function useCurrentCheckIns(
  options = {
    lastXDays: 0,
    reloadData: false,
    selectedFilter: 0,
    dateRange: ''
  }
) {
  const { loading, data, error } = useQuery(graph.GET_CHECKINS, {
    fetchPolicy: options.reloadData ? 'cache-and-network' : 'cache-first'
  });
  const staleData = useWaitForDependentRequests(data);

  const lastXDays = options && options.lastXDays;
  const selectedFilter = options && options.selectedFilter;
  const dateRange = options && options.dateRange;
  const checkIns = useMemo(() => {
    const allCheckIns = get(staleData, 'checkIns', []);
    if (allCheckIns.length > 0 && (lastXDays === 7 || lastXDays === 30)) {
      if (selectedFilter === 0) {
        return allCheckIns.filter(
          (item) => DaysSinceDate(item.createdDate) < lastXDays
        );
      }
    } else if (allCheckIns.length > 0 && selectedFilter === 1) {
      const startDate = moment(dateRange).subtract(7, 'days');
      return allCheckIns.filter((item) =>
        moment(item.createdDate).isBetween(startDate, dateRange)
      );
    } else if (allCheckIns.length > 0 && selectedFilter === 2) {
      return allCheckIns.filter((item) =>
        moment(item.createdDate).isBetween(
          moment(dateRange).startOf('month'),
          moment(dateRange).endOf('month')
        )
      );
    } else if (allCheckIns.length > 0 && selectedFilter === 3) {
      return allCheckIns.filter((item) =>
        moment(item.createdDate).isBetween(
          moment(dateRange).startOf('year'),
          moment(dateRange).endOf('year')
        )
      );
    }

    return allCheckIns;
  }, [lastXDays, staleData, selectedFilter]); //eslint-disable-line

  return { loading, checkIns, error };
}

export function useHasCheckedInToday() {
  const { loading, checkIns } = useCurrentCheckIns();

  const lastCheckInDate =
    checkIns &&
    checkIns[0] &&
    checkIns[0].createdDate &&
    new Date(new Date(checkIns[0].createdDate).setHours(0, 0, 0, 0)).getTime();
  let hasCheckedInToday = false;
  if (lastCheckInDate) {
    const daysSinceLastCheckIn = moment().utc().diff(lastCheckInDate, 'days');
    hasCheckedInToday = daysSinceLastCheckIn <= 0;
  }
  return { loading, hasCheckedInToday };
}

export function useTodaysGreetingMessage(hasCheckedInToday) {
  const { t } = useTranslation();
  const messagesUrl = t('static.messages');
  const { json: messages } = fetchContentJSON(messagesUrl, true);
  const [message, setMessage] = useState('');

  const getTodaysMessage = async (msgs) => {
    if (msgs) {
      return (
        msgs[Math.floor(Math.random() * msgs.length)] || t('Ready to check-in')
      );
    }
    const defaultMessage = t("It's always a good time to check in");
    return defaultMessage;
  };

  useEffect(() => {
    if (hasCheckedInToday) {
      setMessage(
        `${t("You've checked in today")}\n${t('Feel free to add another')}`
      );
    } else {
      (async () => {
        const msg = await getTodaysMessage(messages);
        setMessage(msg);
      })();
    }
  }, [hasCheckedInToday, messages]);

  return message;
}
