import { useQuery } from '@apollo/client';
import { useRef } from 'react';
import get from 'lodash/get';

import graph from '../services/graphql-queries/links';

export function useWaitForDependentRequests(currentData) {
  const lastData = useRef();
  const { data } = useQuery(graph.GET_DEPENDENT_REQUESTS);

  if (get(data, 'dependentRequests', []).length) {
    return lastData.current;
  }

  lastData.current = currentData;
  return lastData.current;
}
