import { gql } from '@apollo/client';

const mutations = {
  RESET_PASSWORD: gql`
    mutation Mutation($params: ResetPasswordRequest!) {
      resetPassword(params: $params)
    }
  `
};

export default mutations;
