import styled from 'styled-components';

export const StyledInput = styled.input`
  -webkit-appearance: none;
  font-size: 1.2rem;
  outline: none;
  border: none;
  border-bottom: 2px solid #6b6b69;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.02);
  line-height: 2.5rem;
  transition: all 350ms ease-in-out;
  :focus {
    border-bottom: 2px solid #3d8284;
  }
`;
