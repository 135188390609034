import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Container,
  TitleContainer,
  DescriptionContainer,
  TitleText,
  DescriptionText,
  TextField
} from './styles';

const ExerciseTextInput = ({
  content,
  handleCurrentResponse,
  sectionId,
  sectionAnswered
}) => {
  const { t } = useTranslation();

  const inputRef = useRef(null);
  const maxInputSize = content?.inputMaxSize || null;

  useEffect(() => {
    if (sectionAnswered?.answerTextInput) {
      handleCurrentResponse({
        response: sectionAnswered?.answerTextInput || ''
      });
    }

    if (!content.isRequired && !sectionAnswered?.answerTextInput) {
      handleCurrentResponse({});
    }
  }, [content, sectionAnswered]);

  return (
    <Container>
      <TitleContainer>
        <TitleText>{content.title ?? ''}</TitleText>
      </TitleContainer>
      {content?.question && (
        <DescriptionContainer>
          <DescriptionText>{content.question ?? ''}</DescriptionText>
        </DescriptionContainer>
      )}

      <TextField
        inputRef={inputRef}
        label={t('Type here')}
        id={`answer-${sectionId}`}
        name={`answer-${sectionId}`}
        defaultValue={sectionAnswered?.answerTextInput || ''}
        onChange={(event) =>
          handleCurrentResponse({ response: event.target.value })
        }
        inputProps={{ maxLength: maxInputSize }}
        autoCapitalize="none"
        multiline
        autoFocus
        helperText={`${inputRef?.current?.value?.length || 0}/${
          maxInputSize || t('Unlimited')
        }`}
      />
    </Container>
  );
};

ExerciseTextInput.defaultProps = {
  sectionAnswered: {
    answerTextInput: '',
    answerAlternative: []
  }
};

ExerciseTextInput.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    question: PropTypes.string.isRequired,
    inputMaxSize: PropTypes.number.isRequired,
    isRequired: PropTypes.bool.isRequired
  }).isRequired,
  handleCurrentResponse: PropTypes.func.isRequired,
  sectionId: PropTypes.string.isRequired,
  sectionAnswered: PropTypes.shape({
    answerTextInput: PropTypes.string,
    answerAlternative: PropTypes.arrayOf(PropTypes.string)
  })
};

export { ExerciseTextInput };
