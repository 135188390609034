import { gql } from '@apollo/client';

const graph = {
  GET_DEPENDENT_REQUESTS: gql`
    {
      dependentRequests @client
    }
  `
};

export default graph;
