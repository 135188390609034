import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import defaultMediaImg from '../../../../../../assets/img/programs-button@3x.png';

import { MEDIA_TYPES } from '../../../../../../helpers/media';

import {
  PreviewImage,
  TopContainer,
  TopLeftContainer,
  TopRightContainer,
  TextType,
  Text,
  FileIcon,
  Container,
  ContainerIcons
} from '../styles';

const MediaItem = ({
  file,
  iconsTypes,
  isDownloaded,
  isDownloading,
  autoPlay,
  isPreview,
  subscribeSectionTitle,
  defaultImage,
  handleOnPlay,
  handleOnDownload
}) => {
  const { t } = useTranslation();

  const { text, type, thumbnailUrl } = file;

  const [imagePreview, setImagePreview] = useState(
    thumbnailUrl || defaultImage
  );

  const iconText = useMemo(() => {
    switch (type) {
      case MEDIA_TYPES.video.value:
        return t('Play');
      case MEDIA_TYPES.audio.value:
        return t('Play');
      case MEDIA_TYPES.link.value:
        return t('wordsClassifications.Open');
      default:
        return t('View');
    }
  }, [text]);

  const previewImageSource = useMemo(() => {
    if (isPreview) return imagePreview || defaultMediaImg;

    return imagePreview;
  }, [isPreview, imagePreview]);

  const titleText = subscribeSectionTitle || text;

  const hasImage = imagePreview || isPreview;

  useEffect(() => {
    if (isPreview && autoPlay) handleOnPlay();
  }, []);

  return (
    <Container>
      <TopContainer datatype="top-container">
        {hasImage && (
          <TopLeftContainer datatype="top-left-container">
            <PreviewImage
              onError={() => setImagePreview(null)}
              src={previewImageSource}
            />
          </TopLeftContainer>
        )}

        <TopRightContainer hasImage={hasImage} datatype="top-right-container">
          <TextType>{MEDIA_TYPES[file.type]?.text}</TextType>
          <Text>{titleText}</Text>
        </TopRightContainer>
      </TopContainer>
      <ContainerIcons isUnique={iconsTypes[type]?.length}>
        {iconsTypes[type].map((iconType, index) => (
          <FileIcon
            key={`FileIcon-${index}`}
            iconType={iconType}
            isDownloaded={isDownloaded}
            isDownloading={isDownloading}
            text={iconText}
            hasBorder={index + 1 !== iconsTypes[type]?.length && isDownloaded}
            onClick={() =>
              iconType?.isDownload ? handleOnDownload() : handleOnPlay()
            }
          />
        ))}
      </ContainerIcons>
    </Container>
  );
};

MediaItem.defaultProps = {
  autoPlay: false,
  isPreview: false,
  subscribeSectionTitle: ''
};

MediaItem.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string
  }).isRequired,
  iconsTypes: PropTypes.object.isRequired,
  isDownloaded: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  autoPlay: PropTypes.bool,
  isPreview: PropTypes.bool,
  subscribeSectionTitle: PropTypes.string,
  defaultImage: PropTypes.string.isRequired,
  handleOnPlay: PropTypes.func.isRequired,
  handleOnDownload: PropTypes.func.isRequired
};

export { MediaItem };
