import { gql } from '@apollo/client';

const mutations = {
  CREATE_USER: gql`
    mutation CreateUser($user: CreateUserRequest!) {
      createUser(user: $user) {
        id
        name
        email
      }
    }
  `,
  DELETE_USER: gql`
    mutation DeleteUser($user: DeleteUserRequest!) {
      deleteUser(user: $user) {
        id
        name
        email
      }
    }
  `,
  UPDATE_USER: gql`
    mutation UpdateUser($user: UpdateUserRequest!) {
      updateUser(user: $user) {
        id
        name
        email
      }
    }
  `,
  CREATE_USER_SETTINGS: gql`
    mutation CreateUserSetting($input: CreateUserSettingRequest) {
      createUserSetting(params: $input) {
        id
        active
        meta
        type
      }
    }
  `,
  GET_USER_SETTINGS: gql`
    query GetUserSettings($input: UserSettingFilterInput) {
      userSetting(params: $input) {
        id
        meta
        active
        type
        platform
      }
    }
  `,
  UPDATE_USER_SETTINGS: gql`
    mutation UpdateUserSetting($input: UpdateUserSettingRequest!) {
      updateUserSetting(params: $input) {
        id
        active
        meta
        type
      }
    }
  `
};
export default mutations;
