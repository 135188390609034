import styled from 'styled-components';
import { Box } from '@mui/material';
import { colors, breakpoints } from '@checkingin/component-library';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: ${colors.white.absolute};
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);

  border-radius: 8px;

  gap: 1rem;

  @media (max-width: ${breakpoints.tablet}px) {
    width: 90%;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1.5rem 2rem;
  gap: 1rem;
`;

export const TitleContainer = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-style: normal;
  font-weight: 700;
  font-size: 1.063rem;
  line-height: 1.4375rem;

  color: #636463;
`;

export const DescriptionContainer = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1.3125rem;

  color: #636463;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #999999;
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  border-bottom-left-radius: ${({ isCancel }) => (isCancel ? `8px` : `0px`)};
  border-bottom-right-radius: ${({ isCancel }) => (isCancel ? `0px` : `8px`)};
  cursor: pointer;

  background-color: ${({ isCancel }) =>
    isCancel ? `${colors.system.red}` : `${colors.white.absolute}`};
`;

export const ButtonText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-style: normal;
  font-weight: 400;
  font-size: 1.063rem;
  line-height: 1.4375rem;
  text-align: center;

  color: ${({ isCancel }) =>
    isCancel ? `${colors.white.absolute}` : `#636463`};
`;
