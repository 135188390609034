import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';
import { Auth0Provider } from '@auth0/auth0-react';
import { ApolloProvider } from '@apollo/client/react';
import { confirmAlert } from 'react-confirm-alert';

import { UserDataProvider } from './contexts/UserDataContext';
import { ContentProvider } from './contexts/ContentContext';
import { useTheme } from './contexts/ThemeContext';
import { ExerciseModal } from './components/Modal/ExerciseModal';
import Embed from './pages/Embed';
import { EXERCISE_MODAL_CLASS_NAME } from './constants/exercise';
import { ForgotPassword } from './pages/Auth/ForgotPassword';
import { Deeplink } from './pages/Deeplink';
import { getClient } from './services/apollo-client';
import { Pages } from './pages';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENTID,
  AUTH0_DOMAIN
} from './constants/auth0';
import theme from './theme';

const client = getClient();

const isSignUp =
  !!window?.location?.search?.toLowerCase().includes('signup') ||
  !!window?.location?.pathname?.toLowerCase().includes('signup');

const email = window?.location?.search?.split('email=')[1];

function AppRoutes() {
  const { currentTheme } = useTheme();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme[currentTheme]}>
        <Switch>
          <Route component={() => <Embed />} path="/pdf" exact />
          <Route path="/deeplink" component={() => <Deeplink />} exact />

          <Route
            component={() => (
              <>
                <Toaster position="top-right" />
                <ForgotPassword />
              </>
            )}
            path="/forgot-password/:token/:slug/:email"
            exact
          />

          <Route
            component={() => (
              <>
                <Auth0Provider
                  domain={AUTH0_DOMAIN}
                  audience={AUTH0_AUDIENCE}
                  clientId={AUTH0_CLIENTID}
                  redirectUri={window.location.origin}
                  display={isSignUp ? 'signUp' : 'login'}
                  login_hint={isSignUp ? email : undefined}
                >
                  <ApolloProvider client={client}>
                    <UserDataProvider>
                      <ContentProvider>
                        <BrowserRouter
                          getUserConfirmation={(message, callback) => {
                            confirmAlert({
                              customUI: ({ onClose }) => (
                                <ExerciseModal
                                  message={message}
                                  onClose={onClose}
                                  callback={callback}
                                />
                              ),
                              overlayClassName: EXERCISE_MODAL_CLASS_NAME
                            });
                          }}
                        >
                          <Toaster
                            position="top-right"
                            containerStyle={{ marginTop: '56px' }}
                          />
                          <Pages />
                        </BrowserRouter>
                      </ContentProvider>
                    </UserDataProvider>
                  </ApolloProvider>
                </Auth0Provider>
              </>
            )}
            path="/"
          />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export { AppRoutes };
