import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

export const Container = styled.div`
  flex: 1;
  display: flex;

  background-color: ${colors.primary};
  border-radius: 0.5rem;
  min-height: 14.563rem;
  width: 100%;
`;

export const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 2rem;
  gap: 3rem;
`;

export const TextTitle = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: ${colors.white.absolute};
  text-align: center;
`;

export const TextDescription = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${colors.white.absolute};
  text-align: center;
`;
