import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withUser } from '../../../../services/hoc/user_old';

import CardHeader from '../../../../components/CardHeader';
import Loading from '../../../../components/SimpleLoading';
import CarrouselDots from '../../../../components/CarrouselDots';
import {
  StepContainer,
  CardBody,
  CardTextContainer,
  CardTitle,
  CardText,
  NextButton
} from './style';
import {
  useHasCheckedInToday,
  useTodaysGreetingMessage
} from '../../../../hooks/checkins';

const WelcomeStep = ({ goNext, wizardStep, stepSize, user }) => {
  const { t } = useTranslation();
  const { loading, hasCheckedInToday } = useHasCheckedInToday();
  const greetingMessage = useTodaysGreetingMessage(hasCheckedInToday);

  if (loading) {
    return <Loading />;
  }

  return (
    <StepContainer>
      <CardHeader title={t('Welcome')} />
      <CardBody>
        <CardTextContainer>
          <CardTitle>
            {t('Hey')} {user && user.name ? user.name : '______'}!
          </CardTitle>
          <CardText>{greetingMessage}</CardText>
          <CardText>
            {t('messages.Lets walk you through your check in')}
          </CardText>
        </CardTextContainer>
      </CardBody>
      <NextButton onClick={goNext}>{t('Next')}</NextButton>
      <CarrouselDots currentStep={wizardStep} stepsSize={stepSize} />
    </StepContainer>
  );
};

WelcomeStep.propTypes = {
  goNext: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  wizardStep: PropTypes.number.isRequired,
  stepSize: PropTypes.number.isRequired
};

export default withUser(WelcomeStep);
