import styled from 'styled-components';
import { Divider as InnerDivider } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  gap: 1.5rem;
  padding: 0rem 1.25rem;
  width: 100%;
`;

export const TitleContainer = styled.div``;

export const TitleText = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  color: hsl(120, 1%, 39%);
  text-align: center;
`;

export const ThumbnailContainer = styled.div``;

export const ThumbnailImage = styled.img.attrs({
  alt: 'Thumbnail image'
})`
  width: 100%;
  height: 100%;
  max-height: 14.563rem;
  max-width: 25.875rem;
  border-radius: 0.5rem;
`;

export const DescriptionText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bcsans};
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
`;

export const ContentContainer = styled.div`
  padding: 1rem;
`;

export const Divider = styled(InnerDivider)`
  width: 100%;
`;
