import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  CardTitle,
  Container,
  ContentOverlay,
  Duration,
  InnerContainer,
  SubTextWrapper
} from './styles';
import { formatCalenderDayWithoutTime } from '../../helpers/date';
import ContentLabel from '../ContentLabel';
import programsImg from '../../assets/img/programs-button@3x.png';
import { countNewExercises, isProgramLocked } from '../../helpers/programs';
import { EMPTY_AUTHOR } from '../../pages/Exercise/ExerciseDetailsInfo';

const ExerciseItemList = ({ item, shouldHideNewsWithoutCount, onPress }) => {
  const source = { uri: item?.backgroundImage || item?.imageUrl };
  const formattedCompleteDate = item?.availableDate
    ? formatCalenderDayWithoutTime(item.availableDate)
    : undefined;

  const isLocked = useMemo(() => isProgramLocked(item.flag), [item.flag]);

  const newsCount = useMemo(
    () => countNewExercises(item?.flags) || null,
    [item?.flags]
  );

  return (
    <Container
      data-testid="program-exercise-item"
      backgroundImage={source.uri || programsImg}
      isLocked={isLocked}
      onClick={isLocked ? () => {} : onPress}
      disabled={isLocked}
    >
      <InnerContainer>
        <ContentOverlay>
          <CardTitle>{item.title}</CardTitle>
          {item?.author && item?.author?.name?.toLowerCase() !== EMPTY_AUTHOR && (
            <SubTextWrapper>
              <div>{item?.author?.name}</div>
            </SubTextWrapper>
          )}
          {isLocked && formattedCompleteDate && (
            <SubTextWrapper>
              <div>{formattedCompleteDate}</div>
            </SubTextWrapper>
          )}
        </ContentOverlay>
        {!isLocked && item?.duration && item?.duration !== '0' && (
          <Duration>{item.duration}</Duration>
        )}
      </InnerContainer>
      <ContentLabel
        status={item?.flag || 'new'}
        count={newsCount}
        shouldHideNewsWithoutCount={shouldHideNewsWithoutCount}
        position="over-top"
      />
    </Container>
  );
};

ExerciseItemList.defaultProps = {
  shouldHideNewsWithoutCount: false
};

ExerciseItemList.propTypes = {
  item: PropTypes.object.isRequired,
  shouldHideNewsWithoutCount: PropTypes.bool,
  onPress: PropTypes.func.isRequired
};

export default React.memo(ExerciseItemList);
