import { gql } from '@apollo/client';

const queries = {
  GET_REFLECTIONS: gql`
    query GetReflections {
      reflections {
        id
        cmsId
        unlockDate
        viewedDate
        completedDate
        comment
      }
    }
  `
};

export default queries;
