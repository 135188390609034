import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CheckSVG } from '../../assets/svg/check.svg';

import {
  Container,
  Title,
  TitleContainer,
  ContentContainer,
  GenerateButton
} from './styles';

const SurveyCard = ({
  title,
  finishedAt,
  startedAt,
  isDownloadAvailable,
  generateReportLink,
  reportUrl,
  daysLeft,
  assessmentLink
}) => {
  const renderSituation = () => {
    if (finishedAt) {
      return (
        <>
          <CheckSVG width="40" height="40" />
          <h4>Completed</h4>
          <p>{finishedAt}</p>
        </>
      );
    }

    if (startedAt) {
      return (
        <>
          <h4>Active</h4>
          <p>Continue</p>
        </>
      );
    }

    if (daysLeft) {
      if (daysLeft === -1) {
        return <h4>You need to take the baseline survey first.</h4>;
      }

      const daysString = daysLeft === 1 ? `tomorrow` : `in ${daysLeft} days`;

      return <h4>This survey will be available {daysString}</h4>;
    }

    if (title === 'Final Report') {
      return <h4>Will be available once both surveys are completed.</h4>;
    }

    return (
      <GenerateButton as="a" href={assessmentLink}>
        Take the survey
      </GenerateButton>
    );
  };

  const renderReportButton = () => {
    if (reportUrl) {
      return <a href={reportUrl}>Download Report</a>;
    }

    return (
      <GenerateButton type="button" onClick={generateReportLink}>
        Generate Now
      </GenerateButton>
    );
  };

  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <ContentContainer>
        {isDownloadAvailable ? renderReportButton() : renderSituation()}
      </ContentContainer>
    </Container>
  );
};

SurveyCard.defaultProps = {
  isDownloadAvailable: false,
  startedAt: '',
  finishedAt: '',
  reportUrl: '',
  daysLeft: 0,
  assessmentLink: '',
  generateReportLink: () => {}
};

SurveyCard.propTypes = {
  title: PropTypes.string.isRequired,
  reportUrl: PropTypes.string,
  startedAt: PropTypes.string,
  finishedAt: PropTypes.string,
  daysLeft: PropTypes.string,
  isDownloadAvailable: PropTypes.bool,
  generateReportLink: PropTypes.func,
  assessmentLink: PropTypes.string
};

export default SurveyCard;
