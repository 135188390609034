import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SmallArrowSVG } from '../../assets/svg/smallArrowDown.svg';
import {
  Wrapper,
  Title,
  TitleSection,
  CloseButton,
  WrappedSectionHeader,
  WrappedSection,
  SectionTitle,
  SubSectionsWrapper,
  WrappedSectionWordList,
  EploredWord
} from './style';
import { useWordsList } from '../../hooks/words-bank';

const WordExplorer = ({ onSelect }) => {
  const { t } = useTranslation();
  const { data, loading } = useWordsList();
  const [wrapperOpen, setWrapperOpen] = useState(false);
  const [sectionsOpenIndex, setSectionsOpenIndex] = useState([]);

  if (loading) {
    return <p>{t('messages.Loading Words')}</p>;
  }

  const { fellingWords } = data;

  return (
    <Wrapper>
      <TitleSection
        onClick={() => {
          setWrapperOpen(true);
        }}
        wrapperOpen={wrapperOpen}
      >
        <Title wrapperOpen={wrapperOpen}>{t('Explore words')}</Title>
        <CloseButton
          wrapperOpen={wrapperOpen}
          onClick={(e) => {
            e.stopPropagation();
            setWrapperOpen(false);
          }}
        >
          x
        </CloseButton>
      </TitleSection>
      <SubSectionsWrapper wrapperOpen={wrapperOpen}>
        {fellingWords.map(({ content, title }, index) => (
          <WrappedSection key={`${title}index`}>
            <WrappedSectionHeader
              onClick={() => {
                const newSectionsOpenIndex = [...sectionsOpenIndex];
                newSectionsOpenIndex[index] = !sectionsOpenIndex[index];
                setSectionsOpenIndex(newSectionsOpenIndex);
              }}
            >
              <SectionTitle>{title}</SectionTitle>
              <SmallArrowSVG style={{ width: '0.5rem', marginRight: '1rem' }} />
            </WrappedSectionHeader>
            <WrappedSectionWordList
              sectionsOpenIndex={sectionsOpenIndex}
              index={index}
            >
              {content.map((word, wordIndex) => (
                <EploredWord
                  onClick={() => {
                    setWrapperOpen(false);
                    onSelect(word);
                  }}
                  key={`${title}-word-${wordIndex}`}
                >
                  {word}
                </EploredWord>
              ))}
            </WrappedSectionWordList>
          </WrappedSection>
        ))}
      </SubSectionsWrapper>
    </Wrapper>
  );
};

WordExplorer.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default WordExplorer;
