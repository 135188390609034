import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { PageLayout } from '../components/PageLayout';
import { PAGES } from '../constants/navigation';

function Pages() {
  const { logout } = useAuth0();
  const { push } = useHistory();

  const [pageParams, setPageParams] = useState({});

  const changePageMiddleware = ({ params }: { params: Object }) => {
    setPageParams(params);
  };

  // go to named inline step;
  const goTo = (pageKey: string, params: Object = {}) => {
    changePageMiddleware({ params });
    push(`/${pageKey}`);
  };

  // clear store and go back to home step (0);
  const goHome = (params = {}) => {
    changePageMiddleware({ params });
    push('/');
  };

  const rootPage = PAGES.find((page) => page.isRoot);
  const RootComponent = rootPage?.page_component;

  return (
    <PageLayout>
      <Switch>
        <Route
          exact
          path="/"
          component={() => {
            if (RootComponent) {
              return (
                <RootComponent
                  goTo={goTo}
                  goHome={goHome}
                  pageParams={pageParams}
                />
              );
            }
            window.location.href = rootPage?.external_link || '/';
            return null;
          }}
          key="root-route"
        />
        {PAGES.map((page) => (
          <Route
            exact={page?.exact}
            path={`/${page.page_key}`}
            component={() => {
              if (page.page_component) {
                const PageComponent = page.page_component;
                return (
                  <PageComponent
                    goTo={goTo}
                    goHome={goHome}
                    pageParams={pageParams}
                    pageKey={page.page_key}
                  />
                );
              }
              window.location.href = page.external_link;
              return null;
            }}
            key={page.page_key}
          />
        ))}
        <Route
          path="/logout"
          render={() => {
            logout();
            return <div />;
          }}
          key="logout"
        />
        <Route
          path="*"
          component={
            PAGES.find((page) => page.page_key === '404')?.page_component
          }
          key="404"
        />
      </Switch>
    </PageLayout>
  );
}

export { Pages };
