import React, { FC } from 'react';

import { Input } from './styles';
import { InputWithErrorProps } from './types';

const InputWithError: FC<InputWithErrorProps> = ({ error, ...props }) => (
  <Input error={error} {...props} />
);

export { InputWithError };
