import styled from 'styled-components';
import { colors } from '@checkingin/component-library';

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: flex-start;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 ${colors.black.lighter};
  background-color: ${colors.white.absolute};
  padding: 1rem;
  margin: 0.625rem 0.625rem 8rem 0.625rem;
  width: 100%;
  height: fit-content;
`;

export const ContentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  height: fit-content;
  width: 100%;
`;
