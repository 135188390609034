import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const ChartCard = ({ title, titleIcon, children }) => (
  <Container>
    <div>
      <img src={titleIcon || ''} alt={title} />
      <span>{title || 'Please, write a title'}</span>
    </div>
    <div>{children}</div>
  </Container>
);

ChartCard.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default ChartCard;
